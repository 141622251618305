import { Button, Form} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useContext, useEffect, useState } from 'react'
import MyUpload from '../Component/MyUpload';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { RESOURCE_URL } from '../../../Helper/urls';
import { useNavigate, useParams } from 'react-router-dom';
import { Convert_to_base } from '../../../Helper/Doc_Functions';

export default function GeneralForm(props:any) {
    const [form] = Form.useForm();
    let { doctype,name } = useParams();

    const {locale,direction}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const nav=useNavigate()
const addAttachment=async(file:any,docname:string)=>{
    await MyAxios.put('api/resource/File/'+file.uid,{attached_to_doctype:Convert_to_base(doctype!),attached_to_name:docname})
}
    useEffect(()=>{
      
      const fecthData=async()=>{
        if(name){
          await MyAxios.get(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name).then((res:any)=>{
            setData(res.data.data)
            form.setFieldsValue({request_content:res.data.data.request_content})

          })
      }
      }
      fecthData()

      
      
    },[])
    const hundlesubmit=async(event:any)=>{
      if(name===undefined){
      await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),{request_content:event.request_content})
      .then((e:any)=>{
        // console.log(event.attachment);
        if(event.attachment!==undefined){
          for (let i = 0; i < event.attachment.fileList.length; i++) {
          if(event.attachment.fileList[i].status=='done'){
            addAttachment(event.attachment.fileList[i],e.data.data.name)

          }
          
        }
        }
        

        nav('/'+doctype)
      }
    )
      .catch(err=>console.log(err))
        
      }else{
        await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,{request_content:event.request_content})
        .then((e:any)=>{
          // console.log(event.attachment);
          if(event.attachment!==undefined){
            for (let i = 0; i < event.attachment.fileList.length; i++) {
            if(event.attachment.fileList[i].status=='done'){
              addAttachment(event.attachment.fileList[i],e.data.data.name)
  
            }
            
          }
          }
          
  
          nav('/'+doctype)
        }
      )

      }
      console.log(event.attachment)
    }
  return (
    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}
  >
    <Form.Item
      name="request_content"
      label={locale['request_content'][direction]}
      rules={[{ required: true },]}
    >
    <TextArea disabled={data.docstatus>=1} rows={4}  />
    </Form.Item>
   
     <MyUpload docstatus={data.docstatus>=1}   attachment_field='attachment'/>
   
    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
  )
}
