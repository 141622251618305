import { Divider, Flex } from 'antd'
import Title from 'antd/es/typography/Title';
import React, { useContext, useEffect, useRef } from 'react'
import { CustomContext } from '../../../../App';

export default function AttendanceCard() {
    const {locale,direction}=useContext(CustomContext)      
      const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0'); // Ensure two digits for hours
        const minutes = now.getMinutes().toString().padStart(2, '0'); // Ensure two digits for minutes
         
    const timenow=`${hours}:${minutes}`;
    // useEffect(()=>{

    // })
    // const verticalLine=direction=='ltr'?{
    //     borderLeft:'1px solid gray',
    //     paddingLeft:'0.5rem'

    // }:{
    //     borderRight:'1px solid gray',
    //     paddingRight:'0.5rem'
    // }
  return (
    <Flex justify='space-evenly' align='center'>
        <Title level={3}>{timenow}</Title>

        <div style={{}}>
            <Title level={4}>{locale['full_time'][direction]}</Title>
            <br />
            <Title level={5} style={{color:'gray'}}>09:00 - 17:00</Title>
        </div>
    </Flex>
  )
}
