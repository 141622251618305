import React, { useContext } from 'react'
import { Card, Col, Divider, Typography } from 'antd'
import { CustomContext } from '../../../../App'
import './style.css'
const {Title,Text}=Typography
export default function RemotePolicy() {
  const {locale,direction}=useContext(CustomContext)
  return (
    <Col lg={{ span: 8}}>
    <Card> 
      <Title level={3}>{locale.remote_work[direction]}</Title>
    <ul>
    <li>    <Text >{locale.remote_1[direction]}</Text>
    </li>
    <li>    <Text>{locale.remote_2[direction]}</Text>
    </li>
    </ul>

   

  </Card>
    </Col>
  )
}
