import { Collapse, Flex, Select,Typography } from 'antd'
import React, { useContext, useState } from 'react'
import { CustomContext } from '../../../../App'
import ComptencyComponents from './ComptencyComponents'
const {Text,Title}=Typography;

function CompetencyPage(props:any) {
  const {locale,direction}=useContext(CustomContext)
  const[lev,setLev]=useState('Foundational')
  return (
    <>
    <Flex justify='center' className='my-2'><Title level={3}>{locale.behavioral_competencies[direction]}</Title></Flex>
     <Collapse
      size="large"
      items={[{ key: '1', label: locale['stakeholder_managment'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Stakeholder Management' compNum={1}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '2', label: locale['sense_of_responsibility'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Sense Of Responsibility' compNum={2}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '3', label: locale['problem_solving'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Problem Solving' compNum={3}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '4', label: locale['adaptability'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Adaptability' compNum={4}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '5', label: locale['knowledge_sharing'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Knowledge Sharing' compNum={5}/>}]}
    />
     <Collapse
      size="large"
      items={[{ key: '6', label: locale.emotional_agility[direction], children: <ComptencyComponents profiency_level={lev} typeComp='Emotional Agility' compNum={6}/> }]}
    />
      <Flex justify='center'className='my-2 mt-4'><Title level={3}>{locale.leadership_competencies[direction]}</Title></Flex>
      <Collapse
      size="large"
      items={[{ key: '7', label: locale['strategic_leadership'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Strategic Leadership' compNum={7}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '8', label: locale['decision_making'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Decision-Making' compNum={8}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '9', label: locale['project_management'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Project Management' compNum={9}/>}]}
    />
     <Collapse
      size="large"
      items={[{ key: '10', label: locale['risk_management'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Risk Managment' compNum={10}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '11', label: locale['people_management'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='People Management' compNum={11}/> }]}
    />
     <Collapse
      size="large"
      items={[{ key: '12', label: locale['global_mindset'][direction], children: <ComptencyComponents profiency_level={lev} typeComp='Global Mindset' compNum={12}/> }]}
    />
    </>
  )
}

export default CompetencyPage