import React, { useContext } from 'react'
import MainLayout from '../../layouts/main/MainLayout'
import { Space } from 'antd'
import { CustomContext } from '../../App'

export default function MobileVersion() {
    const {locale,direction}=useContext(CustomContext)
    return (
        <MainLayout title="Mobile Version">
            <Space>

            </Space>
            <h2>{locale['install_on'][direction]} Android</h2>
            <p>{locale['install_on_android'][direction]}</p>

            <h2>{locale['install_on'][direction]} iOS</h2>
            <p>{locale['install_on_ios'][direction]}</p>

            <ul>
                <li>{locale['install_on_ios_steps'][direction][0]}</li>
                <li>{locale['install_on_ios_steps'][direction][1]}</li>

                <li>{locale['install_on_ios_steps'][direction][2]}</li>
                <li>{locale['install_on_ios_steps'][direction][3]}</li>
                <li>{locale['install_on_ios_steps'][direction][4]}</li>
            </ul>

        </MainLayout>
    )
}
