import { Flex } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'

function EvaluationHeader() {
    const{locale,direction}=useContext(CustomContext)

  return (
    <Flex justify='space-around'  >   
         <div className='titles'>{locale['employee_name'][direction]}</div>

        <div className='titles'>{locale['quarter'][direction]}</div>
        <div className='titles'>{locale['status'][direction]}</div>
        <div className='titles'>{locale['owner'][direction]}</div>
    </Flex>  
    )
}

export default EvaluationHeader