import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { FULL_METHOD_URL } from '../../Helper/urls'

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    value: 0,
  },
  reducers: {
    login:  (state) => {


    },
    logout: (state) => {
    },
    get_permision: (state, action) => {
    },
  },
})

// Action creators are generated for each case reducer function
export const { get_permision,login,logout } = UserSlice.actions

export default UserSlice.reducer