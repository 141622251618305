import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Link } from 'react-router-dom'
import { FloatButton, List, Radio, Tabs, TabsProps } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { MyAxios } from '../../../globals/MyAxios'
import GeneralHeader from '../../hr_request/general_request/component/GeneralHeader'
import GeneralItem from '../../hr_request/general_request/component/GeneralItem'
import LeaveItem from './component/LeaveItem'
import LeaveHeader from './component/LeaveHeader'
import { CustomContext } from '../../../App'
import LeaveList from './component/LeaveList'
import LeaveCalender from './component/LeaveCalender'
import LeavePolicy from './component/LeavePolicy'

export default function LeaveApplication() {
  const [loading,setLoading]=useState(true)
  const [data,setData]=useState({'my':[],'all':[]})
  const{locale,direction}=useContext(CustomContext)

  useEffect(()=>{
    const feachData=async()=>{
    const res =await MyAxios.get('api/method/kabi.api.get_lists.get_list',{params:{doctype:'Leave Application',allow_all:'HR Manager'}})
    setData(res.data.message)
    setLoading(false)
    }
    feachData()
  },[])
  const items: TabsProps['items'] = [
    {
      key: '1',
      label:locale.list[direction],
      children:<LeaveList data={data}/>,
    },
    {
      key: '2',
      label: locale.calender[direction],
      children: <LeaveCalender data={data} />,
    },
    {
      key: '3',
      label: locale.policies[direction],
      children: <LeavePolicy/>,
    },
  ];

    return (
    <MainLayout title='Leave Application' loading={loading}>
      <Link to='/leave-application/new'> 
           <FloatButton type='primary'  icon={<PlusOutlined  />}></FloatButton>
      </Link>
      <Tabs defaultActiveKey="1" items={items}  />

    </MainLayout>
  )
}

