import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'
import { Flex } from 'antd'

export default function PaymentHeader() {
    const{locale,direction}=useContext(CustomContext)
    return (
      <Flex justify='space-around'  >
          <div className='titles'>{locale['name'][direction]}</div>
          <div className='titles'>{locale['type'][direction]}</div>
          <div className='titles'>{locale['purchase_order'][direction]}</div>
          <div className='titles'>{locale['status'][direction]}</div>
          <div className='titles'>{locale['owner'][direction]}</div>
      </Flex>
    )
}
