import React, { useContext, useState } from 'react'
import AuthLayout from '../../layouts/Auth/AuthLayout'
import { Button, Col, Form, Input,Typography, message } from 'antd';
import WelcomeAuth from './component/welcomeAuth';
import './style.css'
import { Link, useNavigate } from 'react-router-dom';
import { CustomContext } from '../../App';
import { MyAxios } from '../../globals/MyAxios';
import { BASE_URL } from '../../Helper/urls';
import FlushPage from './FlushPage';

type FieldType = {
    email?: string;
    password?: string;
};
const {Title,Text}=Typography
 

export default function SignIn() {
    const [error,setError]=useState('')
    const [success,setSuccess]=useState('')
    const [loggedIn, setLoggedIn] = useState(false);

    const navigate=useNavigate()
    const [loading,setLoading]=useState(false)
    function setSessionCookie(name:string, value:string, domain:string='test.kabi.sa') {
        document.cookie = `${name}=${value}; domain=${domain}; path=/`;
    }
const hundleSubmit= async (e: any)=> {
        setError('')
        setSuccess('')
        setLoading(true)
        const params:any={
            usr:e.email,pwd:e.password
        }
        try{
            // await axios.get('https://jsonplaceholder.typicode.com/todos/1')
            // .then(e=>console.log(e))
 await MyAxios.get('api/method/kabi.api.auth.login',{params,} )
        .then((res:any)=>{
       if(res.data.message.success_key===1){
                setSuccess('Success Login')
                setSessionCookie('sid',res.data.message.sid??'')
                setSessionCookie('system_user','yes')
                setSessionCookie('user_id',res.data.message.email??'')
                setSessionCookie('user_image',res.data.message.image??'')
                setSessionCookie('full_name',res.data.message.full_name??'')

                localStorage.setItem('token',res.data.message.Authorization)
                localStorage.setItem('job_title',res.data.message.job_title??'')
                localStorage.setItem('full_name',res.data.message.employee_name??'')
                localStorage.setItem('email',res.data.message.email??'')
                localStorage.setItem('image',res.data.message.image??'')
                localStorage.setItem('full_name_ar',res.data.message.employee_name_ar??'')
                localStorage.setItem('emp',res.data.message.emp??'')
                localStorage.setItem('sid',res.data.message.sid??'')
                setLoggedIn(true)
                // navigate('/')

       }else{
        setError('Error: ' + res.data.message.message)
       }
       setLoading(false)
        })
        }catch(e){
            // message.error('Err')
            console.error(e)
            window.location.reload()
        }
    //    localStorage.setItem('token','hi')
    //    navigate('/')

     
   
    }
    const {locale,direction}=useContext(CustomContext)
    if (loggedIn) {
        navigate('/loading') 

    }
    return (
        <AuthLayout >


            <Col className='welcome' > <WelcomeAuth></WelcomeAuth></Col>


            <Form
                name="basic"
                layout="vertical"
                style={{ width: "20rem", textAlign: 'center' }}
                onFinish={hundleSubmit}
                autoComplete="off"
            >
                <Title level={3}>{locale['sign_in'][direction]}</Title>
                    <Text type='danger'>{error}</Text>
                    <br />
                    <Text type='success' >{success}</Text>
                <Form.Item<FieldType>
                    label={locale['email'][direction]}
                    name="email"
                    rules={[{ required: true, message: 'Please input your email correctly! ' }]}

                >
                    <Input placeholder='example@example.com' />
                </Form.Item>

                <Form.Item<FieldType>
                    label={locale['password'][direction]}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder='********' /> 
                                       

                </Form.Item>
<Link to='/request-password'><Button style={{display:'flex', justifySelf:'start'}} size='small' type='link'>{locale.forgot_pass[direction]}</Button></Link>

                <Form.Item  >
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading} >
                    {locale['sign_in'][direction]}
                    </Button>
                </Form.Item>
            </Form>
        </AuthLayout>
    )
}
