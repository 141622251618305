import React, { memo, useContext, useEffect } from 'react'
import MainLayout from '../../layouts/main/MainLayout'
import { Button, FloatButton, List, Popconfirm, Skeleton } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { applyAction, featchApprovalData } from '../../redux/Approvals/ApprovalSlice'
import ApprovalItem from './componant/ApprovalItem'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { CustomContext } from '../../App'

 function Approval() {
  const approval=useSelector((state:any)=>state.approval)
  const dispatch=useDispatch<AppDispatch>()
  const {direction,locale}=useContext(CustomContext)
  useEffect(()=>{
    dispatch(featchApprovalData())
  },[])

const ActionButtons=(actions:any[],doc:any)=>{
  let result:any[]=[]
  for(const act of actions){
    if(act.action==="اعتمد"){
      
      const approve=<Popconfirm
      title={locale['approve_the_request'][direction]}
      description={locale['are_you_sure_approve'][direction]}
      onConfirm={()=>{
        dispatch(applyAction({doctype:doc.doctype,name:doc.name,action:act.action}))
        // dispatch(featchApprovalData())

      }}
      okText={locale['yes'][direction]}
      cancelText={locale['no'][direction]}
    >
      <Button  className='d-flex align-items-baseline bg-success res-button '   icon={<CheckOutlined  />} type="primary"   > <div className='d-none d-lg-flex'>{locale[act.action][direction] }</div> </Button>
    </Popconfirm>
      result.push(approve)
    }else if(act.action==="رفض"){
      
      const reject=<Popconfirm
      title={locale['reject_the_request'][direction]}
      description={locale['are_you_sure_reject'][direction]}
      onConfirm={()=>{
        dispatch(applyAction({doctype:doc.doctype,name:doc.name,action:act.action}))
        // dispatch(featchApprovalData())

        

      }}
      okText={locale['yes'][direction]}
      cancelText={locale['no'][direction]}
    >
      <Button  className='d-flex align-items-baseline res-button'  danger  icon={<CloseOutlined    />} type="primary"  ><div className='d-none d-lg-flex'>{locale[act.action][direction] }</div></Button>
    </Popconfirm>
      result.push(reject)


    }
  }

  return result
}
  return (
    <MainLayout title='Approvals' loading={approval.loading}>
      <List
      
      pagination={{pageSize:5,responsive:true,defaultCurrent:1}}
      dataSource={approval.data}
      renderItem={(item:any) => (
        <List.Item
        key={item.doc.name}
        style={{display:'flex',flexWrap:'nowrap'}}
        
          actions={ActionButtons(item.action,item.doc)}
        >
         <ApprovalItem data={item} />
            
         </List.Item>
      )}
      >
       
      </List>
    </MainLayout>
   
  )
}
export default memo(Approval);