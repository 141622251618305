import { Button, Card, DatePicker, Flex, Form, Select, message,Typography, Tooltip, InputNumber } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { RESOURCE_URL } from '../../../Helper/urls';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import TextArea from 'antd/es/input/TextArea';
import MyUpload from '../Component/MyUpload';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { InfoCircleOutlined } from '@ant-design/icons';
const {Title,Text}=Typography
const {RangePicker}=DatePicker
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function LeaveForm(props:any) {
    const [form] = Form.useForm();
    let { doctype,name } = useParams();

    const {locale,direction,mode}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const[emp,setEmp]=useState("")
    const[LeaveType,setLeaveType]=useState("")
  // const[employeeDetails,setEmployeeDetails]=useState<any>()
  // const[startDate,setStartDate]=useState<any>()
  // const[endDate,setEndDate]=useState<any>()
  const[selectedWorkingDate,setSelectedWorkingDate]=useState<any>()
  // const[onBalancing,setOnBalancing]=useState<any>()
  // const[onRemaining,setonRemaining]=useState<any>()
    const [getalllist,setGetalllist]=useState<any[]>([])
    const nav=useNavigate()
    const addAttachment=async(file:any,docname:string)=>{
      await MyAxios.put('api/resource/File/'+file.uid,{attached_to_doctype:Convert_to_base(doctype!),attached_to_name:docname})
  }
  const calculateTotalDays = (startDate:any, endDate:any) => {
    let totalDays = 0;
    let currentDate = startDate;
  
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      // Increment count if it's not Friday (5) or Sunday (0)
      if (currentDate.day() !== 5 && currentDate.day() !== 0) {
        totalDays++;
      }
      currentDate = currentDate.add(1, 'day');
    }
    return totalDays;
  };
    useEffect(()=>{

      const fecthData=async()=>{
        if(name){
          await MyAxios.get(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name).then((res:any)=>{
            setData(res.data.data)
            setLeaveType(res.data.data.leave_type)
            form.setFieldsValue({dates:[dayjs(res.data.data.from_date),dayjs(res.data.data.to_date)],reason:res.data.data.description,leave_type:res.data.data.leave_type,total_leave_days:res.data.data.total_leave_days})
          //   const start=data.from_date, end} = data.to_date;
          //   if (start && end) {
          //     const totalDays = calculateTotalDays(dayjs(start), dayjs(end));
          //     const { leaveBalanceStart, leaveBalanceEnd } = calculateLeaveBalances(employeeDetails.dateOfJoining, start, end, employeeDetails.leaveBalance);
          //     setOnBalancing(leaveBalanceStart)
          //     setonRemaining(leaveBalanceEnd)
          //     setSelectedWorkingDate(totalDays)
          //     setStartDate(start)
          //     setEndDate(end)
          //     form.setFieldsValue({
          //       total_leave_days: totalDays,
    
          //     });
          //   }else{
          //     setOnBalancing('--')
          //     setonRemaining('--')
          //     setSelectedWorkingDate(0)
          //   }
          // }

          })
         
          
          

      } 
      if(name===undefined){
            await MyAxios.get('api/method/kabi.api.home.get_current_emp').then((res:any)=>{
            setEmp(res.data.message)

          }).catch(error=>console.error(error))
          form.setFieldValue('leave_type','Annual Leave')
          setLeaveType('Annual Leave')
          }
          else{
            setEmp(form.getFieldValue('employee'))
          }
          await MyAxios.get('api/method/kabi.api.time_request.get_emp_la').then((res:any)=>{
            setGetalllist(res.data.message)
          }).catch(error=>console.error(error))
          // await MyAxios.get('api/method/kabi.api.time_request.get_leave_balance_joining',{params:{emp:emp}}).then((res:any)=>{
          //   console.log(res.data.message)
          //   setEmployeeDetails(res.data.message)    
          //       }).catch(error=>console.error(error))
      }
      fecthData()
      

      
    },[])
    // Inside your component, update useEffect to watch changes on data
useEffect(() => {
  if (data.from_date && data.to_date) {
    form.setFieldsValue({
      dates: [dayjs(data.from_date), dayjs(data.to_date)],
      reason: data.description,
      leave_type: data.leave_type,
      total_leave_days: calculateTotalDays(dayjs(data.from_date), dayjs(data.to_date)),
    });

    // Assuming you have an API to fetch employee details including dateOfJoining
    // Example: setEmployeeDetails(apiResponse.data);
  }
}, [data]);

    const hundlesubmit=async(event:any)=>{

      if(name===undefined){
      await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),{description:event.reason,from_date:dayjs(event.dates[0]).format('YYYY-MM-DD'),to_date:dayjs(event.dates[1]).format('YYYY-MM-DD'),leave_type:event.leave_type,employee:emp,total_leave_days:event.total_leave_days})      .then((e:any)=>{
        // console.log(event.attachment);
        if(event.attachment!==undefined){
          for (let i = 0; i < event.attachment.fileList.length; i++) {
          if(event.attachment.fileList[i].status=='done'){
            addAttachment(event.attachment.fileList[i],e.data.data.name)

          }
          
        }
        }
        

        nav('/'+doctype)
      }
    )
     .catch((err:any)=>{
        message.error(JSON.parse(JSON.parse(err.response.data._server_messages)[0]).message  )
      })
      }else{
        await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,{description:event.reason,from_date:dayjs(event.dates[0]).format('YYYY-MM-DD'),to_date:dayjs(event.dates[1]).format('YYYY-MM-DD'),leave_type:event.leave_type,total_leave_days:event.total_leave_days})      .then((e:any)=>{
          // console.log(event.attachment);
          if(event.attachment!==undefined){
            for (let i = 0; i < event.attachment.fileList.length; i++) {
            if(event.attachment.fileList[i].status=='done'){
              addAttachment(event.attachment.fileList[i],e.data.data.name)
  
            }
            
          }
          }
          
  
          nav('/'+doctype)
        }
      )
       .catch((err:any)=>{
          message.error(JSON.parse(JSON.parse(err.response.data._server_messages)[0]).message  )

        })

      }
    }


    const disabledDateRanges = (current:any) => {
      for (let i = 0; i < getalllist.length; i++) {
        const { from_date, to_date,workflow_state } = getalllist[i];
        if (dayjs(current).isSameOrAfter(dayjs(from_date)) && dayjs(current).isSameOrBefore(dayjs(to_date)) && workflow_state !== 'مرفوض') {
          if(name===undefined)
          return true;
        else{
          if(getalllist[i].name!==name){
            return true 
          }
          else{
            return false
          }
        }
      
        }
      }
      return false;
    };
    const validateDateRange = (rule:any, value:any) => {
      // if(name){
        const [startDate, endDate] = value;
      if (!startDate || !endDate) {
        return Promise.reject('Please select dates');
      }
      
      for (let i = 0; i < getalllist.length; i++) {
        const { from_date, to_date,workflow_state } = getalllist[i];
        if (dayjs(startDate).isBefore(to_date) && dayjs(endDate).isAfter(from_date)&& (workflow_state !=='مرفوض')&&(getalllist[i].name!=name)) {
          return Promise.reject('Disabled date range selected')
 
        }
      }
      // }
       return Promise.resolve();

    };
    const calculateLeaveBalances = (dateOfJoining:any, startDate:any, endDate:any, initialLeaveBalance:any) => {
      const yearsOfService = dayjs().year() - dayjs(dateOfJoining).year();
      const monthlyAccrualRate = yearsOfService >= 5 ? 2.5 : 1.833;
    
      // Initial leave balance fetched from the API
      const currentLeaveBalance = initialLeaveBalance;
    
      // Calculate the number of months from joining date to the month before the start date
      const joinMonthStart = dayjs(dateOfJoining).startOf('month');
      const startMonth = dayjs(startDate).startOf('month');
      const monthsBeforeStart = startMonth.diff(joinMonthStart, 'month');
    
      // Calculate total months from joining date to the end date month
      const endMonth = dayjs(endDate).startOf('month');
      const totalMonthsToEndDate = endMonth.diff(joinMonthStart, 'month') + 1; // +1 to include the end month itself
    
      // Calculating the new leave balances
      const leaveBalanceStart = currentLeaveBalance + monthlyAccrualRate * monthsBeforeStart;
      const leaveBalanceEnd = currentLeaveBalance + monthlyAccrualRate * totalMonthsToEndDate;
    
      return {
        leaveBalanceStart,
        leaveBalanceEnd
      };
    };
    
    
    // if(name)
    // form.setFieldsValue({dates:[dayjs(data.from_date),dayjs(data.to_date)],reason:data.description,leave_type:data.leave_type,total_leave_days:data.total_leave_days})

  return (
    <>
    {LeaveType==='Annual Leave'?
    <Flex  gap={'small'}  className='d-none mb-3 flex-lg-nowrap flex-wrap'>
      <Card className='w-100 d-lg-inline-block w-lg-25' hoverable style={{backgroundColor:'#F4F6F7'}}  >
        <Flex justify='space-between' align='baseline' gap={'small'}>
          <Title level={5} style={{color:'#62728C'}} >{true==undefined?"--":`${''} ${locale.days[direction]}`}</Title>
          <Tooltip title={locale.first_tooltip[direction]} color='#023241'>
          <InfoCircleOutlined  style={{ fontSize: '1rem', color: '#BEC9D0' }}/>
          </Tooltip>
        </Flex>
        <Text className='text-secondary' >{locale.balance_on[direction]} {true==undefined?"--":dayjs().format('MMMM DD, YYYY')??'--'}</Text>
      </Card>
      <Card hoverable className='w-100 d-lg-inline-block w-lg-25' style={{backgroundColor:'#F4F6F7'}} >
      <Flex justify='space-between' align='baseline' gap={'small'}>
          <Title level={5} style={{color:'#62728C'}} >{selectedWorkingDate==undefined?"--":`${selectedWorkingDate} ${locale.days[direction]}`}</Title>
          <Tooltip title={locale.second_tooltip[direction]}  color='#023241'>
          <InfoCircleOutlined  style={{ fontSize: '1rem', color: '#BEC9D0' }}/>
          </Tooltip>
        </Flex>
        <Text className='text-secondary' >{locale.selected_working_days[direction]}</Text>
      </Card>
      <Card hoverable className='w-100 d-lg-inline-block w-lg-25' style={{backgroundColor:99>=0?'#EFFAF0':'#FEEDEC'}}>
      <Flex  justify='space-between' align='baseline' gap={'small'}>
          <Title level={5}  style={{color:'#62728C'}} >{true==undefined?"--":`${"onRemaining"} ${locale.days[direction]}`}</Title>
          <Tooltip title={locale.third_tooltip[direction]}  color='#023241'>
          <InfoCircleOutlined  style={{ fontSize: '1rem', color: '#BEC9D0' }}/>
          </Tooltip>
        </Flex>
        <Text className='text-secondary' >{locale.remaining_on[direction]} {"endDate"==undefined?"--":dayjs().add(1, 'day').format('MMMM DD, YYYY')??'--'}</Text>
      </Card>
    </Flex>:<></>}
    
    
    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}
   
  >
    <Flex gap={5} wrap='wrap'>
 <Form.Item
    label={locale.dates[direction]}
    name='dates'
    rules={[{required:true},{validator:validateDateRange}]}    >

    <RangePicker style={{width:'10rem'}} disabled={data.docstatus==1}  disabledDate={disabledDateRanges}
     onChange={(dates) => {
      if (dates) {
        const [start, end] = dates;
        if (start && end) {
          const totalDays = calculateTotalDays(dayjs(start), dayjs(end));
          // const { leaveBalanceStart, leaveBalanceEnd } = calculateLeaveBalances(employeeDetails.dateOfJoining, start, end, employeeDetails.leaveBalance);
          // setOnBalancing(leaveBalanceStart)
          // setonRemaining(leaveBalanceEnd)
          setSelectedWorkingDate(totalDays)
          // setStartDate(start)
          // setEndDate(end)
          form.setFieldsValue({
            total_leave_days: totalDays,

          });
        }else{
          // setOnBalancing('--')
          // setonRemaining('--')
          setSelectedWorkingDate(0)
        }
      }
    }}
    />

    </Form.Item>
    <Form.Item
    label={locale.leave_type[direction]}
    name='leave_type'
    rules={[{required:true}]}
    >
            <Select
            style={{width:'10rem'}}
      disabled={data.docstatus>=1}
      onChange={(value:string)=>setLeaveType(value)}
      options={[
        { value: 'Annual Leave', label: locale.annual_leave[direction] },
                { value: 'Sick', label: locale.sick[direction] },
        { value: 'Leave Without Pay', label: locale.leave_without_pay[direction]},
        { value: 'Marriage', label: locale.marriage[direction] },
        { value: 'Childbirth', label: locale.maternity[direction] },
        { value: 'Death of relative', label: locale.death[direction] },
       { value: 'Hajj', label: locale.hajj[direction] },

      ]}
    />
    </Form.Item>


    </Flex>
    <Form.Item 
   label={locale.total_leave_days[direction]}
   name='total_leave_days'
   
   >
 <InputNumber
    //  value='0'
    style={{width:'10rem'}}
      formatter={(value) => `${value} Days`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
      disabled
    />  
     </Form.Item>
    <Flex gap={5}>
         <Form.Item
   label={locale.reason[direction]}
   name='reason'
   >
        <TextArea disabled={data.docstatus==1}   rows={4} ></TextArea>
    </Form.Item>
<MyUpload docstatus={data.docstatus==1 } attachment_field='attachment' attatment_label='Attachment'/>
    </Flex>
  
    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
  
    </>
    
  )
}
