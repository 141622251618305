import { Button, Result } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthLayout from './AuthLayout'
import { CustomContext } from '../../App'

export default function NotFoundAuth() {
    const {locale,direction}=useContext(CustomContext)
    return (

        <AuthLayout>

            <Result
                status="404"
                title="404"
                subTitle={locale['sorry_404'][direction]}
                extra={<Button type="primary"><Link to='/login'>{locale['back_login'][direction]}</Link> </Button>}
            />
        </AuthLayout>

    )
}