import { Button, List } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { CustomContext } from '../../../../App'
import { useParams } from 'react-router-dom'
import ObjectiveComponent from './ObjectiveComponent'

function ObjectivePage(props:any) {
  const {locale,direction}=useContext(CustomContext)
 
  useEffect(()=>{

  },[])

  return (
    <> <List
    itemLayout="horizontal"
    dataSource={[]}
    renderItem={(item, index) => (
      <List.Item>
          <ObjectiveComponent data={item} deletable={true} />
      </List.Item>
    )}
  />
  <Button type='primary' className='my-4' disabled={[].length==4}>Add new Object</Button>
  </>
     )
}

export default ObjectivePage