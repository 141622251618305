import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme, Button, Card } from "antd";
import {store} from './redux/store';
import { Provider } from 'react-redux'
const { defaultAlgorithm, darkAlgorithm } = theme;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store} >
     <ConfigProvider
     direction= {localStorage.getItem('direction')=='rtl'?'rtl':'ltr'} 
    theme={{
      token: {colorPrimary: '#29A0D1'},


      algorithm: localStorage.getItem('mode')=='dark' ? darkAlgorithm : defaultAlgorithm,


      
      
    }}
  >
     <App   />
  </ConfigProvider>

    </Provider>

   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
