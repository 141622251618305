import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyAxios } from "../../globals/MyAxios";


interface Comments {
    name: string;
    content: string;
  }
const CommentSlice=createSlice({
    initialState:{
        loading:true,
        data:[{}]
    },
    name:'comments',
    reducers:{
        add_comment(){

        },
        edit_comment(){

        }

    },extraReducers(builder) {
        builder.addCase(fecthComments.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })   
        builder.addCase(fecthComments.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(fecthComments.rejected,(state,action)=>{
            state.loading=false
        })
        builder.addCase(addComments.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(addComments.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(addComments.fulfilled,(state,action)=>{
            state.loading=false
            state.data.unshift({owner:localStorage.getItem('full_name'),doc:action.payload,edit:true})

        })
        builder.addCase(editComments.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(editComments.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(editComments.fulfilled,(state,action)=>{
            state.loading=false
            const index = state.data.findIndex((item:any) => item.doc.name === action.payload.name);
            if (index !== -1) {
                state.data[index] = {...state.data[index],doc:action.payload}
              }

        })    
        builder.addCase(DeleteComments.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(DeleteComments.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(DeleteComments.fulfilled,(state,action)=>{
            state.loading=false
            const index = state.data.findIndex((item:any) => item.doc.name === action.payload);
            if (index !== -1) {
                state.data.splice(index, 1)
                
              }

        }
    )     
    
    }
        
})



export const fecthComments=createAsyncThunk('feach/comment',
async (params: { doctype: string; name: string })=>{
    const { doctype, name } = params;

    const res=await MyAxios.get('api/method/kabi.api.workflow.get_comments',{params:{doctype:doctype,name:name}})
    return res.data.message
}
)
export const editComments=createAsyncThunk('edit/comment',
async(params:{name:string,content:string})=>{
    const { content, name } = params;

    const res=await MyAxios.put('api/resource/Comment/'+name,{content:content})
    return res.data.data
}
)
export const addComments=createAsyncThunk('add/comment',
async(params: { doctype: string; name: string ,content:string})=>{
    const { doctype, name,content } = params;

    const res=await MyAxios.post('api/resource/Comment',{comment_type:"Comment",reference_doctype:doctype,reference_name:name,content:content})

    return res.data.data
}
)
export const DeleteComments=createAsyncThunk('delete/comment',
async(params:{commentname:string})=>{
    const { commentname } = params;
    const res=await MyAxios.delete('api/resource/Comment/'+commentname)

    if(res.data.message=='ok')
    return commentname
}
)
export default CommentSlice.reducer