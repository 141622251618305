import { Card, Flex, Steps,Typography } from 'antd'
import React, { useContext, useEffect } from 'react'
import { Convert_from_base_doctype_to, get_doc_type_color } from '../../../../Helper/Doc_Functions'
import { CustomContext } from '../../../../App'
import { Link } from 'react-router-dom'
const {Title,Text}=Typography

function MyRequestsCard(props:any) {
    const {locale,direction}=useContext(CustomContext)

  return (
    <Link to={'/' + Convert_from_base_doctype_to(props.data.doctype) + '/' + props.data.doc.name + '/edit'} className='w-100'  >

    <Card hoverable bordered style={{ borderColor: get_doc_type_color(props.data.doctype), borderWidth: 2, backgroundColor: get_doc_type_color(props.data.doctype) + '10', width: '100%' }} >
                <Flex justify='space-around' gap={2}  align='center' style={{}}  >
                    
                    <Text className='titles'  >{locale[props.data.doctype.replaceAll(' KABi', '').replaceAll(" ",'_').toLowerCase()][direction]}</Text>
                    <Text className='titles' style={{fontSize:'small',color:'gray'}}>{props.data.doc.name}</Text>
                    <Text className='titles d-lg-none d-flex'>{props.data.doc.workflow_state}</Text>
                    {props.data.workflow.status!='error'?<Steps
                    style={{ marginTop: 8 ,maxWidth:'30%' }}
                    type="inline"
                    className='d-none d-lg-flex'
                    current={props.data.workflow.currentindex}
                    items={props.data.workflow.items}
                    responsive={true}
          />:<Steps
          style={{ marginTop: 8 ,maxWidth:'30%' }}
          type="inline"
          className='d-none d-lg-flex'
          current={props.data.workflow.currentindex}
          status={'error'}
          items={props.data.workflow.items}
          responsive={true}
/>}
                    
                </Flex>


            </Card>
</Link>
  )
}

export default MyRequestsCard