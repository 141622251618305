import React, { useContext, useEffect, useState } from 'react'
import { Flex, Form, Input, Select } from 'antd'
import { locale } from 'dayjs'
import { CustomContext } from '../../../../App'
import { useParams } from 'react-router-dom'
import { MyAxios } from '../../../../globals/MyAxios'

function EmployeePage(props:any) {
    const {locale,direction}=useContext(CustomContext)
    const {doctype,name}=useParams()
    const [emps,setEmps]=useState<any[]>([]);
    const [loading,setLoading]=useState(true)

    useEffect(()=>{
        const feachData=async()=>{
            setLoading(true)
            await MyAxios.get('api/method/kabi.api.user.reports_emp').then((res:any)=>{
                setEmps(res.data.message)
                setLoading(false)
            })

        }        
        feachData()

        if(name){
        }else{
            props.form.setFieldValue('quarter',get_quarter()) 
        }
    },[])
    const get_quarter=()=>{
        const month = new Date().getMonth() + 1; // getMonth() returns month from 0-11, so add 1

        if (month >= 1 && month <= 3) {
            return 1;
        } else if (month >= 4 && month <= 6) {
            return 2;
        } else if (month >= 7 && month <= 9) {
            return 3;
        } else {
            return 4;
        }
    }
    const empOnchange=(event:any)=>{
        
        emps.forEach(element => {
            if(element.name==event){
                props.form.setFieldsValue({"department":element.department,'job_title':element.job_title??'','employee_name':element.employee_name}) 
            }
            
        });
    }
    const options = emps.map((val: any) => {
        return { label: val.employee_name, value: val.name };
    });
  return (
    <>
    <Flex gap='small' wrap='wrap'>
    <Form.Item
    label={locale.employee[direction]}
    name={'employee'}
    style={{width:'20rem'}}
    rules={[
        {
          required: true,
          message: 'Please select an employee',
        },]}
    
    >
        <Select loading={loading
        } options={options} onChange={empOnchange} />
    </Form.Item>
    <Form.Item
    label={locale.quarter[direction]}
    name={'quarter'}
    rules={[
        {
          required: true,
          message: 'Error',
        },]}
    
    >
    <Input disabled />
    </Form.Item>
    </Flex>
    <Flex gap='small' wrap='wrap'>
    <Form.Item
     label={locale.department[direction]}
    name={'department'}
    rules={[
        {
          required: true,
          message: 'Please select an employee',
        },]}
    
    >
    <Input disabled />

    </Form.Item>
    <Form.Item 
    label={locale.job_title[direction]}
    name={'job_title'}
    rules={[
        {
          required: true,
          message: 'Please select an employee',
        },]}
    
    >
    <Input disabled />

    </Form.Item>
    <Form.Item 
    label={locale.employee_name[direction]}
    name={'employee_name'}
    >   
     <Input disabled />
    </Form.Item>
   
    </Flex>



    </>


  )
}

export default EmployeePage