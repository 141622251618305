import { Flex } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'

export default function LeaveHeader() {
    const{locale,direction}=useContext(CustomContext)

  return (
    <Flex justify='space-around' style={{textAlign:'center'}} >
    <div className='titles'>{locale['name'][direction]}</div>
    <div className='titles'>{locale['leave_type'][direction]}</div>
    <div className='titles'>{locale['total_days'][direction]}</div>
    <div className='titles'>{locale['status'][direction]}</div>
    <div className='titles'>{locale['owner'][direction]}</div>
    
    
</Flex>
  )
}
