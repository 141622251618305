import { Avatar, Button, Col, Divider, Dropdown, MenuProps, Row, Space, Switch } from 'antd'
import { Typography } from 'antd';

import React, { useContext } from 'react'
import Notification from './Notification';
import { Link, useNavigate } from 'react-router-dom';
import { MoonOutlined } from '@ant-design/icons';
import { CustomContext } from '../../../App';
import MyCustomAvatar from '../../../globals/MyCustomAvatar';
import * as serviceWorker from '../../../swDev'

const { Title } = Typography;

export default function MyHeader(props: any) {
    const {direction, mode,locale} = useContext(CustomContext);
    const nav=useNavigate();
    
    const items: MenuProps['items'] = [
        {
            label: <Link to="/profile">{locale['profile'][direction]}</Link>,
            key: '0',
        },{
            label: <Link to="/reset-password">{locale['reset_password'][direction]}</Link>,
            key: '4',
        },
        {
            label:<Space><MoonOutlined />{locale['dark_mode'][direction]}<Switch checked={mode==='dark'} onChange={(check,e)=>{
                if(check){
                    localStorage.setItem('mode','dark')
                }else{
                    localStorage.setItem('mode','ligth')

                }
                // 
                nav('/')
                window.location.reload()
            }}/></Space>,
            key: '1',
        },
        {
            label: <Space>العربية<Switch checked={direction==='rtl'} onChange={(check,e)=>{
                if(check){
                    localStorage.setItem('direction','rtl')
                }else{
                    localStorage.setItem('direction','ltr')

                }
                // 
                nav('/')
window.location.reload()
            }} /></Space>,
            key: '2',
        },

    
       
        {
            label: <Button type="primary" onClick={()=>logout()} danger>{locale['logout'][direction]}</Button>,
            key: '5',
        },
    ];
    const logout=()=>{
        localStorage.removeItem('token')
                localStorage.removeItem('job_title')
                localStorage.removeItem('full_name')
        localStorage.removeItem('email')
        localStorage.removeItem('image')
        localStorage.removeItem('full_name_ar')

        localStorage.removeItem('sid')
        localStorage.removeItem('emp')

        serviceWorker.unregister()
        // ;
        nav('/login');
        window.location.reload()
      }
    return (
        <>
         {props.title!=null?<Title style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} level={5}>{locale[props.title.toLowerCase().replaceAll(' ','_') ][direction]??""}</Title>:""}   

            <Row style={{ gap: '0.4rem', marginLeft: '1.5rem', marginRight: '1.5rem', alignItems: 'baseline' }}>
               <Notification>

               </Notification>


                <Col style={{ margin: 0, alignSelf: 'center' }} >
                    <div className='d-none d-lg-flex full-name'>{direction=='ltr'?localStorage.getItem('full_name'):localStorage.getItem('full_name_ar')==''?localStorage.getItem('full_name'):localStorage.getItem('full_name_ar')} </div>
                    <div className='d-none d-lg-flex job-title'>{localStorage.getItem('job_title')=="undefined"?"":localStorage.getItem('job_title')}</div>
                </Col>





                <Divider type="vertical" className='m-0' />
                <Dropdown menu={{ items,selectable: true, }} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                        <MyCustomAvatar 
                        name={localStorage.getItem('full_name')=="undefined"?"":localStorage.getItem('full_name')}
                        image={localStorage.getItem('image')}
                        size='large'
                        />
                    </a>
                </Dropdown>




            </Row>
        </>



    )
}
