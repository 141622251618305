import { useContext } from "react";
import { CustomContext } from "../../../../App";
import React from 'react'
import './style.css'

function CompHelper(props: any) {

    const { locale, direction } = useContext(CustomContext)
    const Comptencies: any = {
        "Stakeholder Management": {
            "Foundational": [locale["stakeholder_managment_Foundational_1"][direction], locale["stakeholder_managment_Foundational_2"][direction], locale["stakeholder_managment_Foundational_3"][direction], locale["stakeholder_managment_Foundational_4"][direction], locale["stakeholder_managment_Foundational_5"][direction]],
            "Intermediate": [locale["stakeholder_managment_Intermediate_1"][direction], locale["stakeholder_managment_Intermediate_2"][direction], locale["stakeholder_managment_Intermediate_3"][direction], locale["stakeholder_managment_Intermediate_4"][direction], locale["stakeholder_managment_Intermediate_5"][direction]],
            "Advanced": [locale["stakeholder_managment_Advanced_1"][direction], locale["stakeholder_managment_Advanced_2"][direction], locale["stakeholder_managment_Advanced_3"][direction], locale["stakeholder_managment_Advanced_4"][direction], locale["stakeholder_managment_Advanced_5"][direction]],
            "Proficient": [locale["stakeholder_managment_Proficient_1"][direction], locale["stakeholder_managment_Advanced_2"][direction], locale["stakeholder_managment_Proficient_3"][direction], locale["stakeholder_managment_Proficient_4"][direction], locale["stakeholder_managment_Proficient_5"][direction]],
            "Expert": [locale["stakeholder_managment_Expert_1"][direction], locale["stakeholder_managment_Expert_2"][direction], locale["stakeholder_managment_Expert_3"][direction], locale["stakeholder_managment_Expert_4"][direction], locale["stakeholder_managment_Expert_5"][direction]]
        },
        "Sense Of Responsibility": {
            "Foundational": [locale["sense_of_responsibility_Foundational_1"][direction], locale["sense_of_responsibility_Foundational_2"][direction], locale["sense_of_responsibility_Foundational_3"][direction], locale["sense_of_responsibility_Foundational_4"][direction], locale["sense_of_responsibility_Foundational_5"][direction]],
            "Intermediate": [locale["sense_of_responsibility_Intermediate_1"][direction], locale["sense_of_responsibility_Intermediate_2"][direction], locale["sense_of_responsibility_Intermediate_3"][direction], locale["sense_of_responsibility_Intermediate_4"][direction], locale["sense_of_responsibility_Intermediate_5"][direction]],
            "Advanced": [locale["sense_of_responsibility_Advanced_1"][direction], locale["sense_of_responsibility_Advanced_2"][direction], locale["sense_of_responsibility_Advanced_3"][direction], locale["sense_of_responsibility_Advanced_4"][direction], locale["sense_of_responsibility_Advanced_5"][direction]],
            "Proficient": [locale["sense_of_responsibility_Proficient_1"][direction], locale["sense_of_responsibility_Advanced_2"][direction], locale["sense_of_responsibility_Proficient_3"][direction], locale["sense_of_responsibility_Proficient_4"][direction], locale["sense_of_responsibility_Proficient_5"][direction]],
            "Expert": [locale["sense_of_responsibility_Expert_1"][direction], locale["sense_of_responsibility_Expert_2"][direction], locale["sense_of_responsibility_Expert_3"][direction], locale["sense_of_responsibility_Expert_4"][direction], locale["sense_of_responsibility_Expert_5"][direction]]
        },
        "Problem Solving": {
            "Foundational": [locale["problem_solving_Foundational_1"][direction], locale["problem_solving_Foundational_2"][direction], locale["problem_solving_Foundational_3"][direction], locale["problem_solving_Foundational_4"][direction], locale["problem_solving_Foundational_5"][direction]],
            "Intermediate": [locale["problem_solving_Intermediate_1"][direction], locale["problem_solving_Intermediate_2"][direction], locale["problem_solving_Intermediate_3"][direction], locale["problem_solving_Intermediate_4"][direction], locale["problem_solving_Intermediate_5"][direction]],
            "Advanced": [locale["problem_solving_Advanced_1"][direction], locale["problem_solving_Advanced_2"][direction], locale["problem_solving_Advanced_3"][direction], locale["problem_solving_Advanced_4"][direction], locale["problem_solving_Advanced_5"][direction]],
            "Proficient": [locale["problem_solving_Proficient_1"][direction], locale["problem_solving_Advanced_2"][direction], locale["problem_solving_Proficient_3"][direction], locale["problem_solving_Proficient_4"][direction], locale["problem_solving_Proficient_5"][direction]],
            "Expert": [locale["problem_solving_Expert_1"][direction], locale["problem_solving_Expert_2"][direction], locale["problem_solving_Expert_3"][direction], locale["problem_solving_Expert_4"][direction], locale["problem_solving_Expert_5"][direction]]
        },
        "Adaptability": {
            "Foundational": [locale["adaptability_Foundational_1"][direction], locale["adaptability_Foundational_2"][direction], locale["adaptability_Foundational_3"][direction], locale["adaptability_Foundational_4"][direction], locale["adaptability_Foundational_5"][direction]],
            "Intermediate": [locale["adaptability_Intermediate_1"][direction], locale["adaptability_Intermediate_2"][direction], locale["adaptability_Intermediate_3"][direction], locale["adaptability_Intermediate_4"][direction], locale["adaptability_Intermediate_5"][direction]],
            "Advanced": [locale["adaptability_Advanced_1"][direction], locale["adaptability_Advanced_2"][direction], locale["adaptability_Advanced_3"][direction], locale["adaptability_Advanced_4"][direction], locale["adaptability_Advanced_5"][direction]],
            "Proficient": [locale["adaptability_Proficient_1"][direction], locale["adaptability_Advanced_2"][direction], locale["adaptability_Proficient_3"][direction], locale["adaptability_Proficient_4"][direction], locale["adaptability_Proficient_5"][direction]],
            "Expert": [locale["adaptability_Expert_1"][direction], locale["adaptability_Expert_2"][direction], locale["adaptability_Expert_3"][direction], locale["adaptability_Expert_4"][direction], locale["adaptability_Expert_5"][direction]]
        },
        "Knowledge Sharing": {
            "Foundational": [locale["knowledge_sharing_Foundational_1"][direction], locale["knowledge_sharing_Foundational_2"][direction], locale["knowledge_sharing_Foundational_3"][direction], locale["knowledge_sharing_Foundational_4"][direction], locale["knowledge_sharing_Foundational_5"][direction]],
            "Intermediate": [locale["knowledge_sharing_Intermediate_1"][direction], locale["knowledge_sharing_Intermediate_2"][direction], locale["problem_solving_Intermediate_3"][direction], locale["knowledge_sharing_Intermediate_4"][direction], locale["knowledge_sharing_Intermediate_5"][direction]],
            "Advanced": [locale["knowledge_sharing_Advanced_1"][direction], locale["knowledge_sharing_Advanced_2"][direction], locale["knowledge_sharing_Advanced_3"][direction], locale["knowledge_sharing_Advanced_4"][direction], locale["knowledge_sharing_Advanced_5"][direction]],
            "Proficient": [locale["knowledge_sharing_Proficient_1"][direction], locale["knowledge_sharing_Advanced_2"][direction], locale["knowledge_sharing_Proficient_3"][direction], locale["knowledge_sharing_Proficient_4"][direction], locale["knowledge_sharing_Proficient_5"][direction]],
            "Expert": [locale["knowledge_sharing_Expert_1"][direction], locale["knowledge_sharing_Expert_2"][direction], locale["knowledge_sharing_Expert_3"][direction], locale["knowledge_sharing_Expert_4"][direction], locale["knowledge_sharing_Expert_5"][direction]]
        },
        "Emotional Agility": {
            "Foundational": [locale["emotional_agility_Foundational_1"][direction], locale["emotional_agility_Foundational_2"][direction], locale["emotional_agility_Foundational_3"][direction], locale["emotional_agility_Foundational_4"][direction], locale["emotional_agility_Foundational_5"][direction]],
            "Intermediate": [locale["emotional_agility_Intermediate_1"][direction], locale["emotional_agility_Intermediate_2"][direction], locale["emotional_agility_Intermediate_3"][direction], locale["emotional_agility_Intermediate_4"][direction], locale["emotional_agility_Intermediate_5"][direction]],
            "Advanced": [locale["emotional_agility_Advanced_1"][direction], locale["emotional_agility_Advanced_2"][direction], locale["emotional_agility_Advanced_3"][direction], locale["emotional_agility_Advanced_4"][direction], locale["emotional_agility_Advanced_5"][direction]],
            "Proficient": [locale["emotional_agility_Proficient_1"][direction], locale["emotional_agility_Advanced_2"][direction], locale["emotional_agility_Proficient_3"][direction], locale["emotional_agility_Proficient_4"][direction], locale["emotional_agility_Proficient_5"][direction]],
            "Expert": [locale["emotional_agility_Expert_1"][direction], locale["emotional_agility_Expert_2"][direction], locale["emotional_agility_Expert_3"][direction], locale["emotional_agility_Expert_4"][direction], locale["emotional_agility_Expert_5"][direction]]
        },
        "Strategic Leadership": {
            "Foundational": [locale["strategic_leadership_Foundational_1"][direction], locale["strategic_leadership_Foundational_2"][direction], locale["strategic_leadership_Foundational_3"][direction], locale["strategic_leadership_Foundational_4"][direction]],
            "Intermediate": [locale["strategic_leadership_Intermediate_1"][direction], locale["strategic_leadership_Intermediate_2"][direction], locale["strategic_leadership_Intermediate_3"][direction], locale["strategic_leadership_Intermediate_4"][direction], locale["strategic_leadership_Intermediate_5"][direction]],
            "Advanced": [locale["strategic_leadership_Advanced_1"][direction], locale["strategic_leadership_Advanced_2"][direction], locale["strategic_leadership_Advanced_3"][direction], locale["strategic_leadership_Advanced_4"][direction], locale["strategic_leadership_Advanced_5"][direction], locale["strategic_leadership_Advanced_6"][direction]],
            "Proficient": [locale["strategic_leadership_Proficient_1"][direction], locale["strategic_leadership_Advanced_2"][direction], locale["strategic_leadership_Proficient_3"][direction], locale["strategic_leadership_Proficient_4"][direction], locale["strategic_leadership_Proficient_5"][direction], locale["strategic_leadership_Proficient_6"][direction]],
            "Expert": [locale["strategic_leadership_Expert_1"][direction], locale["strategic_leadership_Expert_2"][direction], locale["strategic_leadership_Expert_3"][direction], locale["strategic_leadership_Expert_4"][direction], locale["strategic_leadership_Expert_5"][direction], locale["strategic_leadership_Expert_6"][direction]]
        },
        "Decision-Making": {
            "Foundational": [locale["decision_making_Foundational_1"][direction], locale["decision_making_Foundational_2"][direction], locale["decision_making_Foundational_3"][direction], locale["decision_making_Foundational_4"][direction], locale["decision_making_Foundational_5"][direction], locale["decision_making_Foundational_6"][direction]],
            "Intermediate": [locale["decision_making_Intermediate_1"][direction], locale["decision_making_Intermediate_2"][direction], locale["decision_making_Intermediate_3"][direction], locale["decision_making_Intermediate_4"][direction], locale["decision_making_Intermediate_5"][direction]],
            "Advanced": [locale["decision_making_Advanced_1"][direction], locale["decision_making_Advanced_2"][direction], locale["decision_making_Advanced_3"][direction], locale["decision_making_Advanced_4"][direction], locale["decision_making_Advanced_5"][direction]],
            "Proficient": [locale["decision_making_Proficient_1"][direction], locale["decision_making_Advanced_2"][direction], locale["decision_making_Proficient_3"][direction], locale["decision_making_Proficient_4"][direction], locale["decision_making_Proficient_5"][direction], locale["decision_making_Proficient_6"][direction]],
            "Expert": [locale["decision_making_Expert_1"][direction], locale["decision_making_Expert_2"][direction], locale["decision_making_Expert_3"][direction], locale["decision_making_Expert_4"][direction], locale["decision_making_Expert_5"][direction]]
        },
        "Project Management": {
            "Foundational": [locale["project_managment_Foundational_1"][direction], locale["project_managment_Foundational_2"][direction], locale["project_managment_Foundational_3"][direction], locale["project_managment_Foundational_4"][direction], locale["project_managment_Foundational_5"][direction]],
            "Intermediate": [locale["project_managment_Intermediate_1"][direction], locale["project_managment_Intermediate_2"][direction], locale["project_managment_Intermediate_3"][direction], locale["project_managment_Intermediate_4"][direction], locale["project_managment_Intermediate_5"][direction]],
            "Advanced": [locale["project_managment_Advanced_1"][direction], locale["project_managment_Advanced_2"][direction], locale["project_managment_Advanced_3"][direction], locale["project_managment_Advanced_4"][direction], locale["project_managment_Advanced_5"][direction], locale["project_managment_Advanced_6"][direction]],
            "Proficient": [locale["project_managment_Proficient_1"][direction], locale["project_managment_Advanced_2"][direction], locale["project_managment_Proficient_3"][direction], locale["project_managment_Proficient_4"][direction], locale["project_managment_Proficient_5"][direction], locale["project_managment_Proficient_6"][direction]],
            "Expert": [locale["project_managment_Expert_1"][direction], locale["project_managment_Expert_2"][direction], locale["project_managment_Expert_3"][direction], locale["project_managment_Expert_4"][direction], locale["project_managment_Expert_5"][direction]]
        },
        "Risk Managment": {
            "Foundational": [locale["risk_managment_Foundational_1"][direction], locale["risk_managment_Foundational_2"][direction], locale["risk_managment_Foundational_3"][direction], locale["risk_managment_Foundational_4"][direction], locale["risk_managment_Foundational_5"][direction],locale["risk_managment_Foundational_6"][direction],
            locale["risk_managment_Foundational_7"][direction]],
            "Intermediate": [locale["risk_managment_Intermediate_1"][direction], locale["risk_managment_Intermediate_2"][direction], locale["risk_managment_Intermediate_3"][direction], locale["risk_managment_Intermediate_4"][direction], locale["risk_managment_Intermediate_5"][direction]],
            "Advanced": [locale["risk_managment_Advanced_1"][direction], locale["risk_managment_Advanced_2"][direction], locale["risk_managment_Advanced_3"][direction], locale["risk_managment_Advanced_4"][direction], locale["risk_managment_Advanced_5"][direction], locale["risk_managment_Advanced_6"][direction], locale["risk_managment_Advanced_7"][direction]],
            "Proficient": [locale["risk_managment_Proficient_1"][direction], locale["risk_managment_Advanced_2"][direction], locale["risk_managment_Proficient_3"][direction], locale["risk_managment_Proficient_4"][direction], locale["risk_managment_Proficient_5"][direction]],
            "Expert": [locale["risk_managment_Expert_1"][direction], locale["risk_managment_Expert_2"][direction], locale["risk_managment_Expert_3"][direction], locale["risk_managment_Expert_4"][direction], locale["risk_managment_Expert_5"][direction], locale["risk_managment_Expert_6"][direction], locale["risk_managment_Expert_7"][direction]]
        },
        "People Management": {
            "Foundational": [locale["people_managment_Foundational_1"][direction], locale["people_managment_Foundational_2"][direction], locale["people_managment_Foundational_3"][direction], locale["people_managment_Foundational_4"][direction], locale["people_managment_Foundational_5"][direction], locale["people_managment_Foundational_6"][direction], locale["people_managment_Foundational_7"][direction], locale["people_managment_Foundational_8"][direction]],
            "Intermediate": [locale["people_managment_Intermediate_1"][direction], locale["people_managment_Intermediate_2"][direction], locale["people_managment_Intermediate_3"][direction], locale["people_managment_Intermediate_4"][direction], locale["people_managment_Intermediate_5"][direction], locale["people_managment_Intermediate_6"][direction]],
            "Advanced": [locale["people_managment_Advanced_1"][direction], locale["people_managment_Advanced_2"][direction], locale["people_managment_Advanced_3"][direction], locale["people_managment_Advanced_4"][direction], locale["people_managment_Advanced_5"][direction], locale["people_managment_Advanced_6"][direction]],
            "Proficient": [locale["people_managment_Proficient_1"][direction], locale["people_managment_Advanced_2"][direction], locale["people_managment_Proficient_3"][direction], locale["people_managment_Proficient_4"][direction], locale["people_managment_Proficient_5"][direction], locale["people_managment_Proficient_6"][direction]],
            "Expert": [locale["people_managment_Expert_1"][direction], locale["people_managment_Expert_2"][direction], locale["people_managment_Expert_3"][direction],  locale["people_managment_Expert_4"][direction]]
        },
        "Global Mindset": {
            "Foundational": [locale["global_mindset_Foundational_1"][direction], locale["global_mindset_Foundational_2"][direction], locale["global_mindset_Foundational_3"][direction], locale["global_mindset_Foundational_4"][direction], locale["global_mindset_Foundational_5"][direction]],
            "Intermediate": [locale["global_mindset_Intermediate_1"][direction], locale["global_mindset_Intermediate_2"][direction], locale["global_mindset_Intermediate_3"][direction], locale["global_mindset_Intermediate_4"][direction], locale["global_mindset_Intermediate_5"][direction], locale["global_mindset_Intermediate_6"][direction]],
            "Advanced": [locale["global_mindset_Advanced_1"][direction], locale["global_mindset_Advanced_2"][direction], locale["global_mindset_Advanced_3"][direction], locale["global_mindset_Advanced_4"][direction], locale["global_mindset_Advanced_5"][direction], locale["global_mindset_Advanced_6"][direction]],
            "Proficient": [locale["global_mindset_Proficient_1"][direction], locale["global_mindset_Advanced_2"][direction], locale["global_mindset_Proficient_3"][direction], locale["global_mindset_Proficient_4"][direction], locale["global_mindset_Proficient_5"][direction], locale["global_mindset_Proficient_6"][direction]],
            "Expert": [locale["global_mindset_Expert_1"][direction], locale["global_mindset_Expert_2"][direction], locale["global_mindset_Expert_3"][direction], locale["global_mindset_Expert_4"][direction], locale["global_mindset_Expert_5"][direction], locale["global_mindset_Expert_6"][direction]]
        },

    };
    return (
        <> {Comptencies[props.typeComp][props.profiency_level].map((val: any, index: number) => (<li>{val}</li>))}</>

    )
}

export default CompHelper
