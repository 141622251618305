import { DeleteOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Dropdown, Flex, Input, List, MenuProps, Modal, Spin, Timeline } from 'antd'
import React, { Children, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { useParams } from 'react-router-dom'
import { Convert_to_base } from '../../../Helper/Doc_Functions'
import { DeleteComments, addComments, editComments, fecthComments } from '../../../redux/Comments/CommentSlice'
import Title from 'antd/es/typography/Title'
import { CustomContext } from '../../../App'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import './style.css'

export default function Comments(props:any) {

  const comments=useSelector((state:any)=>state.comments.data)
  const loading=useSelector((state:any)=>state.comments.loading)
  const dispatch=useDispatch<AppDispatch>()
  const {locale,direction}=useContext(CustomContext)
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [content, setContent] = useState('');
  const [commentname, setCommentName] = useState('');

  const{doctype,name}=useParams();

  const showModal = () => {
    setContent('')
    setOpen(true);
  };

  const handleOk = () => {
    dispatch(addComments({doctype:Convert_to_base(doctype!) ,name:name!,content:content}))
    setOpen(false);
    setContent('')
  };

  const handleCancel = () => {
    setOpen(false);
    setContent('')
  };
  
  useEffect(()=>{
    dispatch(fecthComments({doctype:Convert_to_base(doctype!),name:name!}))
  },[])
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContent( e.target.value);
  };
//------------------------------------------------------
  const showModalEdit = () => {

    setOpenEdit(true);
  };
  const DeleteComment=(docname:string)=>{
    dispatch(DeleteComments({commentname:docname}))
  }
  const handleEditOk = () => {
    dispatch(editComments({name:commentname,content:content}))
    setOpenEdit(false);
    setContent('')
  };
  function htmlToPlainText(html:string) {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
}
  const handleEditCancel = () => {
    setOpenEdit(false);
    setContent('')
  };
  const onChangeEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContent( e.target.value);
  };
  if(loading){
    return (<Spin style={{display:'flex',justifyContent:'center'}}  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />)

  }
  return (
    <>
    <Modal
        title={locale.add_comment[direction]}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText={locale.ok[direction]}
        cancelText={locale.cancel[direction]}
        okButtonProps={{disabled:content==''}}
      >
         <Flex vertical gap={32}>

    <TextArea
      showCount
      value={content}
      maxLength={250}
      onChange={onChange}
      style={{ height: 120, resize: 'none' }}
    />
  </Flex>
  <br />
      </Modal>
      <Modal
        title={locale.add_comment[direction]}
        open={openEdit}
        onOk={handleEditOk}
        confirmLoading={loading}
        onCancel={handleEditCancel}
        okText={locale.ok[direction]}
        cancelText={locale.cancel[direction]}
        okButtonProps={{disabled:content==''}}
      >
         <Flex vertical gap={32}>

    <TextArea
      showCount
      value={content}
      maxLength={250}
      onChange={onChangeEdit}
      style={{ height: 120, resize: 'none' }}
    />
  </Flex>
  <br />
      </Modal>
     <div className="d-flex" style={{justifyContent:'end'}}>
      <Button type='primary' icon={<PlusOutlined />} onClick={showModal} >{locale.add_comment[direction]}</Button>
      </div>
      <br />
      {comments.length==0?  <Flex align='center' justify='center'>
            <Title  level={5} style={{color:'gray'}}>{locale.no_result[direction]}</Title>
</Flex>:
  
    
//     <Timeline
// mode='left'
//

        // items={
<div  style={{overflowX:'scroll',height:'35vh',padding:'1rem',width:'95%'}}>
          {comments.map((e:any)=>{
            
            const items: MenuProps['items'] = [
              {
                key: '1',
                label: locale.edit[direction],
                icon: <EditOutlined />,
                onClick: () => {
                  setCommentName(e.doc.name);
                  setContent(htmlToPlainText(e.doc.content));
                  showModalEdit();
                },
              },
              {
                key: '2',
                danger: true,
                label: locale.delete[direction],
                icon: <DeleteOutlined />,
                onClick: () => {
                  DeleteComment(e.doc.name);
                },
              },
            ];
            return <div>
              <div className='mb-1'  style={{fontSize:'small',color:'gray',flexWrap:'wrap' }}>{dayjs(e.doc.creation).format('DDMMM,YYYY - hh:mma')}</div>
               <Card bordered  title={e.edit?<Flex justify='space-between' wrap='wrap' style={{flexGrow:1}}><div>{e.owner}</div><Dropdown menu={{ items }} >
          
          <EllipsisOutlined style={{cursor:'pointer'}} />
        </Dropdown></Flex>:<>{e.owner}</>}><div  style={{fontSize:'small',color:'gray',flexWrap:'wrap' }} dangerouslySetInnerHTML={{__html:e.doc.content}} ></div></Card>
        <br />
        <Divider></Divider>
        </div>
            // {label:dayjs(e.doc.creation).format('DDMMM,YYYY - hh:mma'),children:
          // <Flex justify='start' style={{width:'100%'}} >
        // </Flex> 
        // }}
      })
    }</div>
      // />

   }
    </>
   
 
  )
}
