import { Card, Divider, Typography } from 'antd';
import React, { useState } from 'react'
import MyCustomAvatar from '../../../../globals/MyCustomAvatar';
import { TeamOutlined } from '@ant-design/icons';
const{Text}=Typography

function EmployeeNode(props:any) {
    const [isVisible, setIsVisible] = useState(false);

    const toggleChildren = (event: any) => {
        event.stopPropagation();
        setIsVisible(!isVisible);
    };

    return (
        <li  >
            <div className='center-div'>
     <Card onClick={toggleChildren}  hoverable title={props.employee.children.length>0?<div className='d-flex gap-2 ' style={{alignItems:'baseline'}}>{props.employee.children.length} <TeamOutlined /></div>:<></>} >
                <MyCustomAvatar image={props.employee.image ?? ""} name={props.employee.employee_name} size='large' />
                <br />
                <br />

                <Text >{props.employee.employee_name}</Text>
                <Divider></Divider>
                <Text style={{ color: 'gray', fontSize: 'small' }}>{props.employee.job_title ?? ""}</Text>
                <br />
                <br />

            </Card>
            </div>
       
            {isVisible && props.employee.children.length > 0 && (
                <ul style={{ display: isVisible ? 'flex' : 'none' }}>
                    {props.employee.children.map((child:any, index:number) => (
                        <EmployeeNode key={index} employee={child} />
                    ))}
                </ul>
            )}
        </li>
    );
}

export default EmployeeNode