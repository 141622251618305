import { List } from 'antd'
import Item from 'antd/es/list/Item'
import React from 'react'
import AttendanceCard from './AttendanceCard'

export default function AttedanceList(props:any) {

  return (
   <List
   itemLayout="vertical"
    size="large"
    dataSource={props.data}
    loading={props.loading}
    pagination={{pageSize:5,}}
    renderItem={(item:any)=>{
        return <List.Item 
        key={item.name}
        >
            <AttendanceCard item={item} />
        </List.Item>
    }}
    >
    
   </List>
  )
}
