import { Card, Flex, Typography } from 'antd'
import React, { useContext } from 'react'
import { Convert_from_base_doctype_to, get_doc_type_color } from '../../../Helper/Doc_Functions'
import { Link } from 'react-router-dom'
import './style.css'
import { CustomContext } from '../../../App'
const { Text } = Typography
export default function ApprovalItem(props: any) {
    const {locale,direction}=useContext(CustomContext)
    return (
        <Link to={'/' + Convert_from_base_doctype_to(props.data.doc.doctype) + '/' + props.data.doc.name + '/edit'} className='w-100'  >

            <Card hoverable bordered style={{ borderColor: get_doc_type_color(props.data.doc.doctype), borderWidth: 2, backgroundColor: get_doc_type_color(props.data.doc.doctype) + '10', color: 'black200', width: '100%' }} >
                <Flex justify='space-around' gap={2}  align='center' style={{}}  >

                    <Text className='titles'  >{locale[props.data.doc.doctype.replaceAll(' KABi', '').replaceAll(" ",'_').toLowerCase()][direction]}</Text>
                    <Text className='titles' >{props.data.doc.name}</Text>
                    <Text className='titles' >{props.data.doc.workflow_state}</Text>
                    <Text className='titles' >{props.data.owner}</Text>
                </Flex>


            </Card>

        </Link>
    )
}
