import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Link } from 'react-router-dom'
import { FloatButton, List, Radio, Tabs, TabsProps } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CustomContext } from '../../../App'
import { MyAxios } from '../../../globals/MyAxios'
import BusinessHeader from './component/BusinessHeader'
import BusinessItem from './component/BusinessItem'
import BusinessList from './component/BusinessList'
import BusinessCalendar from './component/BusinessCalendar'
import BusinessPolicy from './component/BusinessPolicy'

export default function BusinessTrip() {
  const [loading,setLoading]=useState(true)
  const [data,setData]=useState({'my':[],'all':[]})
  const{locale,direction}=useContext(CustomContext)

  useEffect(()=>{
    const feachData=async()=>{
    const res =await MyAxios.get('api/method/kabi.api.get_lists.get_list',{params:{doctype:'Business Trip',allow_all:'HR Manager'}})
    setData(res.data.message)
    setLoading(false)
    }
    feachData()
  },[])
  const items: TabsProps['items'] = [
    {
      key: '1',
      label:locale.list[direction],
      children:<BusinessList data={data}/>,
    },
    {
      key: '2',
      label: locale.calender[direction],
      children: <BusinessCalendar data={data} />,
    },
    {
      key: '3',
      label: locale.policies[direction],
      children: <BusinessPolicy/>,
    },
  ];

    return (
    <MainLayout title='Business Trip' loading={loading}>
      <Link to='/business-trip/new' > 
           <FloatButton  type='primary'  icon={<PlusOutlined  />}></FloatButton>
      </Link>
     
     <Tabs defaultActiveKey="1" items={items}  />

    </MainLayout>
  )
}
