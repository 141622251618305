import { Button, Form, Tabs, TabsProps } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import { RESOURCE_URL } from '../../../Helper/urls';
import EmployeePage from '../Component/EvaluationComponent.tsx/EmployeePage';
import ObjectivePage from '../Component/EvaluationComponent.tsx/ObjectivePage';
import CompetencyPage from '../Component/EvaluationComponent.tsx/CompetencyPage';
import ResultPage from '../Component/EvaluationComponent.tsx/ResultPage';

function EvaluationForm() {
    const [form] = Form.useForm();
    let { doctype,name } = useParams();

    const {locale,direction}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const nav=useNavigate()
const addAttachment=async(file:any,docname:string)=>{
    await MyAxios.put('api/resource/File/'+file.uid,{attached_to_doctype:Convert_to_base(doctype!),attached_to_name:docname})
}
    useEffect(()=>{
      
      const fecthData=async()=>{
        if(name){
          await MyAxios.get(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name).then((res:any)=>{
            setData(res.data.data)
            console.log(res.data.data)
            form.setFieldsValue({})

          })
      }
      }
      fecthData()

      
      
    },[])
    const hundlesubmit=async(event:any)=>{
    //   if(name===undefined){
    //   await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),{request_content:event.request_content})
    //   .then((e:any)=>{
    //     nav('/'+doctype)
    //   }
    // )
    //   .catch(err=>console.log(err))
        
    //   }else{
    //     await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,{request_content:event.request_content})
    //     .then((e:any)=>{
    //       nav('/'+doctype)
    //     }
    //   )
      
    //   }
    console.log(event)
    }
    
const items: TabsProps['items'] = [
    {
      key: '1',
      label: locale.employee[direction],
      children: <EmployeePage data={data} form={form}/>,
    },
    {
      key: '2',
      label: locale.objective[direction],
      children: <ObjectivePage data={data} form={form}/>,
    },
    {
      key: '3',
      label: locale.competency[direction],
      children: <CompetencyPage data={data} form={form}/>,
      disabled:name?data.workflow_state.includes('الخطة'):true
    },
    {
        key: '4',
        label: locale.result[direction],
        children: <ResultPage data={data} form={form}/>,
        disabled:name?data.workflow_state.includes('الخطة'):true
    }
  ];
  
  return (

    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}
  >
<Tabs type='card' size='large' defaultActiveKey="1" items={items} />
    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
)
}

export default EvaluationForm