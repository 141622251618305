import React, { useContext } from 'react'
import { Card, Col, Flex, Row, Table, Typography } from 'antd'
import './style.css'
import { locale } from 'dayjs';
import { CustomContext } from '../../../../App';
const {Title,Text}=Typography;


export default function LeavePolicy() {
  const {locale,direction}=useContext(CustomContext)
  const dataSource = [
    {
      key: '1',
      percentage: '0.00 %',
      range: '1.00 - 30.00',
    },
    {
      key: '2',
      percentage: '25.00 %',
      range: '31.00 - 90.00',
    },
    {
      key: '3',
      percentage: '100.00 %',
      range: `91.00 - ${locale.unlimited[direction]}`,
    },
  ];
  
  const columns = [
    {
      title: locale.percentage[direction],
      dataIndex: 'percentage',
      key: 'percentage',
    },
    {
      title: locale.range[direction],
      dataIndex: 'range',
      key: 'range',
    },
   
  ];
  return (
<>
<Row gutter={[8, 8]}>
  <Col lg={{ span: 6 }} >
<Card>
        <Title level={3}>{locale.annual_leave[direction]}</Title>
        <ul>
      <li><Text>{locale.day_basis[direction]}: <span className='text-secondary'>{locale.working_days[direction]}</span> </Text></li>
      <li><Text>{locale.accrual_kind[direction]}: <span className='text-secondary'>{locale.accrual_kind_policy[direction]}</span></Text></li>
      <li><Text>{locale.accural_basis[direction]}: <span className='text-secondary'>{locale.calander_days[direction]}</span></Text></li>
      <li><Text>{locale.no_of_days[direction]}: <span className='text-secondary'>22.0 {locale.days[direction]}</span></Text></li>
      <li><Text>{locale.after_5_years[direction]}: <span className='text-secondary'>30 {locale.days[direction]}</span></Text></li>
      <li><Text>{locale.carryover[direction]}: <span className='text-secondary'>{locale.no_carryover[direction]}</span></Text></li>
      <li><Text>{locale.eligibility[direction]}: <span className='text-secondary'>1 {locale.days[direction]} {locale.after_hire_date[direction]}</span></Text></li>
    </ul>
    </Card>
  </Col>
  <Col lg={{ span: 8}}>
<Card> <Title level={3}>{locale.sick_leave[direction]}</Title>
    <ul>
      <li><Text>{locale.max_number_of_days[direction]}:<span className='text-secondary'> {locale.unlimited[direction]}</span></Text></li>
      <li><Text>{locale.period_basis[direction]}:<span className='text-secondary'> {locale.sick_year[direction]}</span></Text></li>
      <li><Text>{locale.day_basis[direction]}:<span className='text-secondary'> {locale.calandar[direction]}</span></Text></li>
      <li><Text>{locale.Forbid[direction]}:<span className='text-secondary'> {locale.no[direction]}</span></Text></li>
      <li><Text>{locale.require_evidence[direction]}:<span className='text-secondary'> {locale.no[direction]}</span></Text></li>
    </ul>

    <Table
    dataSource={dataSource}
    columns={columns}
    pagination={false}
    
    >

    </Table>
    </Card>
  </Col>

  <Col lg={{ span: 6 }} >
 <Card>
       <Title level={3}>{locale.special_leave[direction]}</Title>
    <ul>
      <li><Text>{locale.marriage[direction]}: <span className='text-secondary'> 5 {locale.working_days[direction]}</span></Text></li>
      <li><Text>{locale.death_of_relative[direction]}: <span className='text-secondary'>5 {locale.calander_days[direction]}</span> </Text></li>
      <li><Text>{locale.childbirth[direction]}: <span className='text-secondary'> 3 {locale.working_days[direction]}</span></Text></li>
      <li><Text>{locale.hajj[direction]}: <span className='text-secondary'>15 {locale.calander_days[direction]}</span> </Text></li>
      
    </ul>
    </Card>
  </Col>
  
</Row>
  {/* <Flex gap={'small'} wrap='wrap' >
      
    
      
    
    </Flex> */}
  
   

    </>
  )
}
