import { List, Radio } from 'antd';
import React, { useContext, useState } from 'react'
import BusinessHeader from './BusinessHeader';
import BusinessItem from './BusinessItem';
import { CustomContext } from '../../../../App';

export default function BusinessList(props:any) {
    const [sortFilter,setSortFilter]=useState<keyof typeof props.data>('my')
    const{locale,direction}=useContext(CustomContext)

  return (
   <>
    {props.data['all'].length==0?<></>:
            <Radio.Group
            value={sortFilter}
            onChange={(e) => {
              setSortFilter(e.target.value);
            }}
            ><Radio.Button className='titles' value="my">{locale['your_requests'][direction]}</Radio.Button>
            <Radio.Button className='titles' value="all">{locale['all_requests'][direction]}</Radio.Button>
            
            
            
          </Radio.Group>}
          <br />

     <List
     header={<BusinessHeader/>}
     dataSource={props.data[sortFilter] ||[]}
     pagination={{pageSize:5,responsive:true,defaultCurrent:1}}
     renderItem={(item:any) => (
      <>
      <BusinessItem data={item} />
      <br />
      </>
    )}
         >

     </List>
   </>
  )
}
