import React from "react";
import { Route } from "react-router-dom";
import UnAuthGuard from "../gaurds/UnAuthGuard";
import SignIn from "../Pages/Auth/sign-in";
import NotFoundAuth from "../layouts/Auth/NotFoundAuth";
import Request_password from "../Pages/Auth/Request-password";
import Update_Password from "../Pages/Auth/Update_Password";



const UnAuthRoutes = [
    <Route key="not-found-auth" path="*" element={<UnAuthGuard component={<NotFoundAuth />} />} ></Route>,

    <Route key="Login" path="/login" element={<UnAuthGuard component={<SignIn />} />} ></Route>,
    <Route key="req_pass" path="/request-password" element={<UnAuthGuard component={<Request_password />} />} ></Route>,
    <Route key="update_pass" path="/update-password" element={<UnAuthGuard component={<Update_Password />} />} ></Route>,


    

]

export default UnAuthRoutes;