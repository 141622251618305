import { Button, Modal } from 'antd'
import { locale } from 'dayjs'
import React, { useContext, useState } from 'react'
import { CustomContext } from '../../../../App'
import { IdcardOutlined } from '@ant-design/icons'
import BusinessCardData from './BusinessCardData'
import { MyAxios } from '../../../../globals/MyAxios'
import CustomLoading from '../../../../globals/CustomLoading'

function BusinessCard() {
    const {locale,direction}=useContext(CustomContext)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);


    const feachData=async()=>{
        setLoading(true)
        const res=await MyAxios.get('api/method/kabi.api.home.business_card')
        if (res.status==200){
            setLoading(false)
            setData(res.data.message)
        }
         
    }
    const showModal = () => {
      setIsModalOpen(true);
      feachData()
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  return (
    <>  
      <Button  size='large' onClick={showModal} style={{width:'100%',height:'20vh'}} icon={<IdcardOutlined />}>{locale.business_card[direction]}</Button>
      <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} style={{overflow:'hidden'}}>
        {loading?<CustomLoading/>:<BusinessCardData data={data}  />}
      </Modal>
    </>
  )
}

export default BusinessCard