import React from 'react'
import { Link } from 'react-router-dom'
import { TIME_Request_Color } from '../../../../globals/GlobalColors'
import { Card, Flex, Typography } from 'antd'

const {Text}=Typography
export default function LeaveItem(props:any) {
  return (
    <Link to={'/leave-application'+ '/' + props.data.doc.name + '/edit'} className='w-100'  >

    <Card hoverable bordered style={{ borderColor: TIME_Request_Color, borderWidth: 2, backgroundColor: TIME_Request_Color + '10', color: 'black200', width: '100%',textAlign:'center' }} >
        <Flex justify='space-around' gap={2}  align='center'  >
            
            <Text className='titles w-50' >{props.data.doc.name}</Text>
            <Text className='titles w-50' >{props.data.doc.leave_type}</Text>
            <Text className='titles w-50' >{props.data.doc.total_leave_days}</Text>

            <Text className='titles w-50' >{props.data.doc.workflow_state}</Text>
            <Text className='titles w-50' >{props.data.owner}</Text>
        </Flex>


    </Card>

</Link>  )
}
