import { Flex } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../App'

export default function ExpenseHeader() {
    const{locale,direction}=useContext(CustomContext)
    return (
      <Flex justify='space-around'  >
          <div className='titles'>{locale['name'][direction]}</div>
          {/* <Divider type='vertical'/> */}
          <div className='titles'>{locale['status'][direction]}</div>
          {/* <Divider type='vertical'/> */}
          <div className='titles'>{locale['payment_month'][direction]}</div>

          <div className='titles'>{locale['total_grand'][direction]}</div>

          <div className='titles'>{locale['owner'][direction]}</div>
          
          
      </Flex>
    )
}
