import React, { useContext, useEffect, useState } from 'react'
import AuthLayout from '../../layouts/Auth/AuthLayout'
import { Button, Card, Flex, Form, Input, message } from 'antd'
import { MyAxios } from '../../globals/MyAxios';
import { Link, useNavigate } from 'react-router-dom';
import { CustomContext } from '../../App';

export default function Request_password() {
  const nav=useNavigate()
  const [form] = Form.useForm();
  const {locale,direction}=useContext(CustomContext)
  const [loading,setLoading]=useState(false)
  const [users,setUsers]=useState<string[]>([])

  // Function to parse query parameters


  useEffect(()=>{
      const feachUser=async()=>{
          await MyAxios.get('api/method/kabi.api.user.get_all_users',{params:{key:''}})
          .then((res:any)=>{
              setUsers(res.data.message)
          })
      }
      feachUser()
  },[])
  
  const onFinish =async (e: any) => {

    setLoading(true)

    const params:any={
        email:e.email
    }

await MyAxios.get('api/method/kabi.api.user.send_email',{params} )
    .then(res=>{
            message.success(res.data.message)
            nav('/login')
    }).catch((error)=>message.error(error))
      setLoading(false)


  };

  const emailValidator = (_:any, value:any) => {
    if (!value || users.includes(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Email does not exist'));
  };

  return (
    <AuthLayout>
      <Flex >

      <Card style={{ width: '100%' }} title={locale.reset_password[direction]} bordered>
            <Form
                form={form}
                layout='vertical'
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // style={{ maxWidth: 1000 }}
                onFinish={onFinish}

                autoComplete="off"
            >
                <Form.Item
                    label={locale.email[direction]}
                    name="email"

                    rules={[{ required: true, message: 'Please input your username!' },{ validator: emailValidator }]}
                >
                    <Input  />
                </Form.Item>
                               <Link to='/login'><Button style={{display:'flex', justifySelf:'start'}} size='small' type='link'>{locale.back_login[direction]}</Button></Link>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>

                    <Button type="primary" htmlType="submit"  loading={loading}>
                        {locale.submit[direction]}
                    </Button>
                </Form.Item>
            </Form>
            
        </Card>
      </Flex>
    </AuthLayout>
  )
}
