import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Flex, FloatButton, List, Radio, Table, TableColumnsType } from 'antd'
import {  PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Title from 'antd/es/skeleton/Title'
import './style.css'
import GeneralHeader from './component/GeneralHeader'
import GeneralItem from './component/GeneralItem'
import { MyAxios } from '../../../globals/MyAxios'
import { CustomContext } from '../../../App'

export default function General_request() {
  const [loading,setLoading]=useState(true)
  const [sortFilter,setSortFilter]=useState<keyof typeof data>('my')
  const [data,setData]=useState({'my':[],'all':[]})
  const{locale,direction}=useContext(CustomContext)

  useEffect(()=>{
    const feachData=async()=>{
    const res =await MyAxios.get('api/method/kabi.api.get_lists.get_list',{params:{doctype:'General Request',allow_all:'HR Manager'}})
    setData(res.data.message)
    setLoading(false)
    }
    feachData()
  },[])

    return (
    <MainLayout title='General Request' loading={loading}>
      <Link to='/general-request/new'> 
           <FloatButton type='primary'   icon={<PlusOutlined  />}></FloatButton>
      </Link>
      {data['all'].length==0?<></>:
            <Radio.Group
            value={sortFilter}
            onChange={(e) => {
              setSortFilter(e.target.value);
            }}
          ><Radio.Button className='titles' value="my">{locale['your_requests'][direction]}</Radio.Button>
          <Radio.Button className='titles' value="all">{locale['all_requests'][direction]}</Radio.Button>
            
            
            
          </Radio.Group>}
          <br />

     <List
     header={<GeneralHeader/>}
     dataSource={data[sortFilter] ||[]}
     pagination={{pageSize:5,responsive:true,defaultCurrent:1}}
     renderItem={(item:any) => (
      <>
      <GeneralItem data={item} />
      <br />
      </>
    )}
         >

     </List>

    </MainLayout>
  )
}
