import { List } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { MyAxios } from '../../../../globals/MyAxios'
import MyRequestsCard from './MyRequestsCard'
import { CustomContext } from '../../../../App'

function MyRequestList(props:any) {
    const {locale,direction}=useContext(CustomContext)

    const [data,setData]=useState<any>({pending:[],approved:[],rejected:[]})
    const [loading,setLoading]=useState(true)
    useEffect(()=>{  
      setLoading(true)
  
      const feachData=async()=>{ 
  
        await MyAxios.get('api/method/kabi.api.myrequests.get_my_requests',{params:{direction:direction}}).then((res:any)=>{
          setData(res.data.message)
          setLoading(false)
  
        })
      }
      feachData()
    },[])
  return (
    <List
      
    pagination={{pageSize:8,responsive:true,defaultCurrent:1}}
    dataSource={data[props.state]}
    loading={loading}
    renderItem={(item:any) => (
      <List.Item
      key={item.doc.name}
      
      >
       <MyRequestsCard data={item} />
          
       </List.Item>
    )}
    >
     
    </List>  )
}

export default MyRequestList