import { Card, Flex, Rate } from 'antd'
import React, { useContext, useState } from 'react'
import { Form } from 'antd'
import { CustomContext } from '../../../../App';
import CompHelper from './CompHelper';
import './style.css'
function ComptencyComponents(props:any) {
    const {locale,direction}=useContext(CustomContext)
    const desc = [locale.poor[direction], locale.below_expectations[direction], locale.meets[direction], locale.exceeding_expectations[direction], locale.outstanding[direction]];
    const Ratedesc=[locale.poor_d[direction], locale.below_expectations_d[direction], locale.meets_d[direction], locale.exceeding_expectations_d[direction], locale.outstanding_d[direction]]
    const [value, setValue] = useState(1);

  return (
    <Flex wrap='wrap' gap={'small'} justify='space-around' >
    <Card style={{width:'45%'}}>
      <ul>
        <CompHelper typeComp={props.typeComp} profiency_level={props.profiency_level}/>
      
      </ul>
    </Card>
    
    <Flex vertical className='w-50' gap={'small'}  justify='center'>
    <Form.Item
    label={locale.rate[direction]}
    name={`rate_competency_${props.compNum}`}
    >
    <Rate tooltips={desc} onChange={setValue} value={value} />
    </Form.Item>
    {value ? <div style={{fontSize:'small'}} className='text-secondary w-50'>{Ratedesc[value - 1]}</div> : null}

    </Flex>
    </Flex>
  )
}

export default ComptencyComponents