import { Card, Flex, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { FINANCE_Color } from '../../../../globals/GlobalColors'
const {Text}=Typography
export default function PaymentItem(props:any) {
    return (
        <Link to={'/payment-request'+ '/' + props.data.doc.name + '/edit'} className='w-100'  >
    
        <Card hoverable bordered style={{ borderColor: FINANCE_Color, borderWidth: 2, backgroundColor: FINANCE_Color + '10', color: 'black200', width: '100%' }} >
            <Flex justify='space-around' gap={2} style={{textAlign:'center'}}  align='center'  >
                
                <Text className='titles w-50'  >{props.data.doc.name}</Text>
                <Text className='titles w-50' >{props.data.doc.templete}</Text>
                <Text className='titles w-50' >{props.data.doc.purchase_order}</Text>

                <Text className='titles w-50' >{props.data.doc.workflow_state}</Text>
                <Text className='titles w-50' >{props.data.owner}</Text>
            </Flex>
    
    
        </Card>
    
    </Link>
      )
}
