import { Button, Flex, Form, Input, Upload, UploadProps, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import { UploadOutlined } from '@ant-design/icons';
import { BASE_URL, RESOURCE_URL } from '../../../Helper/urls';

export default function Edit_profile() {
  const [form] = Form.useForm();

    const {locale,direction}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const [loading,setLoading]=useState(true)
  const nav=useNavigate();
  useEffect(()=>{
      
    const fecthData=async()=>{
      setLoading(true)
        await MyAxios.get('api/method/kabi.api.profile.get_edit_profile_data').then((res:any)=>{
          setData(res.data.message)  
            form.setFieldsValue({first_name:res.data.message.first_name,last_name:res.data.message.last_name,full_arabic_name:res.data.message.full_arabic_name,cell_number:res.data.message.cell_number})
            setLoading(false)

        })
        // await MyAxios.get('api/method/kabi.api.utils.get_attacments',{params:{doctype:Convert_to_base(doctype!),name:name}}).then((res:any)=>{
        // })
    
    }
    fecthData()
    
    
  },[])
  const add_profile_image=async (image:any)=>{
   
    await MyAxios.put(RESOURCE_URL+"File/"+image.fileList[0].response.message.name,{attached_to_doctype:"Employee",attached_to_field:'image',attached_to_name:localStorage.getItem('emp')})
    .then((res:any)=>{

      localStorage.setItem('image',image.fileList[0].response.message.file_url)
    }) 
    await MyAxios.put(RESOURCE_URL+"Employee/"+localStorage.getItem('emp'),{image:image.fileList[0].response.message.file_url}).then((res:any)=>{
    })
  }
  const hundlesubmit=async(event:any)=>{
    setLoading(true)
    await MyAxios.post('api/method/kabi.api.profile.edit_profile_data',{first_name:event.first_name,last_name:event.last_name,full_arabic_name:event.full_arabic_name,cell_number:event.cell_number,emp:localStorage.getItem('emp'),file_name:event.image!=undefined?event.image.fileList[0].response.message.name:'',user:localStorage.getItem('email')})
    .then((res:any)=>{
      message.success("Your Profile has been Updated")
        localStorage.setItem('full_name_ar',event.full_arabic_name)
        localStorage.setItem('full_name',event.first_name+" "+event.last_name)
      if(event.image!=undefined)
                localStorage.setItem('image',event.image.fileList[0].response.message.file_url)
        setLoading(false)
        // add_profile_image(event.image)
        window.location.reload()
    })

  }
  const props: UploadProps = {
    action: BASE_URL+`api/method/upload_file`,
    headers:{
        Accept: 'application/json',
        Authorization: localStorage.getItem('token')??""
    },
    onChange({ file, fileList }) {

     
    },
    accept:'.png,.jpg,.jpeg',
    listType:'picture-circle'
  

    
  };

  return (
    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}
  >
    <Flex gap='small' wrap='wrap'  >
      <Form.Item
      name="first_name"
      label={locale.first_name[direction]}
      required
      
    >
      <Input disabled={loading} style={{width:'30vw'}}/>
    </Form.Item>
    <Form.Item
      name="last_name"
      label={locale.last_name[direction]}
      required
      
    >
      <Input disabled={loading} style={{width:'30vw'}}/>
    </Form.Item>
    <Form.Item
      name="full_arabic_name"
      label={locale.full_name_ar[direction]}
      
    >
      <Input disabled={loading} style={{width:'30vw'}}/>
    </Form.Item>
    <Form.Item
      name="cell_number"
      label={locale.phone_number[direction]}
      
    >
      <Input disabled={loading} style={{width:'30vw'}}/>
    </Form.Item>
 
    </Flex>
     <Form.Item
    name={'image'}
    label={locale.profile_image[direction]}
    
    >

<Upload multiple {...props} >
    <Button loading={loading}  icon={<UploadOutlined />}>{locale['upload'][direction]}</Button>
  </Upload> 

    </Form.Item>
   
    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
  )
}
