import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyAxios } from "../../globals/MyAxios";

export const TeamSlice=createSlice(
    {
        name:'team',
        initialState:{
            loading:true,
            data:{
            org_chart:[],
            team:[] 
            }
           
        },
        reducers:{
            getAllEmployee:()=>{

            },
            getAllEmployeeChart:()=>{

            }
            

        },
        extraReducers(builder) {
            builder.addCase(feachTeamData.fulfilled,(state,action)=>{
                state.loading=false
                state.data=action.payload

            })
            builder.addCase(feachTeamData.pending,(state,action)=>{
                state.loading=true
                
            })
            builder.addCase(feachTeamData.rejected,(state,action)=>{
                state.loading=false
                
            })

        },
    }
    )
export const feachTeamData=createAsyncThunk('feacth/team',
async()=>{
    const res=await MyAxios.get('api/method/kabi.api.team.get_emps')
    return res.data.message
}
)
export const {getAllEmployee,getAllEmployeeChart}=TeamSlice.actions
export default TeamSlice.reducer