import { Button, Result } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import MainLayout from './MainLayout'
import { CustomContext } from '../../App'

export default function NotFound() {
  const { locale, direction } = useContext(CustomContext)

  return (
    <MainLayout title='Sorry 404'>
      <Result
        status="404"
        title="404"
        subTitle={locale['sorry_404'][direction]}
        extra={<Button type="primary"><Link to='/'>{locale['back_home'][direction]}</Link> </Button>}
      /></MainLayout>


  )
}
