import { Card, Col, Flex,Typography } from 'antd'
import React from 'react'
import MyCustomAvatar from '../../../../globals/MyCustomAvatar'
import { GiftOutlined, TrophyOutlined } from '@ant-design/icons'
const {Title,Text}=Typography
export default function MyEventCard(props:any) {
  return (
    <Card size='small'>
    <Flex justify='space-around' align='center'>
        <MyCustomAvatar
        image={props.image}
        name={props.name}
        size='large'
        
        />

    <div className='d-block'>        <Text style={{fontWeight:'bold'}}>{props.name}</Text>
    <br />
        <Text style={{color:'gray',fontSize:'small'}}> {props.job_title}</Text></div>

        
       {props.isBD?<GiftOutlined style={{color:'#D669FE' }} twoToneColor='#D669FE' />:<TrophyOutlined style={{color:'#FFC43D'}} twoToneColor='#FFC43D' />} 
        
    </Flex>

    </Card>

  )
}
