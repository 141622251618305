import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyAxios } from "../../globals/MyAxios";
import { stat } from "fs";


const ApprovalSlice=createSlice({
    name:'Approval',
    initialState:{
        loading:true,
        data:{}
    },
    reducers:{

    },
    extraReducers(builder) {
        builder.addCase(featchApprovalData.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload

        })
        builder.addCase(featchApprovalData.pending,(state,action)=>{
            state.loading=true
            
        })
        builder.addCase(featchApprovalData.rejected,(state,action)=>{
            state.loading=false
            
        })
        builder.addCase(applyAction.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload

        })
        builder.addCase(applyAction.pending,(state,action)=>{
            state.loading=true
            
        })
        builder.addCase(applyAction.rejected,(state,action)=>{
            state.loading=false
            
        })
    },
})
export const featchApprovalData=createAsyncThunk('featch/approval',
async()=>{
    const res= await MyAxios.get('api/method/kabi.api.approval.get_actions')
    return res.data.message
}
);
export const applyAction=createAsyncThunk('featch/apply_action',
async ({ doctype, name, action }: { doctype: string; name: string; action: string }) => {
    const res= await MyAxios.post('api/method/kabi.api.approval.apply',{doctype:doctype,name:name,action:action})
    return res.data.message
}
);

export default ApprovalSlice.reducer