import { HistoryOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin, Steps, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MyAxios } from '../../../globals/MyAxios'
import { CustomContext } from '../../../App'
import { Convert_to_base } from '../../../Helper/Doc_Functions'
import ReactDOMServer from 'react-dom/server';

import './style.css'

export default function WorkflowUI(props:any) {
  const {doctype,name}=useParams()
const [isLoading,setIsLoading]=useState(true)
const [data,setData]=useState<any>({currentindex:0,items:[],status:'proccess'})
const {direction}=useContext(CustomContext)
useEffect(()=>{ 
  
    const feachData=async()=>{
        await MyAxios.get('api/method/kabi.api.workflow.get_workflow',{params:{doctype:Convert_to_base(doctype!) ,docname:name,direction:direction}}).then((res:any)=>{
            setData(res.data.message)
            setIsLoading(false)
            const jsxString = ReactDOMServer.renderToString(<HistoryOutlined  />);
             const number_element= document.querySelector("#rc-tabs-1-panel-1 > div > div.ant-steps-item.ant-steps-item-proccess.ant-steps-item-active > div > div.ant-steps-item-icon > span")
            if(number_element){
      
            number_element.innerHTML=jsxString

              }
        }).catch(error=>{
          message.error('Error: feach Workflow')
          setIsLoading(false)
        })
    }
    feachData() 
    
    
    // const jsxString = ReactDOMServer.renderToString(<HistoryOutlined  />);
    //          const number_element= document.querySelector("#rc-tabs-1-panel-1 > div > div.ant-steps-item.ant-steps-item-proccess.ant-steps-item-active > div > div.ant-steps-item-icon > span")
    //         if(number_element){
      
    //         number_element.innerHTML=jsxString

    //           }
     

},[])

if(isLoading){
        return (<Spin style={{display:'flex',justifyContent:'center'}}  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />)

}

  return (

<Steps
current={data['currentindex']==undefined?0:data['currentindex']}
status={data['status']}
responsive
items={data['items']}
>

</Steps>
    )
}
