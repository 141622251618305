import React from 'react'
import { Link } from 'react-router-dom'
import { Convert_from_base_doctype_to, get_doc_type_color } from '../../../../Helper/Doc_Functions'
import { Card, Flex,Typography } from 'antd'
import { HR_RequestColor } from '../../../../globals/GlobalColors'
const {Text}=Typography

export default function GeneralItem(props:any) {
  return (
    <Link to={'/general-request'+ '/' + props.data.doc.name + '/edit'} className='w-100'  >

            <Card hoverable bordered style={{ borderColor: HR_RequestColor, borderWidth: 2, backgroundColor: HR_RequestColor + '10', color: 'black200', width: '100%' }} >
                <Flex justify='space-around' gap={2}  align='center'  >
                    
                    <Text className='titles' strong>{props.data.doc.name}</Text>
                    <Text className='titles' strong>{props.data.doc.workflow_state}</Text>
                    <Text className='titles' strong>{props.data.owner}</Text>
                </Flex>


            </Card>

        </Link>
  )
}
