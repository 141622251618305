import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyAxios } from "../../globals/MyAxios";


const PermissionSlice=createSlice({
    name:'permission',
    initialState:{loading:false,data:{}},
    reducers: {
        
    },
    extraReducers(builder) {
        builder.addCase(get_permission.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(get_permission.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(get_permission.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
          

        })    
    },
})
export const get_permission=createAsyncThunk('fecth/permission',
async()=>{
    const res =await MyAxios.get('api/method/kabi.api.permission.get_permission')
    return res.data.message
}
)
export default PermissionSlice.reducer 