import React, { useContext } from 'react'
import { Flex, Typography } from 'antd'
import { CustomContext } from '../../../../App'
const {Title}=Typography
export default function BusinessPolicy() {
  const {locale,direction}=useContext(CustomContext)
  return (
    <Flex align='center' justify='center'>
            <Title  level={5} style={{color:'gray'}}>{locale.no_policies[direction]}</Title>
</Flex>

    )
}
