import { Flex } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'

export default function RemoteHeader() {
    const{locale,direction}=useContext(CustomContext)

  return (
    <Flex justify='space-around' style={{textAlign:'center'}} >
  <div className='titles'>{locale['name'][direction]}</div>
    <div className='titles'>{locale['remote_day'][direction]}</div>

    <div className='titles'>{locale['status'][direction]}</div>
    <div className='titles'>{locale['owner'][direction]}</div>
    
    
</Flex>
  )
}
