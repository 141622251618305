import React,{lazy} from "react";
import { Route, } from "react-router-dom";
import AuthGuard from "../gaurds/Authguards";
import Home from "../Pages/general/Home/home";
import Approval from "../Pages/approval/approval";
import Profile_page from "../Pages/profile/Profile_page";
import Reset_pasword from "../Pages/reset_password/Reset_pasword";
import Team from "../Pages/general/Team/team";
import Leaderboard from "../Pages/general/leaderboard/leaderboard";
import Performance_page from "../Pages/general/performance/performance_page";
import Expense_claim from "../Pages/expence_claim/Expense_claim";
import General_request from "../Pages/hr_request/general_request/General_request";
import Document_page from "../Pages/hr_request/document/Document";
import LeaveApplication from "../Pages/time_request/leave_application/LeaveApplication";
import RemoteWork from "../Pages/time_request/Remote_Work/RemoteWork";
import BusinessTrip from "../Pages/time_request/Business_trip/BusinessTrip";
import NotFound from "../layouts/main/NotFound";
import MobileVersion from "../Pages/Mobile/MobileVersion";
import Project from "../Pages/Project/Project";
import PurchaseOrder from "../Pages/Finance/Purchase-Order/PurchaseOrder";
import PaymentRequestList from "../Pages/Finance/Payment-Request/PaymentRequest";
import MyForm from "../Pages/Forms/MyForm";
import AttendanceReport from "../Pages/hr/attendanceReport.tsx/AttendanceReport";
import FlushPage from "../Pages/Auth/FlushPage";
import MyRequest from "../Pages/general/My_request/MyRequest";
import EvaluationDashboard from "../Pages/hr/Performance/EvauationDashboard";
import EvaluationForm from "../Pages/hr/Performance/EvaluationForm";



const AuthRoutes = [
  
    <Route key="home" path="/" element={<AuthGuard component={<Home/>} />} />,
   

    <Route key="profile" path="/profile" element={<AuthGuard component={<Profile_page/>} />} />,
    <Route key="reset-password" path="/reset-password" element={<AuthGuard component={<Reset_pasword/>} />} />,
    <Route key="approval" path="/approval" element={<AuthGuard component={<Approval/>} />} />,
    <Route key="team" path="/team" element={<AuthGuard component={<Team/>} />} />,
    <Route key="leaderboard" path="/leaderboard" element={<AuthGuard component={<Leaderboard/>} />} />,
    <Route key="performance" path="/performance" element={<AuthGuard component={<Performance_page/>} />} />,
    <Route key="general-request" path="/general-request" element={<AuthGuard component={<General_request/>} />} />,
    <Route key="document" path="/document" element={<AuthGuard component={<Document_page/>} />} />,
    <Route key="leave-application" path="/leave-application" element={<AuthGuard component={<LeaveApplication/>} />} />,
    <Route key="remote-work" path="/remote-work" element={<AuthGuard component={<RemoteWork/>} />} />,
    <Route key="business-trip" path="/business-trip" element={<AuthGuard component={<BusinessTrip/>} />} />,
    <Route key="expense-claim" path="/expense-claim" element={<AuthGuard component={<Expense_claim/>} />} />,
    <Route key="purchase-order" path="/purchase-order" element={<AuthGuard component={<PurchaseOrder/>} />} />,
    <Route key="payment-request" path="/payment-request" element={<AuthGuard component={<PaymentRequestList />} />} />,
    <Route key="mobile" path="/mobile" element={<AuthGuard component={<MobileVersion/>} />} />,
    <Route key="project" path="/project" element={<AuthGuard component={<Project/>} />} />,
    <Route key="attendance" path="/attendance" element={<AuthGuard component={<AttendanceReport/>} />} />,
    <Route key="after" path="/loading" element={<AuthGuard component={<FlushPage/>} />} />,
    <Route key="my_request" path="/my-requests" element={<AuthGuard component={<MyRequest/>} />} />,
    <Route key="dash-evaluation" path="/evaluation-form/dashboard" element={<AuthGuard component={<EvaluationDashboard/>} />} />,
    <Route key="evaluation-form" path="/evaluation-form" element={<AuthGuard component={<EvaluationForm/>} />} />,
    <Route key="form-new" path="/:doctype/new" element={<AuthGuard component={<MyForm/>} />} />,
    <Route key="form-edit" path="/:doctype/:name/edit" element={<AuthGuard component={<MyForm edit={true}/>} />} />,
    <Route key="not-found" path="*" element={<AuthGuard component={<NotFound/>} />} />,
]

export default AuthRoutes;