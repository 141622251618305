import { Card, Flex,Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { HR_Color } from '../../../../globals/GlobalColors'
const {Text,Title}=Typography

export default function AttendanceCard(props:any) {
    const [startTime,setStartTime]=useState("")
    const [endTime,setEndTime]=useState("")
    const [TotalTime,setTotalTime]=useState("")
    useEffect(()=>{
        setStartTime(formatTime(props.item.creation))
        setEndTime(formatTime(props.item.checkout))

        
    },[])

    function formatTime(datetime: string): string {
        // Create a Date object from the datetime string
        const date = new Date(datetime);
    
        // Convert to 12-hour time format using toLocaleTimeString
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    
        return formattedTime=='Invalid Date'?'':formattedTime;
    }
    function getTimeDifference(startTime: string, endTime: string): string {
        // Parse time strings into JavaScript Date objects (on the same arbitrary day)
        const baseDate = "2024-01-01";
        const startDateTime = new Date(`${baseDate} ${startTime}`);
        const endDateTime = new Date(`${baseDate} ${endTime}`);
    
        // Calculate difference in seconds
        let diffInSeconds = (endDateTime.getTime() - startDateTime.getTime()) / 1000;
    
        // Handle negative differences if end time is before start time
        if (diffInSeconds < 0) {
            diffInSeconds += 24 * 60 * 60;  // Add one day in seconds
        }
    
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(diffInSeconds / 3600);
        diffInSeconds %= 3600;
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;
    
        // Format the difference as "0h 6m 40s"
        return `${hours}h ${minutes}m ${Math.floor(seconds)}s`;
    }
    
  return (

    <Card hoverable bordered style={{ borderColor: HR_Color, borderWidth: 2, backgroundColor: HR_Color + '10', color: 'black200', width: '100%' }} >
        <Flex justify='space-around'  align='center'  >
            
            <Text className='titles' >{props.item.employee_name}</Text>
            <Text className='titles' >{props.item.attendance_date}</Text>
            <Text className='titles p-1 rounded' style={{color:'white' ,backgroundColor:'#50C878'}} >{startTime}</Text>
            <Text className='titles p-1 rounded' style={{color:props.item.checkout==undefined?'':'white',backgroundColor:props.item.checkout==undefined?'':'#ED2939'}} >{props.item.checkout==undefined?'------------':endTime}</Text>
            <Text className='titles d-lg-flex d-none' >{props.item.checkout==undefined?'-------------':getTimeDifference(startTime,endTime)}</Text>

        </Flex>


    </Card>
  )
}
