import React, { useContext, useRef, useState } from 'react';
import { CustomContext } from '../../../../App';
import { FloatButton } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

interface DragScrollContainerProps {
  children: React.ReactNode;
}

export function DragScrollContainer({ children }: DragScrollContainerProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    if (containerRef.current) {
      setStartX(event.pageX - containerRef.current.offsetLeft);
      setStartY(event.pageY - containerRef.current.offsetTop);
      setScrollLeft(containerRef.current.scrollLeft);
      setScrollTop(containerRef.current.scrollTop);
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !containerRef.current) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const y = event.pageY - containerRef.current.offsetTop;
    const walkX = (x - startX) * 2; // You can adjust the scrolling speed here
    const walkY = (y - startY) * 2;
    containerRef.current.scrollLeft = scrollLeft - walkX;
    containerRef.current.scrollTop = scrollTop - walkY;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };
  const {mode}=useContext(CustomContext)
  const [scale, setScale] = useState(1);

  const handleZoomIn = () => {
      if (scale < 2) setScale(scale + 0.25);
  };
  
  const handleZoomOut = () => {
      if (scale > 0.25) setScale(scale - 0.25);
  };
  
  return (
    <div
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      className='ng'
      style={{
        overflow: 'auto',
        cursor: isDragging ? 'grabbing' : 'grab',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        maxHeight: '80vh', 
        WebkitOverflowScrolling: 'touch', // Add smooth scrolling on iOS
        backgroundColor:mode==='dark'?'black':'#F5F5F5'
      }}
    >
            {/* <div className="genealogy-body "> */}
            <div className="genealogy-tree d-flex"style={{ transform: `scale(${scale})` ,flexWrap:'nowrap'}} >
      {children}
      {/* </div> */}

            
        </div>
              <FloatButton.Group shape="square" style={{ right: 94 }}>
      <FloatButton onClick={handleZoomIn} icon={<ZoomInOutlined />} />
     
      <FloatButton onClick={handleZoomOut} icon={<ZoomOutOutlined />} />
     
    </FloatButton.Group>
      </div>
  );
}


