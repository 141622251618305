import React, { useContext, useState } from 'react'
import { DragScrollContainer } from './DragMouse'
import { OrganizationChart } from 'primereact/organizationchart';
import { Card, Divider,FloatButton,Typography } from 'antd';
import MyCustomAvatar from '../../../../globals/MyCustomAvatar';
import { CustomContext } from '../../../../App';

import './style.css'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import EmployeeNode from './EmployeeNode';
const{Text}=Typography

export default function OrgChart(props:any) {
 const {mode}=useContext(CustomContext)

  return (
    <DragScrollContainer >
       
      {/* <OrganizationChart  style={{        transform:'scale(0.7)', display:'flex',justifyContent:'start'}}  value={props.data} nodeTemplate={nodeTemplate} /> */}

                <ul id="root">
                    <EmployeeNode employee={props.data} scale={props.scale} />
                </ul>

</DragScrollContainer>
  )
}
