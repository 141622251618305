import { Table } from 'antd';
import React, { memo, useContext, useEffect, useState } from 'react'
import CustomLoading from '../../../globals/CustomLoading';
import { locale } from 'dayjs';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { Link } from 'react-router-dom';

function PurchaseTable(props:any) {
    const [data,setData]=useState([]);
    const [loading,isLoading]=useState(false);
    const {locale,direction}=useContext(CustomContext)

    useEffect(()=>{
        const feachData=async()=>{
            isLoading(true)
            await MyAxios.get('api/method/kabi.api.table.get_transaction',{params:{po:props.po}}).then((res:any)=>{
                setData(res.data.message)
                isLoading(false)
            })
            isLoading(false)

        }
        feachData()
    },[])
    const columns = [
        {
          title: locale.transaction[direction],
          dataIndex: 'transaction',
          key: 'transaction',
        },
        {
          title: locale.amount[direction],
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: locale.document[direction],
          dataIndex: 'document',
          key: 'document',
          render: (_:any, record:any) => {
            if (record.name!='') {
                return <Link to={`/payment-request/${record.name}/edit`}>{record.name}</Link>;
            } else {
                return null; 
            }
        },
        },

      ];
  return (
    <>
    {loading?<CustomLoading/>:<Table pagination={false} className='d-lg-flex d-none px-2' dataSource={data} columns={columns} />}
</>
  )
}

export default memo(PurchaseTable)