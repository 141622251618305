import { Badge, Col,Typography } from 'antd'
import React from 'react'
import '../style.css'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { setRead, set_Read } from '../../../redux/Notification/NotificationSlice'
const {Text}=Typography
export default function NotificationItem(props:any) {
 const dispatch=useDispatch<AppDispatch>()

 const hundleClick=(notification:any)=>{
  if(!notification.read){
     dispatch(setRead(notification.name))
    dispatch(set_Read(notification.name))
  }
   
    // window.location.reload()
 }
  return (
   <>
   {/* <a href={props.notification.href}> */}

    <div className='d-flex gap-2 ' onClick={()=>hundleClick(props.notification)}>
        <Col>
        {props.notification.read?<Badge status="processing"  style={{visibility:'hidden'}}/>: <Badge status="processing" />
}
        </Col>

        <Col>
        <div dangerouslySetInnerHTML={{__html:props.notification.subject}} ></div>
        
        <div style={{fontSize:'small',color:'gray'}} dangerouslySetInnerHTML={{__html:props.notification.email_content}} ></div>
        
        </Col>
        
    </div>
        
   {/* </a> */}
   </>
  )
}
