import { createSlice } from "@reduxjs/toolkit";

const PostsSlice=createSlice({
    name:'post',
    initialState:[],
    reducers:{
        getAll:()=>{

        }
    }
})
export const {getAll}=PostsSlice.actions
export default PostsSlice.reducer