import React, { useContext, useEffect, useState } from 'react';
import { Radio, Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import MainLayout from '../../../layouts/main/MainLayout';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { useDispatch, useSelector } from 'react-redux';
import { feachLeaderboard } from '../../../redux/leaderboard/LeaderboardSlice';
import { AppDispatch } from '../../../redux/store';

interface DataType {
  key: React.Key;
  rank:string;
  name: string;
  Score: number;
}


 function Leaderboard(){
  const {direction,locale}=useContext(CustomContext)
  const leaderboard=useSelector((state:any)=>state.leaderboard)
  const dispatch=useDispatch<AppDispatch>()
  useEffect(()=>{
    dispatch(feachLeaderboard())
  },[])
  const columns: TableColumnsType<DataType> = [
    {
      title: locale['rank'][direction],
      dataIndex: 'rank',
      

    },
    {
      title: locale['name'][direction],
      dataIndex: 'name',
      

    },
    {
      title: locale['score'][direction],
      dataIndex: 'value',
    },

  ];
  

  const [all_data,setAll_data] :any=useState(leaderboard.data)  

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const [sortFilter,setSortFilter]=useState('all')
  
 return <MainLayout title="Leaderboard" loading={leaderboard.loading}>
            <Radio.Group
            value={sortFilter}
            onChange={(e) => {
              setSortFilter(e.target.value);
            }}
          ><Radio.Button value="all">{locale['all'][direction]}</Radio.Button>
          <Radio.Button value="mounth">{locale['this_mounth'][direction]}</Radio.Button>
            <Radio.Button value="year">{locale['this_year'][direction]}</Radio.Button>
            
            
            
          </Radio.Group>
  <Table columns={columns} dataSource={leaderboard.data[sortFilter]} onChange={onChange} size='small'    pagination={{pageSize:20,defaultCurrent:1,}}
 sticky />
  </MainLayout> ;
 }
export default Leaderboard;