import { Avatar, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { BASE_URL } from '../Helper/urls'
import axios from 'axios'

export default function MyCustomAvatar(props:any) {
    const MinizeName=(name:string)=>{
        const res:string[]=name.split(' ')
        return `${res[0].charAt(0).toUpperCase()}${res[1].charAt(0).toUpperCase()}`
    }
    const fetchphoto =async(url:string)=>{
      const res=await axios.get(url,{headers:{Authorization:localStorage.getItem('token')}})
      console.log(res)
      // return res.data
    }
    // useEffect(()=>{
    //   fetchphoto(BASE_URL.slice(0, -1)+props.image )
    // },[])

  return (
    <> 
     {/* <img src={BASE_URL.slice(0, -1)+props.image} alt=''/> */}
  <Tooltip title={props.name}>
    {props.image?
   <Avatar src={BASE_URL.slice(0, -1)+props.image } size={props.size}/>
    // {fetchphoto(BASE_URL.slice(0, -1)+props.image) }
    :<Avatar style={{ backgroundColor: '#29A0D1', verticalAlign: 'middle' }} size={props.size}>{MinizeName(props.name)}</Avatar>}
   </Tooltip>
    </>
  
  )
}
