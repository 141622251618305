import { Card, Divider,Typography } from 'antd'
import React from 'react'
import MyCustomAvatar from '../../../../globals/MyCustomAvatar'
const {Text}=Typography
export default function TeamItem(props:any) {
  return (
    <Card style={{textAlign:'center',width:''}} className='mx-2'  hoverable>
    <Divider orientation='center'>
    
    <MyCustomAvatar image={props.emp.image} name={props.emp.employee_name} size='large'/>

    </Divider>
    <Text style={{fontWeight:'bolder'}}>{props.emp.employee_name}</Text>
    <br />
    <Text style={{fontSize:'small',color:'gray'}}>{props.emp.job_title}</Text>
    <Divider></Divider>
    <Text style={{fontSize:'small',color:'gray'}}>{props.emp.prefered_email}</Text>
    <br />
    <Text style={{fontSize:'small',color:'gray'}}>{props.emp.cell_number}</Text>
  </Card>
  )
}
