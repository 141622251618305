import React from 'react'
import { Link } from 'react-router-dom'
import { TIME_Request_Color } from '../../../../globals/GlobalColors'
import { Card, Flex, Typography } from 'antd'
const{Text}=Typography
export default function BusinessItem(props:any) {
    return (
        <Link to={'/business-trip/'+ props.data.doc.name + '/edit'} className='w-100'  >
    
        <Card hoverable bordered style={{ borderColor: TIME_Request_Color, borderWidth: 2, backgroundColor: TIME_Request_Color + '10', color: 'black200', width: '100%',textAlign:'center' }} >
            <Flex justify='space-around' gap={2}  align='center'  >
                
                <Text className='titles' >{props.data.doc.name}</Text>
                <Text className='titles' >{props.data.doc.location}</Text>
    
                <Text className='titles' >{props.data.doc.workflow_state}</Text>
                <Text className='titles' >{props.data.owner}</Text>
            </Flex>
    
    
        </Card>
    
    </Link>  )  
}
