import React, { useContext, useEffect, useState } from 'react'
import {  useNavigate, useParams } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import { RESOURCE_URL } from '../../../Helper/urls';
import TextArea from 'antd/es/input/TextArea';
import { Button, Flex, Form ,DatePicker, Select } from 'antd';
import MyUpload from '../Component/MyUpload';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const{RangePicker}=DatePicker
export default function BusinessTripForm(props:any) {
    const [form] = Form.useForm();
    let { doctype,name } = useParams();

    const {locale,direction}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const [getalllist,setGetalllist]=useState<any[]>([])
    const[emp,setEmp]=useState("")

    const nav=useNavigate()
    const addAttachment=async(file:any,docname:string)=>{
      await MyAxios.put('api/resource/File/'+file.uid,{attached_to_doctype:Convert_to_base(doctype!),attached_to_name:docname})
  }
    useEffect(()=>{
      
      const fecthData=async()=>{
        if(name){
          await MyAxios.get(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name).then((res:any)=>{
            setData(res.data.data)
            form.setFieldsValue({dates:[dayjs(res.data.data.from_date),dayjs(res.data.data.to_date)],reason:res.data.data.description,location:res.data.data.location})

          })

      }
      if(name===undefined){
        await MyAxios.get('api/method/kabi.api.home.get_current_emp').then((res:any)=>{
        setEmp(res.data.message)

      }).catch(error=>console.error(error))
    
      }
      await MyAxios.get('api/method/kabi.api.time_request.get_emp_bt').then((res:any)=>{
        // console.log(res.data.message)

        setGetalllist(res.data.message)
      }).catch(error=>console.error(error))
      }
      fecthData()
      
    },[])
    const hundlesubmit=async(event:any)=>{
      if(name===undefined){
      await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),{description:event.reason,from_date:dayjs(event.dates[0]).format('YYYY-MM-DD'),to_date:dayjs(event.dates[1]).format('YYYY-MM-DD'),employee:emp,location:event.location})      .then((e:any)=>{
        // console.log(event.attachment);
        if(event.attachment!==undefined){
          for (let i = 0; i < event.attachment.fileList.length; i++) {
          if(event.attachment.fileList[i].status=='done'){
            addAttachment(event.attachment.fileList[i],e.data.data.name)

          }
          
        }
        }
        

        nav('/'+doctype)
      }
    )
      .catch(err=>console.log(err))
        nav('/'+doctype)
      }else{
        await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,{description:event.reason,from_date:dayjs(event.dates[0]).format('YYYY-MM-DD'),to_date:dayjs(event.dates[1]).format('YYYY-MM-DD'),location:event.location})      .then((e:any)=>{
          // console.log(event.attachment);
          if(event.attachment!==undefined){
            for (let i = 0; i < event.attachment.fileList.length; i++) {
            if(event.attachment.fileList[i].status=='done'){
              addAttachment(event.attachment.fileList[i],e.data.data.name)
  
            }
            
          }
          }
          
  
          nav('/'+doctype)
        }
      )
        .catch(err=>console.log(err))

      }

    }
    // if(name)
    // form.setFieldsValue({reason:data.reason})
    const disabledDateRanges = (current:any) => {
      for (let i = 0; i < getalllist.length; i++) {
        const { from_date, to_date,workflow_state } = getalllist[i];
        if (dayjs(current).isSameOrAfter(dayjs(from_date)) && dayjs(current).isSameOrBefore(dayjs(to_date)) && workflow_state !== 'مرفوض') {
          if(name===undefined)
          return true;
        else{
          if(getalllist[i].name!==name){
            return true 
          }
          else{
            return false
          }
        }
      
        }
      }
      return false;
    };
    const validateDateRange = (rule:any, value:any) => {
      // if(name){
        const [startDate, endDate] = value;
      if (!startDate || !endDate) {
        return Promise.reject('Please select dates');
      }
      
      for (let i = 0; i < getalllist.length; i++) {
        const { from_date, to_date,workflow_state } = getalllist[i];
        if (dayjs(startDate).isBefore(to_date) && dayjs(endDate).isAfter(from_date)&&(workflow_state !=='مرفوض')&&(getalllist[i].name!=name)) {
          return Promise.reject('Disabled date range selected')
 
        }
      }
      // }
       return Promise.resolve();

    };
    // if(name){
    //       form.setFieldsValue({dates:[dayjs(data.from_date),dayjs(data.to_date)],reason:data.description,location:data.location})

    // }
  return (
    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}

   
  >
    <Flex gap={5}>
    <Form.Item 
    label={locale.dates[direction]}
    name='dates'
    rules={[{required:true},{validator:validateDateRange}]}
    >
    <RangePicker disabled={data.docstatus==1||name!==undefined} disabledDate={disabledDateRanges} />

    </Form.Item>
    <Form.Item 
    label={locale.location[direction]}
    name='location'
    rules={[{required:true},{validator:validateDateRange}]}
    >
<Select
      defaultValue="KSA"
      // style={{ width: 120 }}
      options={[
        { value: 'KSA', label: locale.ksa[direction] },
        { value: 'GCC & Arab countreis', label: locale.gcc[direction] },
        { value: 'Other', label: locale.other[direction] },
      ]}
    />
    </Form.Item>
    </Flex>
    
    <Flex gap={5}>
        <Form.Item
      name="reason"
      label={locale['reason'][direction]}
      rules={[{ required: true }]}
    >
    <TextArea disabled={data.docstatus>=1} rows={4} />
    </Form.Item>
   
     <MyUpload docstatus={data.docstatus==1 }  attachment_field='attachment'/>
   
    </Flex>
    
    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
  )
}
