import { Flex } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'

export default function AttendanceHeader() {
    const{locale,direction}=useContext(CustomContext)

    
    return (
      <Flex justify='space-around'  align='center'  >
          <div className='titles'>{locale.name[direction]}</div>
          <div className='titles'>{locale.date[direction]}</div>

          <div className='titles'>{locale.checkin[direction]}</div>
          <div className='titles'>{locale.checkout[direction]}</div>
          <div className='titles d-lg-flex d-none'>{locale.working_hour[direction]}</div>
      </Flex>
    )
}
