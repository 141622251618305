import Card from 'antd/es/card/Card'
import Title from 'antd/es/typography/Title'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'
import MyEventCard from './MyEventCard'
import { Space } from 'antd'

export default function MyEvents(props:any) {
  const {direction,locale}=useContext(CustomContext)
  const get_count_events=()=>{
    let result=0
    for (let i=0;i<props.data.length;i++){
      for (let j = 0; j < props.data[i].events.length; j++) {
        result++;
        
      }
    }
    return result
  }
  return (
    <Card style={{gap:4,height:'50vh',overflow:'scroll'}}>
      <Title className='sticky-top' level={5}>{locale['events'][direction]} <span style={{color:'gray'}}>({get_count_events()})</span> </Title>
      <Space  direction='vertical' className='w-100'>
        
         { props.data.length===0?(<div style={{color:'gray' , textAlign:'center'}}>No Result</div>):
         (props.data.map((e:any)=>{
          return  (
          <>
          <div style={{color:'gray' , textAlign:'start'}}>{e.date}</div>
          {e.events.map((events:any)=>{
            return <MyEventCard name={events.name} image={events.image} job_title={events.job_title} isBD={events.isBD} />
          })}
          </>
           )
          
          
        
        
            

         }))}
              
        
        

      
      </Space>


    </Card>
  )
}
