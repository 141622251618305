import React, { useContext, useEffect, useState } from 'react'
import { CustomContext } from '../../../../App'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import { Check_in, Check_out, fecthAttendance } from '../../../../redux/Attendance/AttendanceSlice'
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Flex, Popconfirm, Spin,Typography, message } from 'antd'
const {Text,Title}=Typography

export default function AttendanceCheckin() {
    const {locale,direction}=useContext(CustomContext)
    const [Location,setLocation]=useState({latitude: 0, longitude: 0 })
    const attendance=useSelector((state:any)=>state.attendance.data)
    const loading=useSelector((state:any)=>state.attendance.loading)
    const [ActivateLocation,isActivateLocation]=useState(false)
    const dispatch=useDispatch<AppDispatch>()
    const refreshAttendence=()=>{
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            // console.log(position.coords)
            setLocation({ latitude, longitude });
            isActivateLocation(true)
            dispatch(fecthAttendance({lat:latitude, lon:longitude}));
        }, (error) => {
          isActivateLocation(false)
        },{       
             enableHighAccuracy: true, 
        timeout: 10000,          
        maximumAge: 0
     });
    }
    useEffect(()=>{
        refreshAttendence()
    },[])
    function formatTime(datetime: string): string {
        // Create a Date object from the datetime string
        const date = new Date(datetime);
    
        // Convert to 12-hour time format using toLocaleTimeString
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    
        return formattedTime=='Invalid Date'?'':formattedTime;
    }
    
    if(loading){
        return <Spin style={{display:'flex',justifyContent:'center'}}  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    }else
  return (
    <> {!navigator.geolocation?<Text style={{color:'gray',textAlign:'center'}}>{locale.no_location[direction]}</Text>
    :

    <Flex justify='space-around' align='baseline' gap={'small'}>
        <Button icon={<RedoOutlined  />} type='text' onClick={refreshAttendence}></Button>
        {ActivateLocation?<> <div className='d-flex flex-column'>
        <Popconfirm
    title={locale.checkin[direction]}
    description={locale.are_checkin[direction]}
    onConfirm={(e)=>dispatch(Check_in())}
    // onCancel={cancel}
    okText={locale['yes'][direction]}
    cancelText={locale['no'][direction]}
  >
            <Button type='primary' className={` ${!attendance.enable_checkin?'':'btn-success'}`}  disabled={!attendance.enable_checkin} >{locale.checkin[direction]}</Button>
            </Popconfirm>
            <Text style={{color:'gray',textAlign:'center'}}>{formatTime(attendance.checkin_time)??''}</Text>
        </div>
        <div className='d-flex flex-column '> 
             <Popconfirm
    title={locale.checkin[direction]}
    description={locale.are_checkout[direction]}
    onConfirm={(e)=>dispatch(Check_out())}
    okText={locale['yes'][direction]}
    cancelText={locale['no'][direction]}
  >
        <Button type='primary'  danger disabled={!attendance.enable_checkout} >{locale.checkout[direction]}</Button>
        </Popconfirm>
        <Text style={{color:'gray',textAlign:'center'}}>{formatTime(attendance.checkout_time)??''}</Text>
        </div></>:<Flex className='mx-2'>{locale.activate_location[direction]}</Flex>}
       
        </Flex>}</>
   
  )
}
