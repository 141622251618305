import React from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import dayjs from 'dayjs'

function EvaluationDashboard() {
  return (
    <MainLayout title="Performance Dashboard" loading={false}>
        {dayjs().format('YYYY')}
    </MainLayout>        
)
}
export default EvaluationDashboard