import { UploadOutlined } from '@ant-design/icons'
import { Button, Form,  Upload, UploadFile, UploadProps, message } from 'antd'
import React, { useContext, useEffect, useState, } from 'react'
import { CustomContext } from '../../../App';
import { BASE_URL } from '../../../Helper/urls';
import { useParams } from 'react-router-dom';
import { MyAxios } from '../../../globals/MyAxios';
import { Convert_to_base } from '../../../Helper/Doc_Functions';

export default function MyUpload(prop:any) {
    const {locale,direction}=useContext(CustomContext)
    const {doctype,name}=useParams()
    const [MyFiles,setMyFiles]=useState<UploadFile[]>([])

  useEffect(()=>{
    if(name!==undefined){
      const feachFiles=async()=>{
 await MyAxios.get('api/method/kabi.api.utils.get_attachments',{params:{doctype:Convert_to_base(doctype!),name:name}}).then((res:any)=>{
  setMyFiles(res.data.message)
    // props.defaultFileList=res.data.message
            // console.log(res.data.message)
          })
      }
      feachFiles()
       
    }
  },[])
  const RemoveFile=async(docname:string)=>{
    await MyAxios.put('api/resource/File/'+docname,{attached_to_doctype:'',attached_to_name:''})
    .then(()=>message.success("File has been Remove Successfully"))
    .catch(()=>message.error("Error Occured"))

  }
    const props: UploadProps = {
        action: BASE_URL+`api/method/upload_file`,
        headers:{
            Accept: 'application/json',
            Authorization: localStorage.getItem('token')??""
        },
        onChange({ file, fileList }) {
          if (file.status !== 'uploading' ) {
            console.log(file, fileList);
          //   // fileList=[]
          if(file.status=='removed'){
              RemoveFile(file.uid)
          }else{
            fileList.pop()
            fileList.push({
                name:file.response.message.file_name??file.name,
                uid:file.response.message.name??file.uid,
                url:BASE_URL.slice(-1,0)+file.response.message.file_url??'',
                status:file.status

            }) ;
          }
            
            // console.log(fileList)
          //   // fileList.push()
          }
            setMyFiles([...fileList])

        },
        fileList:MyFiles, 
      };
      
  return (
    <Form.Item
    name={prop.attachment_field}
    label={locale[prop.attachment_field][direction]}
    
    >

<Upload multiple {...props} disabled={prop.docstatus}>
    <Button disabled={prop.docstatus} icon={<UploadOutlined />}>{locale['upload'][direction]}</Button>
  </Upload> 

    </Form.Item>
 )
}
