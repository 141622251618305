import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../layouts/main/MainLayout'
import { Link } from 'react-router-dom'
import { FloatButton, List, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CustomContext } from '../../App'
import { MyAxios } from '../../globals/MyAxios'
import ExpenseHeader from './component/ExpenseHeader'
import ExpenseItem from './component/ExpenseItem'

export default function Expense_claim() {
  const [loading,setLoading]=useState(true)
  const [sortFilter,setSortFilter]=useState<keyof typeof data>('my')
  const [data,setData]=useState({'my':[],'all':[]})
  const{locale,direction}=useContext(CustomContext)

  useEffect(()=>{
    const feachData=async()=>{
    const res =await MyAxios.get('api/method/kabi.api.get_lists.get_list',{params:{doctype:'Expense Claim KABi',allow_all:'HR Manager'}})
    setData(res.data.message)
    setLoading(false)
    
    }
    feachData()
  },[])

    return (
    <MainLayout title='Expense Claim' loading={loading}>
      <Link to='/expense-claim/new'> 
           <FloatButton type='primary'  icon={<PlusOutlined  />}></FloatButton>
      </Link>
      {data['all'].length==0?<></>:
            <Radio.Group
            value={sortFilter}
            onChange={(e) => {
              setSortFilter(e.target.value);
            }}
            ><Radio.Button className='titles' value="my">{locale['your_requests'][direction]}</Radio.Button>
            <Radio.Button className='titles' value="all">{locale['all_requests'][direction]}</Radio.Button>
            
            
            
          </Radio.Group>}
          <br />

     <List
     header={<ExpenseHeader/>}
     dataSource={data[sortFilter] ||[]}
     pagination={{pageSize:5,responsive:true,defaultCurrent:1}}
     renderItem={(item:any) => (
      <>
      <ExpenseItem data={item} />
      <br />
      </>
    )}
         >

     </List>

    </MainLayout>
  )
}
