import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { MyAxios } from "../../globals/MyAxios"
interface Attendance{
    enable_checkin:boolean;
    checkin_time:string;
    enable_checkout:boolean;
    checkout_time:string;

}
export const AttendanceSlice=createSlice({
    initialState:{
        loading:false,
        data:{
            enable_checkin:false,
            checkin_time:'',
            enable_checkout:false,
            checkout_time:''
        }
    },
    name:'attendance',
    reducers:{

    },
    extraReducers(builder) {
        builder.addCase(fecthAttendance.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })   
        builder.addCase(fecthAttendance.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(fecthAttendance.rejected,(state,action)=>{
            state.loading=false
        })
        builder.addCase(Check_in.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(Check_in.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(Check_in.fulfilled,(state,action)=>{
            state.loading=false
            state.data.enable_checkin=false
            state.data.checkin_time=action.payload
            
        })
        builder.addCase(Check_out.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(Check_out.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(Check_out.fulfilled,(state,action)=>{
            state.loading=false
            state.data.enable_checkout=false
            state.data.checkout_time=action.payload

        })    
   
    
    
    }
        
})



export const fecthAttendance=createAsyncThunk('feach/attendance_checkin',
async (params: { lat: any; lon: any })=>{
    const { lat, lon } = params;

    const res=await MyAxios.get('api/method/kabi.api.attendence.get_attendence',{params:{lat:lat,lon:lon}})

    return res.data.message
}
)
export const Check_in=createAsyncThunk('check-in/attendance',
async()=>{

    const res=await MyAxios.post('api/method/kabi.api.attendence.checkin')
    return res.data.message}
)
export const Check_out=createAsyncThunk('check-out/attendance',
async()=>{
    
    const res=await MyAxios.get('api/method/kabi.api.attendence.checkout')

    return res.data.message
}
)

export default AttendanceSlice.reducer