import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { MyAxios } from "../../globals/MyAxios"

export const AttendanceReportSlice=createSlice({
    initialState:{
        loading:true,
        data:[],
        emps:[]
    },
    name:'attendanceReport',
    reducers:{
   

    },extraReducers(builder) {
       
        builder.addCase(fecthAttendanceReport.rejected,(state,action)=>{
            state.loading=false
        })   
        builder.addCase(fecthAttendanceReport.pending,(state,action)=>{
            state.loading=true
        })   
        builder.addCase(fecthAttendanceReport.fulfilled,(state,action)=>{
            state.loading=false
            state.emps=action.payload.emps
            state.data=action.payload.data

        })    
   
    
    
    }
        
})



export const fecthAttendanceReport=createAsyncThunk('feach/attendance_report',
async (params: { emp?: string; date?: any })=>{
    const { emp, date } = params;
    const res=await MyAxios.get('api/method/kabi.api.attendence.get_attendence_report',{params:{emp:emp??'all',date:date??''}})
    return res.data.message
}
)

export default AttendanceReportSlice.reducer