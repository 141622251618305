import { createAsyncThunk, createSlice, isAction } from "@reduxjs/toolkit";
import axios from "axios";
import { MyAxios } from "../../globals/MyAxios";

const LeaderboardSlice=createSlice({
    name:'leaderboard',
    initialState:{
        loading:true,
        data:{}
    },
    reducers:{
        getAllLeaderboard:()=>{

        },
        getYearleaderboard:()=>{

        },
        getMounthLeaderBoard:()=>{

        }
    },
    extraReducers(builder) {
        builder.addCase(feachLeaderboard.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        builder.addCase(feachLeaderboard.pending,(state,action)=>{
            state.loading=true
        })
        builder.addCase(feachLeaderboard.rejected,(state,action)=>{
            state.loading=false
            state.data={}
        })
    },
})
export const feachLeaderboard=createAsyncThunk('feach/leaderboard',
async()=>{
    const res=await MyAxios.get('api/method/kabi.api.leaderboard.get_leaderboard')
    return res.data.message
}
)

export const {getAllLeaderboard,getMounthLeaderBoard,getYearleaderboard}=LeaderboardSlice.actions
export default LeaderboardSlice.reducer