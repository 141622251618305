import { Button, DatePicker, Flex, Form, Input, InputNumber, Select,  Table , TableColumnsType, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { 
  useNavigate,
  // useNavigate, 
  useParams } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { RESOURCE_URL } from '../../../Helper/urls';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import MyUpload from '../Component/MyUpload';
import dayjs from 'dayjs';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

export default function ExpenseClaimForm(props:any) {
    const [form] = Form.useForm();
    let { doctype,name } = useParams();

    const {locale,direction}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const [paymentMonths ,setPaymentMounth]=useState<any[]>([])
    const nav=useNavigate()
    const addAttachment=async(file:any,docname:string)=>{
      await MyAxios.put('api/resource/File/'+file.uid,{attached_to_doctype:Convert_to_base(doctype!),attached_to_name:docname})
  }
  const updateRow=async(row:any)=>{
    await MyAxios.put(RESOURCE_URL+'Expense Item KABi/'+row.name,{service:row.service,amount:row.amount})
  }
  const addRowafter=async(row:any,docname:string)=>{
    await MyAxios.post(RESOURCE_URL+'Expense Item KABi',{parenttype:Convert_to_base(doctype!),parent:docname,parentfield:'expenses',service:row.service,amount:row.amount})
  }
 const deleteRow = async(row: any) => {
  if(row.name==''){
    const updatedData = tableData.filter(item => item.key !== row.key);
    setTableData(updatedData);
  }else{
    await MyAxios.delete(RESOURCE_URL+'Expense Item KABi/'+row.name).then((e)=>{
          const updatedData = tableData.filter(item => item.key !== row.key);
    setTableData(updatedData);
    })


  }

};
    
  function generatePaymentMonths() {
    const start = dayjs();
    const months = [];

    for (let i = 0; i < 13; i++) { // Loop through 13 months (April 2024 to April 2025 inclusive)
        const month = start.add(i, 'month').format('MMM, YYYY');
        months.push({ value: month, label: month});
    }
    if(name===undefined)form.setFieldValue('payment_month',months[0])

    return months;
}
    useEffect(()=>{
      setPaymentMounth(generatePaymentMonths())
      const fecthData=async()=>{
        if(name){
          await MyAxios.get(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name).then((res:any)=>{
            setData(res.data.data)
            form.setFieldsValue({payment_month:res.data.data.payment_month??'',total_amount:res.data.data.total_amount??0})

          })
          await MyAxios.get('api/method/kabi.api.table.get_all_Items',{params:{doctype:'Expense Item KABi',parent:name,parenttype:Convert_to_base(doctype!)}}).then((res:any)=>{
            let result=res.data.message
           for (let i = 0; i < result.length; i++) {
            result[i].key=result[i].name
            
           }
          
              setTableData(result)
          }).catch(err=>console.error(err))
          

      }else{
        form.setFieldValue('payment_month',paymentMonths[0])
      }
      
      }
      fecthData()
      form.setFieldValue('total_amount',get_totalamount())
      
      
    },[])
    const hundlesubmit=async(event:any)=>{
      if(tableData.length!==0){
      if(name===undefined){

      await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),event).then(e=>{
        for (let i = 0; i < tableData.length; i++) {
          if(tableData[i].name==''){
            addRowafter(tableData[i],e.data.data.name)
          }else{
            updateRow(tableData[i])
          }
          
        }
        if(event.attachment!==undefined){
          for (let i = 0; i < event.attachment.fileList.length; i++) {
          if(event.attachment.fileList[i].status=='done'){
            addAttachment(event.attachment.fileList[i],e.data.data.name)

          }
          
        }
        }
        console.log(e)
      nav('/'+doctype)
      }).catch(err=>console.log(err))
        
      }else{
        await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,event).then((res:any)=>{
          for (let i = 0; i < tableData.length; i++) {
            if(tableData[i].name==''){
              addRowafter(tableData[i],res.data.data.name)
            }else{
              updateRow(tableData[i])
            }
            
          }
          if(event.attachment!==undefined){
            for (let i = 0; i < event.attachment.fileList.length; i++) {
            if(event.attachment.fileList[i].status=='done'){
              addAttachment(event.attachment.fileList[i],res.data.data.name)
  
            }
            
          }
          }
        nav('/'+doctype)

        })

      }


      }else{
        message.error("No Expense Item Found")
      }

    }
    interface DataType {
      key: React.Key;
      name:string;
      service: string;
      amount: string;
    }
    
    interface Props {
      locale: any;
      direction: string;
    }
const [tableData, setTableData] = useState<DataType[]>([]);


    const addRow = () => {
      const newRow: DataType = {
        key: tableData.length + 1,
        name:'',
        service: '',
        amount: '',
      };
      setTableData([...tableData, newRow]);
    };
   
  
    const handleInputChange = (value: string, fieldName: string, key: React.Key) => {
      // const { value } = e.target;
      const updatedData = tableData.map(item => {
        if (item.key === key) {
          
          return { ...item, [fieldName]: value };
        }
        return item;
      });
      setTableData(updatedData);
    };

    const columns: TableColumnsType<DataType> = [


      {
        title: locale.service[direction],
        dataIndex: 'service',
        key: 'service',
        render: (_: any, record: DataType) => (
          <Input 
          disabled={data.docstatus>=1}
          // style={{width:'10rem'}}
            value={record.service}
            onChange={(e) => handleInputChange(e.target.value, 'service', record.key)}
          />
        ),       

      },
      {
        title: locale.amount[direction],
        dataIndex: 'amount',
        key: 'amount',
        render: (_: any, record: DataType) => (
          <Input 
            value={record.amount}
            suffix="SAR"
            onChange={(e) =>/^\d*\.?\d*$/.test(e.target.value)? handleInputChange(e.target.value, 'amount', record.key):''}
            required
            disabled={data.docstatus>=1}

          />
        ),       

      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (_:any,record:DataType) => <Button icon={<DeleteOutlined />} onClick={()=>deleteRow(record) } type='primary' danger></Button>,
      },
     
    ];

  const get_totalamount=()=>{
    let result=0
    for (let i = 0; i < tableData.length; i++) {
      result+=Number(tableData[i].amount) 

      
    }
    return result
  }
  useEffect(()=>{
    form.setFieldValue('total_amount',get_totalamount())
  },[tableData])

  return (
    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}
  >
    <Form.Item
    label={locale.payment_month[direction]}
    name={'payment_month'}
    rules={[
      {
        required:true,
        message:"Please enter payment mounth"
      }
    ]}
    >
<Select  style={{width:'20rem'}}   options={paymentMonths} ></Select>
    </Form.Item>
<Form.Item label={locale.expenses[direction]} name="expenses" required>
              <Table  pagination={false} columns={columns} dataSource={tableData} rowKey="key"  />

             <Button disabled={data.docstatus >= 1} className='mt-1' icon={<PlusOutlined />}  type='primary' onClick={addRow}>{locale.add_expenses[direction]}</Button>

</Form.Item>

<Flex gap={5}>
   <Form.Item 
   label={locale.total_amount[direction]}
   name='total_amount'
   
   >
 <InputNumber
 style={{width:'15rem'}}
 value={get_totalamount().toString()}
      formatter={(value) => `${value} SAR`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
      disabled
    />  
     </Form.Item>
     <MyUpload docstatus={data.docstatus===1 }  attachment_field='attachment'/>
   
</Flex>

    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
  )
}
