import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { CustomContext } from '../../../App'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { fecthAttendanceReport } from '../../../redux/Attendance/AttendanceReportSlice'
import AttendanceHeader from './Component/AttendanceHeader'
import AttedanceList from './Component/AttedanceList'
import AttendanceSearch from './Component/AttendanceSearch'
import { Divider } from 'antd'
import dayjs from 'dayjs'

export default function AttendanceReport() {
    const {locale,direction}=useContext(CustomContext)
    const loading=useSelector((state:any)=>state.attendanceReport.loading)
    const data=useSelector((state:any)=>state.attendanceReport.data)
    const emps=useSelector((state:any)=>state.attendanceReport.emps)

    
    const dispatch=useDispatch<AppDispatch>()
    useEffect(()=>{
      dispatch(fecthAttendanceReport({}))
    },[])

  return (
    <MainLayout title='Attendance' >
      <AttendanceSearch dispatch={dispatch} emps={emps}/>
      <Divider></Divider>
      <AttendanceHeader/>
      <AttedanceList loading={loading} data={data}/>
    
</MainLayout>
  )
}
