import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../layouts/main/MainLayout'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Form, Input,Card, message } from 'antd'
import { CustomContext } from '../../App'
import { MyAxios } from '../../globals/MyAxios'

export default function Reset_pasword() {
  const nav=useNavigate()
  const [form] = Form.useForm();
  const {locale,direction}=useContext(CustomContext)
  const [loading,setLoading]=useState(false)
  const validatePassword = (rule:any, value:any, callback:any) => {
    if (value && value.length >= 8) {
      // Check for at least one digit
      if (!/\d/.test(value)) {
        callback('Password must contain at least one digit');
      }
      // Check for at least one special character
      else if (!/[@#$%^&*]/.test(value)) {
        callback('Password must contain at least one special character: @#$%^&*');
      }
      // Check if the password starts with an alphabet
      else if (!/^[a-zA-Z]/.test(value)) {
        callback('Password must start with an alphabet');
      } else {
        callback();
      }
    } else {
      callback('Password must be at least 8 characters long');
    }
  };
  const onFinish =async (e: any) => {

    setLoading(true)

    const params:any={
        email:e.email,old_pwd:e.password,new_pwd:e.password1
    }
//     try{
//         // await axios.get('https://jsonplaceholder.typicode.com/todos/1')
//         // .then(e=>console.log(e))
await MyAxios.get('api/method/kabi.api.auth.reset_password',{params} )
    .then(res=>{
   if(res.data.message.success_key===1){
            message.success(res.data.message.message)

            localStorage.removeItem('token',)
            localStorage.removeItem('job_title')
            localStorage.removeItem('full_name')
            localStorage.removeItem('email')
            localStorage.removeItem('image')

            nav('/')
                            window.location.reload()

   }else{
    message.error(res.data.message.message)
   }
   setLoading(false)
    })
//     }catch(e){
//         window.location.reload()
//     }
  };

  form.setFieldValue('email',localStorage.getItem('email'))

  return (
    <MainLayout title='Reset Password'>
      <Flex align='center' justify='center' style={{height:'100%',width:'100%'}}>
        <Card style={{width:'100%'}} title={locale.reset_password[direction]}  bordered>
          <Form
      form={form}
    layout='vertical'
    name="basic"
    // labelCol={{ span: 8 }}
    // wrapperCol={{ span: 16 }}
    // style={{ maxWidth: 1000 }}
    onFinish={onFinish}
    
    autoComplete="off"
  >
    <Form.Item
      label={locale.email[direction]}
      name="email"
      
      rules={[{ required: true, message: 'Please input your username!' }]}
    >
      <Input disabled />
    </Form.Item>
    <Form.Item
      label={locale.old_password[direction]}
      name="password"
      rules={[{ required: true, message: 'Please input your password!' }]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item
      label={locale.new_password[direction]}
      name="password1"
      rules={[{ required: true, message: 'Please input your password!' },
      { validator: validatePassword }]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      label={locale.confirm_password[direction]}
      name="password2"
      rules={[{ required: true, message: 'Please input your password!' },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password1') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
      }),]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button type="primary" htmlType="submit" loading={loading}>
        {locale.submit[direction]}
      </Button>
    </Form.Item>
  </Form>
        </Card>
        
      </Flex>
         
    </MainLayout>
  )
}
