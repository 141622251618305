import { Button, DatePicker, Flex, Form, Input, Select } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { fecthAttendanceReport } from '../../../../redux/Attendance/AttendanceReportSlice';

export default function AttendanceSearch(props:any) {
    const [form] = Form.useForm();
    const{locale,direction}=useContext(CustomContext)
    const hundlesubmit=async(event:any)=>{
        props.dispatch(fecthAttendanceReport({emp:event.emp??'all',date:event.date==undefined?'':dayjs(event.date).format('YYYY-MM-DD')}))

      }
  return (
    
    <Form
    form={form}
    onFinish={hundlesubmit}
  ><Flex gap={'small'} wrap='wrap'>
    <Form.Item
      name="emp"
    >
<Select   
    style={{ width: '15rem' }}
 defaultValue={'all'} options={props.emps.map((ele:any)=>{return{value:ele.name,label:ele.employee_name}})} /> 
    </Form.Item>
   
    <Form.Item
      name="date"
    >
    <DatePicker />
    </Form.Item>
   
   
    <Form.Item>
        <Button icon={<SearchOutlined />} type="primary" htmlType="submit" size='small' >
          {locale.search[direction]}
        </Button>
    </Form.Item>
     </Flex>
  </Form>
   
  )
}
