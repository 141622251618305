import React, { useEffect } from 'react';
import NotFound from '../layouts/main/NotFound';
import NotFoundAuth from '../layouts/Auth/NotFoundAuth';

const AuthGuard = ({component}) => {
    const auth=localStorage.getItem('token')

    return auth?<React.Fragment>{component}</React.Fragment>:<React.Fragment><NotFoundAuth/></React.Fragment>
}

export default AuthGuard;