import React, { useContext, useEffect } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import OrgChart from './component/OrgChart';
import TeamList from './component/TeamList';
import { Tabs, TabsProps } from 'antd';
import { CustomContext } from '../../../App';
import locale from 'antd/es/date-picker/locale/en_US';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { feachTeamData } from '../../../redux/Team/TeamSlice';


export default function Team() {
  const team=useSelector((state:any)=>state.team)
  const dispacth=useDispatch<AppDispatch>()
  useEffect(()=>{
    dispacth(feachTeamData());
  },[])
  const {locale,direction}=useContext(CustomContext)
const items: TabsProps['items'] = [
  { key: '1', label: locale['team'][direction], children: <TeamList data={team.data.team}/>},
  { key: '2', label: locale['organizational_structure'][direction], children: <OrgChart data={team.data.org_chart}/> },

];
  return (
    <MainLayout title='Team' loading={team.loading}>
     <Tabs
        defaultActiveKey="1"
        type="card"
        items={items}
        indicator={{ size: (origin) => origin , align: 'start' }}
      />
    </MainLayout>
  )
}
