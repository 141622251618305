import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Link } from 'react-router-dom'
import { FloatButton, List, Radio, Tabs, TabsProps, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { MyAxios } from '../../../globals/MyAxios'
import RemoteHeader from './component/RemoteHeader'
import RemoteItem from './component/RemoteItem'
import { CustomContext } from '../../../App'
import RemoteList from './component/RemoteList'
import RemoteCalendar from './component/RemoteCalendar'
import RemotePolicy from './component/RemotePolicy'

export default function RemoteWork() {
  const [loading,setLoading]=useState(true)
  const{locale,direction}=useContext(CustomContext)

  const [data,setData]=useState({'my':[],'all':[]})
  const [isValidAdd,SetIsValidAdd]=useState([])
  
  // const [api, contextHolder] = notification.useNotification();

  useEffect(()=>{
    const feachData=async()=>{
    const res =await MyAxios.get('api/method/kabi.api.get_lists.get_list',{params:{doctype:'Remote Work',allow_all:'HR Manager'}})
       setData(res.data.message)

   
    setLoading(false)
    }
   
    feachData()
 
  },[])
  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label:locale.list[direction],
      children:<RemoteList data={data}/>,
    },
    {
      key: '2',
      label: locale.calender[direction],
      children: <RemoteCalendar data={data} />,
    },
    {
      key: '3',
      label: locale.policies[direction],
      children: <RemotePolicy/>,
    },
  ];
    return (
    <MainLayout title='Remote Work' loading={loading}>
   <Link to='/remote-work/new'> 
           <FloatButton  type='primary'  icon={<PlusOutlined  />}></FloatButton>
      </Link>
      
      <Tabs defaultActiveKey="1" items={items}  />

    </MainLayout>
  )
}
