import { Divider, Flex } from 'antd'
import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'

export default function GeneralHeader() {
    const{locale,direction}=useContext(CustomContext)
  return (
    <Flex justify='space-around'  >
        <div className='titles'>{locale['name'][direction]}</div>
        {/* <Divider type='vertical'/> */}
        <div className='titles'>{locale['status'][direction]}</div>
        {/* <Divider type='vertical'/> */}

        <div className='titles'>{locale['owner'][direction]}</div>
        
        
    </Flex>
  )
}
