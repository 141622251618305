import { Button, Divider, Flex, Form, Input, InputNumber, Progress, Slider, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { RESOURCE_URL } from '../../../Helper/urls';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import MyUpload from '../Component/MyUpload';
import TextArea from 'antd/es/input/TextArea';
import PurchaseOrder from '../../Finance/Purchase-Order/PurchaseOrder';
import PurchaseTable from '../Component/PurchaseTable';
import CustomLoading from '../../../globals/CustomLoading';
import { ArrowRightOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;
export default function PaymentForm(props: any) {
  const [form] = Form.useForm();
  let { doctype, name } = useParams();

  const { locale, direction } = useContext(CustomContext)
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const nav = useNavigate()
  const addAttachment = async (file: any, docname: string) => {
    await MyAxios.put('api/resource/File/' + file.uid, { attached_to_doctype: Convert_to_base(doctype!), attached_to_name: docname })
  }
  useEffect(() => {

    const fecthData = async () => {
      setLoading(true)
      if (name) {
        await MyAxios.get(RESOURCE_URL + Convert_to_base(doctype!) + '/' + name).then((res: any) => {
          setData(res.data.data)
          // form.setFieldValue('amount',res.data.data.amount)
          form.setFieldsValue({ supplier_name: res.data.data.supplier_name, supplier_phone_number: res.data.data.supplier_phone_number, supplier_email: res.data.data.email, description: res.data.data.payment_description, paid_around: Number(res.data.data.paid_around), grand_total: Number(res.data.data.grand_total), total_quantity: Number(res.data.data.total_quantity), department: res.data.data.department, purchase_order: res.data.data.purchase_order, amount: res.data.data.amount, document_owner: res.data.data.owner_document })

          setLoading(false)


        })

      }
    }
    fecthData()

  }, [])

  const hundlesubmit = async (event: any) => {
    if (name === undefined) {
      await MyAxios.post(RESOURCE_URL + Convert_to_base(doctype!), { amount: event.amount, payment_description: event.description })
        .then((e: any) => {
          // console.log(event.attachment);
          if (event.attachment !== undefined) {
            for (let i = 0; i < event.attachment.fileList.length; i++) {
              if (event.attachment.fileList[i].status == 'done') {
                addAttachment(event.attachment.fileList[i], e.data.data.name)

              }

            }
          }


          nav('/' + doctype)
        }
        )
        .catch(err => console.log(err))
    } else {
      await MyAxios.put(RESOURCE_URL + Convert_to_base(doctype!) + '/' + name, { amount: event.amount, payment_description: event.description })
        .then((e: any) => {
          // console.log(event.attachment);
          if (event.attachment !== undefined) {
            for (let i = 0; i < event.attachment.fileList.length; i++) {
              if (event.attachment.fileList[i].status == 'done') {
                addAttachment(event.attachment.fileList[i], e.data.data.name)

              }

            }
          }


          nav('/' + doctype)
        }
        )
        .catch(err => console.log(err))
    }
  }
  const handleAmount = (value: any) => {

    const rem = (data.grand_total - data.paid_around) * ((value / 100));
    form.setFieldValue('amount', rem.toString())


  }
  // if(name)
  // form.setFieldsValue({request_content:data.request_content})
  if (name == undefined) {
    return (<Flex align='center' justify='center'>
      <Title level={5} style={{ color: 'gray' }}>{locale.not_permitted[direction]}</Title>
    </Flex>
    )
  } else if (loading) {
    return <CustomLoading />
  } else
    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={hundlesubmit}
      >
        <Title level={4}>{locale.purchase_order[direction]}</Title>
        <Flex gap={'small'} align='center'>
          <Form.Item
            name="purchase_order"
            label={locale['purchase_order'][direction]}
          >
            <Input
              style={{ width: '20rem' }} disabled={true}

            />

          </Form.Item>
          <a href={'/purchase-order/' + data.purchase_order + '/edit'} style={{textDecoration:'none',color:'gray'}} target="_blank">            <ArrowRightOutlined /></a>
        </Flex>


        <Flex gap={'small'} justify='space-between' wrap='wrap'>
          <Flex gap={'small'} wrap='wrap'> <Form.Item
            name="supplier_name"
            label={locale['supplier_name'][direction]}
          >
            <Input
              disabled={true}

            />
          </Form.Item>
            <Form.Item
              name="supplier_phone_number"
              label={locale['supplier_phone_number'][direction]}
            className='d-none'              

            // rules={[{ required: true },]}
            >
              <Input
                disabled={true}

              />      </Form.Item>
            <Form.Item
              name="supplier_email"
              label={locale['supplier_email'][direction]}
            // rules={[{ required: true },]}
            >
              <Input
                disabled={true}

              />

            </Form.Item>
          </Flex>

          <PurchaseTable po={data.purchase_order} />

        </Flex>
        <Flex gap={'small'} wrap='wrap'>
          <Form.Item
            name="document_owner"
            label={locale['document_owner'][direction]}
          // rules={[{ required: true },]}
          >
            <Input
              disabled={true}

            />

          </Form.Item>

          <Form.Item
            name="department"
            label={locale['department'][direction]}
          // rules={[{ required: true },]}
          >
            <Input
              disabled={true}

            />

          </Form.Item>

        </Flex>
        <Flex gap={'small'} wrap='wrap'>
          <Form.Item
            name="grand_total"
            label={locale['grand_total'][direction]}
          // rules={[{ required: true },]}
          >
            <Input
              disabled={true}

            />

          </Form.Item>
          <Form.Item
            name="total_quantity"
            label={locale['total_quantity'][direction]}
          // rules={[{ required: true },]}
          >
            <Input
              disabled={true}

            />

          </Form.Item>
          <div>
            <Title level={5}>{locale.paid_around[direction]}</Title>
            <Progress type="circle" percent={data.grand_total == 0 ? 0 : (data.paid_around / data.grand_total) * 100} size={40} />
          </div>
        </Flex>
        <Divider /><Slider defaultValue={0} disabled={data.docstatus >= 1} onChange={(e) => { handleAmount(e) }} />
        <Flex gap={'small'} wrap='wrap'>

          <Form.Item
            name="amount"
            label={locale['amount'][direction]}
            required

            rules={[
              {
                validator: async (_, value) => {
                  const grandTotal = (form.getFieldValue('grand_total') - form.getFieldValue('paid_around'));
                  const currentValue = value;

                  if (grandTotal < currentValue) {
                    return Promise.reject(new Error('Amount cannot exceed the grand total'));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}          >
            <InputNumber
              disabled={data.docstatus >= 1}
              style={{ width: '15rem' }}
            //  value={amount.toString()}
            // formatter={(value) => `${value} SAR`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            // parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}

            />

          </Form.Item>
        </Flex>



        <Flex gap={'small'}>

          <Form.Item
            name="payment_description"
            label={locale['description'][direction]}
          // rules={[{ required: true },]}
          >
            <TextArea disabled={data.docstatus >= 1} rows={4} />

          </Form.Item>

          <MyUpload docstatus={data.docstatus >= 1} attachment_field='attachment' />


        </Flex>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={data.docstatus >= 1} >
            {locale.save[direction]}
          </Button>
        </Form.Item>
      </Form>
    )
}
