import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { BookOutlined, FileTextFilled, HomeFilled, LikeFilled, MobileFilled, ScheduleFilled, ThunderboltFilled, TrophyFilled, WalletFilled } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { hr_request, team, general_request, time_request, Leave_application, remote_work, business_trip, expense_claim, performance_evaluation, hyrdd, Inviews, purchase_order, payment_request, CRM, lead, client, opportunity, HR_ICON, HR_Attendence, HR_Perform, dash } from './iconsSVG';
import '../style.css'
import { Link } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { ApprovalColor, EXPENSE_CLAIM_Color, FINANCE_Color, HR_Color, HR_RequestColor, TIME_Request_Color } from '../../../globals/GlobalColors';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { get_permission } from '../../../redux/Permission/permissionSlice';
const rootSubmenuKeys = ['General', 'hr_request', 'time_request', 'hr', 'finance', 'crm'];

type MenuItem = Required<MenuProps>['items'][number];

function Sidebar() {
  const permission = useSelector((state: any) => state.permission.data)
  const dispacth = useDispatch<AppDispatch>()
  const { direction, mode, locale } = useContext(CustomContext);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    href?: React.ReactNode,
    children?: MenuItem[],

    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      href,
      children,
      label,
      type,
    } as MenuItem;
  }
  useEffect(() => {
    dispacth(get_permission())
  }, [])
  const items_sidebar: any[] = [
    getItem(locale['general'][direction], 'General', null, null, [
      getItem((<Link to='/'>{locale['home'][direction]}</Link>), 'general_1', <HomeFilled />, '/home'),
      getItem((<Link to='/team'>{locale['team'][direction]}</Link>), 'general_2', <Icon component={team}></Icon>, ''),
      getItem((<Link to='/my-requests'>{locale['my_requests'][direction]}</Link>), 'general_3', <BookOutlined />, ''),

      // getItem((<Link to='/leaderboard'>{locale['leaderboard'][direction]}</Link>), 'general_4',<TrophyFilled />,''),
      // getItem((<Link to='/performance'>{locale['performance'][direction]}</Link>), 'general_4',<ThunderboltFilled />,''),
    ]),
    getItem((
      <Link to="/approval" >
        {locale['approvals'][direction]}
      </Link>
    ), 'approval', <LikeFilled style={{ color: ApprovalColor }} />, '/approval'),

    getItem(locale['hr_request'][direction], 'hr_request', <Icon style={{ color: HR_RequestColor }} component={hr_request} ></Icon>, null, [
      getItem((<Link to='/general-request'>{locale['general_request'][direction]}</Link>), 'hr_request_1', <Icon style={{ color: HR_RequestColor }} component={general_request}></Icon>, '/'),
      getItem((<Link to='/document'>{locale['document'][direction]}</Link>), 'hr_request_2', <FileTextFilled style={{ color: HR_RequestColor }} />, '/'),
    ]),
    getItem(locale['time_request'][direction], 'time_request', <Icon style={{ color: TIME_Request_Color }} component={time_request}></Icon>, null, [
      getItem((<Link to='/leave-application'>{locale['leave_application'][direction]}</Link>), 'time_request_1', <Icon style={{ color: TIME_Request_Color }} component={Leave_application}></Icon>, '/'),
      getItem((<Link to='/remote-work'>{locale['remote_work'][direction]}</Link>), 'time_request_2', <Icon style={{ color: TIME_Request_Color }} component={remote_work}></Icon>, '/remote-work'),
      getItem((<Link to='/business-trip'>{locale['business_trip'][direction]}</Link>), 'time_request_3', <Icon style={{ color: TIME_Request_Color }} component={business_trip}></Icon>, '/'),
    ]),
    getItem((<Link to='/expense-claim'>{locale['expense_claim'][direction]}</Link>), 'expense_claim', <Icon style={{ color: EXPENSE_CLAIM_Color }} component={expense_claim}></Icon>, '/'),
    getItem(locale['HR'][direction], 'HR', <Icon style={{ color: HR_Color }} component={HR_ICON}></Icon>, null, [
      getItem((<Link to='/attendance'>{locale['attendence'][direction]}</Link>), 'attendance', <Icon style={{ color: HR_Color }} component={HR_Attendence}></Icon>, null),
      getItem(locale['performance'][direction], 'performance', <Icon style={{ color: HR_Color }} component={HR_Perform}></Icon>, null, [
        getItem((<Link to='/evaluation-form/dashboard'>{locale['dashboard'][direction]}</Link>), 'dashboard-performance', <Icon style={{ color: HR_Color }} component={dash}></Icon>, null),
        getItem((<Link to='/evaluation-form'>{locale['evaluation'][direction]}</Link>), 'evalution-form', <Icon style={{ color: HR_Color }} component={performance_evaluation}></Icon>, null)
      ])
    ]),
    getItem(locale['finance'][direction], 'finance', <WalletFilled style={{ color: FINANCE_Color }} />, null, [
      getItem((<Link to='/purchase-order'>{locale['purchase_order'][direction]}</Link>), 'finance_1', <Icon style={{ color: FINANCE_Color }} component={purchase_order}></Icon>, '/'),
      getItem((<Link to='/payment-request'>{locale['payment_request'][direction]}</Link>), 'finance_2', <Icon style={{ color: FINANCE_Color }} component={payment_request}></Icon>, '/'),
    ]),
    //        getItem(' HYRDD', 'hyrdd', <Icon component={hyrdd}></Icon>,null, [
    // ]),
    // getItem('Inviews', 'Inviews', <Icon component={Inviews}></Icon>,null, [
    // ]),
    // getItem((<Link to='/project'>{locale['project'][direction]}</Link>), 'project',<ScheduleFilled />,'/project'),
    // getItem('CRM', 'crm',<Icon component={CRM}></Icon>,null,[
    //   getItem('Lead','crm_1',<Icon component={lead}></Icon>,'/lead'),
    //   getItem('Client','crm_2',<Icon component={client}></Icon>,'/client'),
    //   getItem('Oportunity','crm_3',<Icon component={opportunity}></Icon>,'/oportunity')
    // ]),
    getItem((<Link className='d-none d-lg-flex' to='/mobile'>{locale['mobile_version'][direction]}</Link>), 'mobile', <MobileFilled className='d-none d-lg-flex' style={{ color: 'gray' }} />, '/mobile'),

  ];;
  const [openKeys, setOpenKeys] = useState(['General']);
  const [current, setCurrent] = useState('general_1');

  const onClick: MenuProps['onClick'] = useMemo(() => (e: any) => {
    localStorage.setItem('side', e.key)
    setCurrent(e.key);
  }, [current])
  const onOpenChange: MenuProps['onOpenChange'] = useMemo(() => (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }, [openKeys])
  return (
    <Menu onClick={onClick} selectedKeys={[localStorage.getItem('side') ?? 'general_1']} className='sd' theme="light" mode="inline" defaultSelectedKeys={['general_1']} openKeys={openKeys} onOpenChange={onOpenChange} items={items_sidebar} style={{ overflow: 'scroll', backgroundColor: mode === 'dark' ? 'transparent' : 'white' }} />
  )
}
export default memo(Sidebar);