import React, { useEffect, createContext, Suspense, useState, useMemo } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css';


import { BrowserRouter, Routes, useNavigate } from 'react-router-dom';
import AuthRoutes from './routes/AuthRoutes';
import UnAuthRoutes from './routes/UnAuthRoutes';
import localeData from './locale.json'


export const CustomContext = createContext({ direction: localStorage.getItem('direction')!, mode: localStorage.getItem('mode')!, locale: JSON.parse(JSON.stringify(localeData)),
})

export default function App() {
  
  useEffect(() => {
    localStorage.getItem('mode') ?? localStorage.setItem('mode', 'ligth')
    localStorage.getItem('direction') ?? localStorage.setItem('direction', 'ltr')
   
  }, []
  )


  return (
    <>
      <CustomContext.Provider value={{ direction: localStorage.getItem('direction') ?? 'ltr', mode: localStorage.getItem('mode') ?? 'ligth', locale: JSON.parse(JSON.stringify(localeData))}}>
        <BrowserRouter>
          <Routes>
            {AuthRoutes}
            {UnAuthRoutes}
          </Routes>

        </BrowserRouter>
      </CustomContext.Provider>

    </>


  );
}