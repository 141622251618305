import { Card, Col, Flex, Row, Space,Typography } from 'antd'
import React from 'react'
import MyCustomAvatar from '../../../../globals/MyCustomAvatar'
import Title from 'antd/es/typography/Title'
import { BASE_URL } from '../../../../Helper/urls';
const { Paragraph, Text } = Typography;

export default function Posts(props:any) {

  return (
    <Space size='middle' direction='vertical' style={{width:'100%',overflow:'scroll',height:'85vh'}}>
    {props.data && props.data.length > 0 ? (
        props.data.map((e: any, index: number) => (
            <Card key={index} style={{ gap: 3, direction: 'ltr' }}>
                <Flex className='mb-3' gap={'small'}>
                    <MyCustomAvatar image='' name='Internal Communication' size='large' />
                    <Title level={5}>Internal Communication</Title>
                </Flex>
                <Text style={{ fontSize: 'larger' }} className='my-2' strong>{e.title}</Text>
                <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                    {e.content}
                </Paragraph>
                <img style={{ objectFit: 'scale-down', height: '25rem' }} className='rounded p-4 w-100' src={BASE_URL + e.image} alt="logo" />
            </Card>
        ))
    ) : (
        <div style={{ color: 'gray', textAlign: 'center' }}>No data available</div>
    )}
       
    </Space>
  )
}
