import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { MyAxios } from "../../globals/MyAxios"
interface Notification {
    name: string;
    read?: number; 
    subject:string;
    email_content:string;
    document_type:string;
    document_name:string;

  }
  
  interface NotificationState {
    loading: boolean;
    data: Notification[];
  }
  
  const initialState: NotificationState = {
    loading: true,
    data: []
  };
const NotificationSlice=createSlice({
    name:'notification',
    initialState,
    reducers:{
        
        setRead:(state,action)=>{
            const { payload: name } = action;
            for(const s of state.data){
                if(s.name===name){
                    s.read=1
                    

                }
            }
           

        }
    },extraReducers(builder) {
        builder.addCase(feacthNotification.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        builder.addCase(feacthNotification.pending,(state,action)=>{
            state.loading=true
        })
        builder.addCase(feacthNotification.rejected,(state,action)=>{
            state.loading=false
            
        })
        builder.addCase(set_Read.fulfilled,(state,action)=>{
            state.loading=false
            // state.data=action.payload
        })
        builder.addCase(set_Read.pending,(state,action)=>{
            state.loading=true
        })
        builder.addCase(set_Read.rejected,(state,action)=>{
            state.loading=false
            
        })
    },
})
export const feacthNotification=createAsyncThunk('feach/notification', 
async () => {
    const res= await MyAxios.get('api/method/kabi.api.notification.get_notifications')
    return res.data.message
   },)

 export   const set_Read=createAsyncThunk('read/notification', 
   async (name:string) => {
        await MyAxios.put(`api/resource/Notification%20Log/${name}`,{read:1})
       
      },)
export const {setRead}=NotificationSlice.actions
export default NotificationSlice.reducer