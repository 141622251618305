import React, { useContext, useEffect } from 'react'
import './style.css'
import { Divider,Flex, Layout, Spin, theme } from 'antd';
import Sidebar from './Component/Sidebar';
import MyHeader from './Component/MyHeader';
import { CustomContext } from '../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../Helper/urls';
import { io } from 'socket.io-client';
import * as serviceWorker from '../../swDev'


const { Header, Content, Sider } = Layout;

    
export default function MainLayout(props:any){
  const nav=useNavigate()
  const location = useLocation();
  const {locale,direction}=useContext(CustomContext)
  const { pathname } = location;
  useEffect(()=>{

     if (('Notification' in window)) {
          // Request permission from the user
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') { 
                 serviceWorker.register()

            }}
          )
    }
      if(pathname==='/login')
        nav('/')
    document.title=locale[props.title.toLowerCase().replaceAll(' ','_') ][direction]??""

    // }
  //    if ('Notification' in window) {
  //     // Request permission from the user
  //     Notification.requestPermission().then(permission => {
  //       if (permission === 'granted') {
  //             const socket = io(BASE_URL.slice(0,-1),{extraHeaders:{token:localStorage.getItem('email')!}});  
    
  //   socket.on('connect', function () {
  //     console.log('Socket.io connection established');
  //   });
  //  socket.on('error', function (error) {
  //     console.error('Socket.io error:', error);
  //   });
  //   // Event listener for incoming messages
  //   socket.on('message', function (data) {
  //     console.log('Message from server:', data);
  //   }); 
  //    socket.on('disconnect', function () {
  //     console.log('Socket.io connection closed');
  //   });
  //   socket.on("connect_error", (err) => {
  //     // the reason of the error, for example "xhr poll error"
  //     console.log(err.message);
    
  //     // some additional description, for example the status code of the initial HTTP response
  //     // console.log(err.description);
    
  //     // // some additional context, for example the XMLHttpRequest object
  //     // console.log(err.context);
  //   });
  //         console.log('Notification permission granted');
  //         window.addEventListener('load', () => {
  //           navigator.serviceWorker.register(`./sw.js`)
  //             .then(registration => {
  //               console.log('Service Worker registered with scope:', registration.scope);
  //             })
  //             .catch(error => {
  //               console.error('Service Worker registration failed:', error);
  //             });
  //         });
  //       } else if (permission === 'denied') {
  //         // Permission denied
  //         console.warn('Notification permission denied');
  //       } else {
  //         // Permission dismissed (likely in this case)
  //         console.warn('Notification permission dismissed');
  //       }
  //     });

  //   } else {
  //     // Notification API not supported
  //     console.warn('Notification API not supported');
  //   }
  },[])
  const {mode} = useContext(CustomContext);

  
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();
  
return (
  <Layout style={{height:'100vh',overflow:'hidden',backgroundColor:mode==='dark'?'transparent !importnent':'white'}} >
  <Sider
    // theme={localStorage.getItem('mode')=='dark'?'dark':'light'}
    breakpoint="lg"
    collapsedWidth="0"
    style={{backgroundColor:mode==='dark'?'transparent ':'white'}}
    
    onBreakpoint={(broken) => {
    }}
    onCollapse={(collapsed, type) => {
    }}
  >
    <Flex style={{alignContent:"center",justifyContent:"center",marginTop:10}}><img src={mode==='dark'?window.location.origin +'/image/logo2.png':window.location.origin +"/image/KABi.png" } height={mode==='dark'?30:50} width={mode=='dark'?140:140}   /></Flex>
    
    <Divider/>
    <Sidebar/>
  </Sider>
  <Layout>
  <Header style={{ padding: 0, background:colorBgContainer , display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' ,overflow:'hidden' }} >
    <MyHeader title={props.title}/>
</Header>    
    <Content style={{ margin: '24px 16px 0' }} >
      <div
        style={{
          padding: 24,
          height: '85vh',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
          overflow:'scroll',
        }}
        
      >

        {props.loading?<Spin style={{display:'flex',justifyContent:'center'}}  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:props.children}
        
      </div>
    </Content>

  </Layout>
</Layout>
)
}

