import React, { useContext, useEffect } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Button, Col, Flex, Row, Space, Spin, Tabs, TabsProps } from 'antd'
import Posts from './component/Posts';
import MyHomeCard from './component/MyHomeCard';
import { remote_work } from '../../../layouts/main/Component/iconsSVG';
import Icon, { LoadingOutlined, OrderedListOutlined, ReloadOutlined, ThunderboltFilled, UserSwitchOutlined } from '@ant-design/icons';
import MyEvents from './component/MyEvents';
import MyAvatarGroup from './component/MyAvatarGroup';
import { CustomContext } from '../../../App';
import AttendanceCard from './component/AttendanceCard';
import {  useDispatch, useSelector } from 'react-redux';
import { fetchHomeData } from '../../../redux/Home/HomeSlice';
import { AppDispatch } from '../../../redux/store';
import AttendanceCheckin from './component/AttendanceCheckin';
import BusinessCard from './component/BusinessCard';

export default function Home() {  

  const home=useSelector((state:any)=>state.home.data)
  const loading=useSelector((state:any)=>state.home.loading)
  const dispatch=useDispatch<AppDispatch>()
 useEffect(()=>{
    dispatch(fetchHomeData());
 },[])

  const {direction,locale}=useContext(CustomContext)
  const items: TabsProps['items'] = [
    { key: '1', label: locale['dashboard'][direction], children: <Flex gap={10} wrap='wrap' justify='space-around' className='w-100'>  
      <MyHomeCard
        icon={<></>}
        title={<Flex justify='space-between' align='baseline' gap={'small'} style={{display:'flex'}}>{locale['attendance'][direction]}</Flex>}
        body={<AttendanceCheckin/>}
        footer=''
        />  
                <BusinessCard></BusinessCard>

    <MyHomeCard 
    icon={<></>}
    title={locale['who_out'][direction]}
    body={<MyAvatarGroup data={home.who_out}/> }
    footer=''

    />
    <MyHomeCard
    icon={<Icon component={remote_work}></Icon>}
    title={locale['remote_work'][direction]}
    body={<MyAvatarGroup data={home.remote_work} />}
    footer=''
    />
    <MyHomeCard
    icon={<ThunderboltFilled style={{color:'#FAB700'}} /> }
    title={locale['energy_points'][direction]}
    body={<div className='text-center'>{home.energy_points} {locale['points'][direction]}</div>}
    footer=''
    />
    <MyHomeCard 
    icon={<OrderedListOutlined style={{color:'#9A25D0'}} />}
    title={locale['rank'][direction]}
    body={<div style={{textAlign:'center'}}>{home.rank}</div>}
    footer=''
    />


        <MyHomeCard
        icon={<></>}
        title={locale['shift'][direction]}
        body={<AttendanceCard/>}
        footer=''
        />
        
        <MyHomeCard 
        icon={<UserSwitchOutlined color='gray' />}
        title={locale['vacation'][direction]}
        body={<div className='h5'>{home.leave_balance}</div>}
        footer={locale['day_available'][direction]}
        />
     
  </Flex> },
    { key: '3', label: locale['events'][direction], children: <MyEvents data={home.events}/> },
    { key: '2', label: locale['posts'][direction], children: <Posts data={home.posts}  /> },
  ];
  const hasUsers=true;
  const colStyle={
    hiegth:"100%",
    overflow:'scroll',
  }

  return (
    <MainLayout title='Home' loading={loading}>
     <Tabs
        defaultActiveKey="1"
        items={items}
        type="card"
        className=' d-flex d-lg-none'
        indicator={{ size: (origin) => origin , align: 'start' }}
      />
      <Row className='d-none d-lg-flex ' style={{height:'100%'}}   gutter={10}>

      <Col id='col1' style={colStyle} span={6} >
        <Space size='middle' direction='vertical' style={{width:'100%'}}>
        <MyHomeCard
        icon={<></>}
        title={<Flex justify='space-between' align='baseline' gap={'small'} style={{display:'flex'}}>{locale['attendance'][direction]}</Flex>}
        body={<AttendanceCheckin/>}
        footer=''
        />
        <MyHomeCard 
        icon={<></>}
        title={locale['who_out'][direction]}
        body={<MyAvatarGroup data={home.who_out}/> }
        footer=''

        />
        <MyHomeCard
        icon={<Icon component={remote_work}></Icon>}
        title={locale['remote_work'][direction]}
        body={<MyAvatarGroup data={home.remote_work}/>}
        footer=''
        />
        <MyHomeCard
        icon={<ThunderboltFilled style={{color:'#FAB700'}} /> }
        title={locale['energy_points'][direction]}
        body={<div className='text-center'>{home.energy_points} {locale['points'][direction]}</div>}
        footer=''
        />
        <MyHomeCard 
        icon={<OrderedListOutlined style={{color:'#9A25D0'}} />}
        title={locale['rank'][direction]}
        body={<div style={{textAlign:'center'}}>{home.rank}</div>}
        footer=''
        />
        </Space>


      </Col>

      <Col id='post' style={colStyle} span={12} ><Posts data={home.posts}/></Col>

      <Col id='col3' style={colStyle} span={6} >
      <Space size='middle' direction='vertical' style={{width:'100%'}}>
                <BusinessCard></BusinessCard>

        <MyHomeCard
        icon={<></>}
        title={locale['shift'][direction]}
        body={<AttendanceCard/>}
        footer=''
        />

        <MyHomeCard 
        icon={<UserSwitchOutlined color='gray' />}
        title={locale['vacation'][direction]}
        body={<div className='h5'>{home.leave_balance}</div>}
        footer={locale['day_available'][direction]}
        />
        <MyEvents data={home.events}/>
     
</Space>
       </Col>  
      </Row>

    </MainLayout>
  )
}
