import React, { useContext, useState } from 'react'
import { CustomContext } from '../../../../App';
import { List, Radio } from 'antd';
import LeaveHeader from './LeaveHeader';
import LeaveItem from './LeaveItem';

export default function LeaveList(props:any) {
    const{locale,direction}=useContext(CustomContext)
    const [sortFilter,setSortFilter]=useState<keyof typeof props.data>('my')

  return (
    <>  {props.data['all'].length==0?<></>:
    <Radio.Group
    value={sortFilter}
    onChange={(e) => {
      setSortFilter(e.target.value);
    }}
    ><Radio.Button className='titles' value="my">{locale['your_requests'][direction]}</Radio.Button>
  <Radio.Button className='titles' value="all">{locale['all_requests'][direction]}</Radio.Button>
    
    
    
  </Radio.Group>}
  <br />

<List
header={<LeaveHeader/>}
dataSource={props.data[sortFilter] ||[]}
pagination={{pageSize:5,responsive:true,defaultCurrent:1}}
renderItem={(item:any) => (
<>
<LeaveItem data={item} />
<br />
</>
)}
 >

</List>

    </>
  
  )
}
