import { configureStore } from '@reduxjs/toolkit'
import UserSlice from './User/UserSlice'
import TeamSlice from './Team/TeamSlice'
import LeaderboardSlice from './leaderboard/LeaderboardSlice'
import PostSlice from './Post/PostSlice'
import NotificationSlice from './Notification/NotificationSlice'
import HomeSlice from './Home/HomeSlice'
import ApprovalSlice from './Approvals/ApprovalSlice'
import permissionSlice from './Permission/permissionSlice'
import CommentSlice from './Comments/CommentSlice'
import AttendanceSlice from './Attendance/AttendanceSlice'
import AttendanceReportSlice from './Attendance/AttendanceReportSlice'

export  const store= configureStore({
  reducer: {
    user:UserSlice,
    team:TeamSlice,
    leaderboard:LeaderboardSlice,
    post:PostSlice,
    notification:NotificationSlice,
    home:HomeSlice,
    approval:ApprovalSlice,
    permission:permissionSlice,
    comments:CommentSlice,
    attendance:AttendanceSlice,
    attendanceReport:AttendanceReportSlice
  },
  // middleware(getDefaultMiddleware) {
    
  // },
})

export type RootState=ReturnType<typeof store.getState>
export type AppDispatch=typeof store.dispatch
