import { BellOutlined } from '@ant-design/icons'
import { Badge, Button, Dropdown, Menu, MenuProps } from 'antd'
import React, { memo, useEffect } from 'react'
import NotificationItem from './NotificationItem'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { feacthNotification } from '../../../redux/Notification/NotificationSlice'

 function Notification(props:any) {
  const notification:any[]=useSelector((state:any)=>state.notification.data)
  const dispatch =useDispatch<AppDispatch>()
  useEffect(()=>{
    dispatch(feacthNotification())
  },[])

const countUnread=()=>{
  let count=0;
  for (let index = 0; index < notification.length; index++) {
    if(!notification[index].read)
    count++;
    
  }
  return count
}



let items:MenuProps['items']=notification.map((item, index) => ({
  key: 'notification'+index,
  label:<NotificationItem notification={item}></NotificationItem>
}));
return (
    <>
 <Dropdown  menu={{ items }} trigger={['click']} >
                    <a onClick={(e) => e.preventDefault()}>
                        <Badge className='mx-2' style={{ fontSize: '0.7rem' }} count={countUnread()}><BellOutlined style={{ fontSize: '1rem' }} /></Badge>    </a>
    </Dropdown>
    </>
  )
}
export default memo(Notification)