import { Button, DatePicker, Flex, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import { RESOURCE_URL } from '../../../Helper/urls';
import { Convert_to_base } from '../../../Helper/Doc_Functions';
import TextArea from 'antd/es/input/TextArea';
import MyUpload from '../Component/MyUpload';
import dayjs from 'dayjs';

export default function RemoteWorkForm(props:any) {
    const [form] = Form.useForm();
    let { doctype,name } = useParams();

    const {locale,direction}=useContext(CustomContext)
    const [data,setData]=useState<any>({})
    const[emp,setEmp]=useState("")
    const [getalllist,setGetalllist]=useState<any[]>([])
    const nav=useNavigate()
    const addAttachment=async(file:any,docname:string)=>{
      await MyAxios.put('api/resource/File/'+file.uid,{attached_to_doctype:Convert_to_base(doctype!),attached_to_name:docname})
  }
    useEffect(()=>{
      
      const fecthData=async()=>{
        if(name){
          await MyAxios.get(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name).then((res:any)=>{
            setData(res.data.data)

          })
         
          
          

      } 
      if(name===undefined){
            await MyAxios.get('api/method/kabi.api.home.get_current_emp').then((res:any)=>{
            setEmp(res.data.message)

          }).catch(error=>console.error(error))
        
          }

          await MyAxios.get('api/method/kabi.api.time_request.get_emp_rw').then((res:any)=>{
            setGetalllist(res.data.message)
            // disabledDates(dayjs())
          }).catch(error=>console.error(error))
      }
      fecthData()
      
      
    },[])
    const hundlesubmit=async(event:any)=>{

      if(name===undefined){
      await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),{description:event.reason,remote_day:dayjs(event.remote_day).format('YYYY-MM-DD'),employee:emp})      .then((e:any)=>{
        // console.log(event.attachment);
        if(event.attachment!==undefined){
          for (let i = 0; i < event.attachment.fileList.length; i++) {
          if(event.attachment.fileList[i].status=='done'){
            addAttachment(event.attachment.fileList[i],e.data.data.name)

          }
          
        }
        }
        

        nav('/'+doctype)
      }
    )
      .catch(err=>console.log(err))
      }else{
        await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,{description:event.reason,remote_day:dayjs(event.remote_day).format('YYYY-MM-DD')})
        .then((e:any)=>{
          // console.log(event.attachment);
          if(event.attachment!==undefined){
            for (let i = 0; i < event.attachment.fileList.length; i++) {
            if(event.attachment.fileList[i].status=='done'){
              addAttachment(event.attachment.fileList[i],e.data.data.name)
  
            }
            
          }
          }
          
  
          nav('/'+doctype)
        }
      )
        .catch(err=>console.log(err))
      }
      // console.log({reason:event.reason,attactment_file:event.attachment==undefined?"":event.attachment.fileList[0].response.message.file_url,remote_day:dayjs(event.remote_day).format('YYYY-MM-DD'),employee:emp})
    }
    const disabledDates = (current:any) => {
      const foundDate = getalllist.find((item:any) => dayjs(item.remote_day).isSame(current, 'month') && dayjs(item.remote_day).isSame(current, 'year')&&item.workflow_state!=='مرفوض');
      return foundDate ? true : false;
    };
    if(name)
    form.setFieldsValue({remote_day:dayjs(data.remote_day),reason:data.description})
  return (

    <Form
    form={form}
    layout="vertical"
    onFinish={hundlesubmit}
   
  >
    <Form.Item
    label={locale.remote_day[direction]}
    name='remote_day'
    
    rules={[{required:true}]}
    >
    <DatePicker disabled={data.docstatus===1}
     disabledDate={disabledDates} 
      />

    </Form.Item>
    <Flex gap={5}>
         <Form.Item
   label={locale.reason[direction]}
   name='reason'
   >
        <TextArea disabled={data.docstatus===1}   rows={4}  ></TextArea>
    </Form.Item>
<MyUpload docstatus={data.docstatus===1 } attachment_field='attachment' attatment_label='Attachment'/>
    </Flex>
  
    <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus>=1} >
          {locale.save[direction]}
        </Button>
    </Form.Item>
  </Form>
  
  )
}
