import { Avatar, Badge, BadgeProps, Calendar, CalendarProps, Radio, Tooltip } from 'antd'
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useState } from 'react'
import { TIME_Request_Color } from '../../../../globals/GlobalColors';
import { ClockCircleFilled } from '@ant-design/icons';
import { CustomContext } from '../../../../App';

export default function LeaveCalender(props:any) {
    const{locale,direction}=useContext(CustomContext)
    const [sortFilter,setSortFilter]=useState<keyof typeof props.data>('my')
    const getListData = (currentDate: Dayjs) => {
                let leave:any[]=props.data[sortFilter]
                let listData=[];
                for (let i = 0; i < leave.length; i++) {
                    // console.log(leave[i])
                    const fromDate = dayjs(leave[i].doc.from_date, 'YYYY-MM-DD');
                    const toDate = dayjs(leave[i].doc.to_date, 'YYYY-MM-DD');


                    if((currentDate.isAfter(fromDate) || currentDate.isSame(fromDate, 'day'))&&( (currentDate.isBefore(toDate) || currentDate.isSame(toDate, 'day'))) )
                    listData.push(leave[i].doc.employee_name)
                }


        return listData || [];
      };
    const dateCellRender = (value: Dayjs) => {
        const listData = getListData(value);
        return (
            <Avatar.Group shape='square' maxCount={4}>
 {listData.map((item:any) => (
    <Tooltip  title={item}>
                    <Avatar  style={{ backgroundColor: TIME_Request_Color, verticalAlign: 'middle' }} size="default" ><ClockCircleFilled /></Avatar>
                
                </Tooltip>
                    


                
            ))}
            </Avatar.Group>
            
            
            
           
                    );
      };
    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        // if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
      };
  return (
    <>    {props.data['all'].length==0?<></>:
    <Radio.Group
    value={sortFilter}
    onChange={(e) => {
      setSortFilter(e.target.value);
    }}
    ><Radio.Button className='titles' value="my">{locale['your_requests'][direction]}</Radio.Button>
  <Radio.Button className='titles' value="all">{locale['all_requests'][direction]}</Radio.Button>
    
    
    
  </Radio.Group>}
  <br />
    <Calendar mode='month' cellRender={cellRender}	 />

    </>
  )
}
