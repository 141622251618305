import { Card, Col, Row ,Typography } from 'antd'
import React from 'react'

const {Text,Title} =Typography
export default function MyHomeCard(props:any) {
  return (
    <>
    <Card style={{boxShadow: '1px 1px 1px -1px rgba(0,0,0,0.5) ',width:'100%'}}>
        <Col>
        <Row><Title level={5} style={{fontWeight:'bolder !important'}} >{props.icon} {props.title}</Title> </Row>
         {props.body} 
        <Row> <Text style={{color:'gray'}}>{props.footer} </Text> </Row>
        </Col>
        
    </Card>
   
    </>
  )
}
