import React, { useContext, useState } from 'react'
import MainLayout from '../../layouts/main/MainLayout'
import { Col, Divider, Flex, GetProp, Row, Upload, UploadProps, message, Typography, Image, Button, Tabs, TabsProps } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../Helper/urls';
import Edit_profile from './component/Edit_profile';
import My_Profile from './component/My_Profile';
import { CustomContext } from '../../App';
const { Text } = Typography
export default function Profile_page() {
  const{direction,locale}=useContext(CustomContext);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: locale.my_profile[direction],
      children: <My_Profile/>,
    },
    {
      key: '2',
      label: locale.edit_profile[direction],
      children: <Edit_profile/>,
    },
   
  ];
  return (
    <MainLayout title='Profile'>
     <Tabs
        defaultActiveKey="1"
        tabPosition={window.innerWidth<990?'top':direction=='ltr'?'left':'right'}
        size={'small'}
        
        items={items}
      />
    </MainLayout>
  )
}


