import { Card, Flex, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { EXPENSE_CLAIM_Color } from '../../../globals/GlobalColors'
const {Text}=Typography
export default function ExpenseItem(props:any) {
  return (
    <Link to={'/expense-claim'+ '/' + props.data.doc.name + '/edit'} className='w-100'  >

    <Card hoverable bordered style={{ borderColor: EXPENSE_CLAIM_Color, borderWidth: 2, backgroundColor: EXPENSE_CLAIM_Color + '10', color: 'black200', width: '100%' }} >
        <Flex justify='space-around' gap={2}  align='center'  >
            
            <Text className='titles' >{props.data.doc.name}</Text>
            <Text className='titles' >{props.data.doc.workflow_state}</Text>
            <Text className='titles' >{props.data.doc.payment_month}</Text>

            <Text className='titles' >{props.data.doc.total_amount}</Text>
            <Text className='titles' >{props.data.owner}</Text>
        </Flex>


    </Card>

</Link>
  )
}
