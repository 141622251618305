import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Tabs, TabsProps } from 'antd';
import { CustomContext } from '../../../App';
import { MyAxios } from '../../../globals/MyAxios';
import MyRequestList from './Component/MyRequestList';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

function MyRequest() {
  const {locale,direction}=useContext(CustomContext)


  const items: TabsProps['items'] = [
    { key: '1', label: locale['pending'][direction],icon:<ClockCircleOutlined style={{color:'orange',fontSize:'large'}}  />, children:<MyRequestList state='pending'/>},
    { key: '2', label: locale['approved'][direction],icon:<CheckCircleOutlined style={{color:'green',fontSize:'large'}} />, children:<MyRequestList state='approved'/>},
    { key: '3', label: locale['rejected'][direction],icon:<CloseCircleOutlined style={{color:'red',fontSize:'large'}}/>, children:<MyRequestList state='rejected'/>},  
  ];
  return (
    <MainLayout title='My Requests' loading={false}>
     <Tabs
        defaultActiveKey="1"
        size='large'
        items={items}
        indicator={{ size: (origin) => origin , align: 'start' }}
      />    
      </MainLayout>
  )
}

export default MyRequest