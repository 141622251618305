import React, { useContext } from 'react'
import { Typography } from 'antd';
import { CustomContext } from '../../../App';
import { url } from 'inspector';
import './style.css'
const { Title } = Typography;
export default function WelcomeAuth() {
  const {locale,direction}=useContext(CustomContext)

  return (
    <>
            {/* <div style={{display:'flex',flexDirection:'column', justifySelf:'start'}}> */}
            <div className={localStorage.getItem('mode')=='dark'?'':'background-image'}>    
                
            <Title level={2}>{locale['hi'][direction]}!</Title>
            <Title level={2}>{locale['welcome_to'][direction]} </Title>
            <Title level={2} style={{color:'#29A0D1'}}>KABi ERP</Title>

            </div>
          

        {/* </div> */}
    </>
  );
}
