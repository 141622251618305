import { Button, Card, Form, Input, message,Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { MyAxios } from '../../globals/MyAxios';
import { CustomContext } from '../../App';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthLayout from '../../layouts/Auth/AuthLayout';
const {Text,Title}=Typography
function Update_Password() {
    const nav=useNavigate()
    const [form] = Form.useForm();
    const {locale,direction}=useContext(CustomContext)
    const [loading,setLoading]=useState(false)
    const [user,setUser]=useState<any>({success:1,email:''})
    const location = useLocation();

    // Function to parse query parameters
    function useQuery() {
      return new URLSearchParams(location.search);
    }
  
    const query = useQuery();
    useEffect(()=>{
        const feachUser=async()=>{
            await MyAxios.get('api/method/kabi.api.user.check_key',{params:{key:query.get('key')}})
            .then((res:any)=>{
                setUser(res.data.message)
                form.setFieldValue('email',res.data.message.email)

            })
        }
        feachUser()
    },[])
    const validatePassword = (rule:any, value:any, callback:any) => {
      if (value && value.length >= 8) {
        // Check for at least one digit
        if (!/\d/.test(value)) {
          callback('Password must contain at least one digit');
        }
        // Check for at least one special character
        else if (!/[@#$%^&*]/.test(value)) {
          callback('Password must contain at least one special character: @#$%^&*');
        }
        // Check if the password starts with an alphabet
        else if (!/^[a-zA-Z]/.test(value)) {
          callback('Password must start with an alphabet');
        } else {
          callback();
        }
      } else {
        callback('Password must be at least 8 characters long');
      }
    };
    const onFinish =async (e: any) => {
  
      setLoading(true)
  
      const params:any={
          name:user.email,pwd:e.password1
      }
  //     try{
  //         // await axios.get('https://jsonplaceholder.typicode.com/todos/1')
  //         // .then(e=>console.log(e))
  await MyAxios.get('api/method/kabi.api.user.update_password_sign',{params} )
      .then(res=>{
        message.success('Your password has been updated successfully')
     setLoading(false)
     nav('/login')
      })

    };
  
  
    return (
        <AuthLayout>

{user.success?
        <Card  title={locale.reset_password[direction]} bordered>
            <Form
                form={form}
                layout='vertical'
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // style={{ maxWidth: 1000 }}
                onFinish={onFinish}

                autoComplete="off"
            >
                <Form.Item
                className='d-none'
                    label={locale.email[direction]}
                    name="email"

                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input defaultValue={user.email} disabled />
                </Form.Item>
                <Form.Item
                    label={locale.new_password[direction]}
                    name="password1"
                    rules={[{ required: true, message: 'Please input your password!' },
                    { validator: validatePassword }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label={locale.confirm_password[direction]}
                    name="password2"
                    rules={[{ required: true, message: 'Please input your password!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password1') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {locale.submit[direction]}
                    </Button>
                </Form.Item>
            </Form>
            
        </Card>:<Text className='d-flex'>{locale.not_applicable[direction]} <Link to='/request-password'><Button style={{display:'flex', justifySelf:'start'}} size='small' type='link'>{locale.here[direction]}</Button></Link>
</Text>}
        </AuthLayout>

    )
}

export default Update_Password