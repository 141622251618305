import React, { useContext } from 'react'
import { CustomContext } from '../../../../App'
import { Flex } from 'antd'

export default function PurchaseHeader() {
    const{locale,direction}=useContext(CustomContext)
    return (
      <Flex justify='space-around'  >
          <div className='titles'>{locale['name'][direction]}</div>
          <div className='titles'>{locale['supplier_name'][direction]}</div>
          <div className='titles'>{locale['total_grand'][direction]}</div>
          <div className='titles'>{locale.paid_around[direction]}</div>
          <div className='titles'>{locale['status'][direction]}</div>
          <div className='titles'>{locale['owner'][direction]}</div>
          
          
      </Flex>
    )
}
