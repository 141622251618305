import React, { useContext, useEffect, useState } from 'react'
import { MyAxios } from '../../../globals/MyAxios'
import { Convert_to_base } from '../../../Helper/Doc_Functions'
import { Button, Checkbox, Descriptions, Divider, Flex, Form, Input, InputNumber, Progress, Table, TableColumnsType, Typography, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import MyUpload from '../Component/MyUpload'
import { RESOURCE_URL } from '../../../Helper/urls'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomContext } from '../../../App'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
const { Title, Text } = Typography;
export default function PurchaseForm(props: any) {
  const [form] = Form.useForm();
  let { doctype, name } = useParams();

  const { locale, direction } = useContext(CustomContext)
  const [data, setData] = useState<any>({})
  const [dep, setDep] = useState<string>('')
  const nav = useNavigate()
  const addAttachment = async (file: any, docname: string) => {
    await MyAxios.put('api/resource/File/' + file.uid, { attached_to_doctype: Convert_to_base(doctype!), attached_to_name: docname })
  }
  useEffect(() => {

    const fecthData = async () => {
      if (name) {
        await MyAxios.get(RESOURCE_URL + Convert_to_base(doctype!) + '/' + name).then((res: any) => {
          setData(res.data.data)
          form.setFieldsValue({ supplier_name: res.data.data.supplier_name, supplier_phone_number: res.data.data.supplier_phone_number, supplier_email: res.data.data.supplier_email, description: res.data.data.description, grand_total: res.data.data.grand_total, total_quantity: res.data.data.total_quantity })
          
        })
        await MyAxios.get('api/method/kabi.api.table.get_all_Items_pur',{params:{doctype:'Item KABi',parent:name,parenttype:Convert_to_base(doctype!)}}).then((res:any)=>{
          let result=res.data.message
         for (let i = 0; i < result.length; i++) {
          result[i].key=result[i].name;
          result[i].vat_15=result[i].vat_15==1?true:false;
         }
            setTableData(result)
        }).catch(err=>console.error(err))
        // form.setFieldsValue({ supplier_name: data.supplier_name, supplier_phone: data.supplier_phone_number, supplier_email: data.supplier_phone_number, description: data.description, grand_total: data.grand_total, total_quantity: data.total_quantity })
      } else {
await MyAxios.get('api/method/kabi.api.user.get_dep').then((res: any) => {
          setDep(res.data.message)
        })

      }
    }
    fecthData()
    form.setFieldValue('grand_total', get_totalamount())
    form.setFieldValue('total_quantity', get_totalquantity())



  }, [])
  const hundlesubmit = async (event: any) => {
    // { department: dep, supplier_name: event.supplier_name, supplier_phone: event.supplier_phone_number, supplier_email: event.supplier_phone_number, description: event.description, grand_total: event.grand_total, total_quantity: event.total_quantity }
    if(tableData.length!==0){
      if(name===undefined){
  await MyAxios.get('api/method/kabi.api.user.get_dep').then((res: any) => {
        setDep(res.data.message)
      });
      await MyAxios.post(RESOURCE_URL+Convert_to_base(doctype!),{ department: dep, supplier_name: event.supplier_name, supplier_phone_number: event.supplier_phone_number, supplier_email: event.supplier_email, description: event.description, grand_total: Number(event.grand_total), total_quantity: Number(event.total_quantity) }).then(e=>{
        for (let i = 0; i < tableData.length; i++) {
          if(tableData[i].name==''){
            addRowafter(tableData[i],e.data.data.name)
          }else{
            updateRow(tableData[i])
          }
          
        }
        if(event.attachment!==undefined){
          for (let i = 0; i < event.attachment.fileList.length; i++) {
          if(event.attachment.fileList[i].status=='done'){
            addAttachment(event.attachment.fileList[i],e.data.data.name)

          }
          
        }
        }
      nav('/'+doctype)
      }).catch(err=>console.log(err))
        
      }else{
        await MyAxios.put(RESOURCE_URL+Convert_to_base(doctype!)+'/'+name,{ supplier_name: event.supplier_name, supplier_phone_number: event.supplier_phone_number, supplier_email: event.supplier_email, description: event.description, grand_total: Number(event.grand_total), total_quantity: Number(event.total_quantity) }).then((res:any)=>{
          for (let i = 0; i < tableData.length; i++) {
            if(tableData[i].name==''){
              addRowafter(tableData[i],res.data.data.name)
            }else{
              updateRow(tableData[i])
            }
            
          }
          if(event.attachment!==undefined){
            for (let i = 0; i < event.attachment.fileList.length; i++) {
            if(event.attachment.fileList[i].status=='done'){
              addAttachment(event.attachment.fileList[i],res.data.data.name)
  
            }
            
          }
          }
        nav('/'+doctype)

        })

      }


      }else{
        message.error("No Item Found")
      }
  }
  interface DataType {
    key: React.Key;
    name: string;
    item_description: string;
    price: number;
    quantity:number;
    vat_15:boolean;
    total_amount:number;
  }

  interface Props {
    locale: any;
    direction: string;
  }
  const [tableData, setTableData] = useState<DataType[]>([]);


  const addRow = () => {
    const newRow: DataType = {
      key: tableData.length + 1,
      name: '',
      item_description: '',
      price: 0,
      quantity:1,
      vat_15:false,
      total_amount:0,


    };
    setTableData([...tableData, newRow]);
  };


  const handleInputChange = (value: any, fieldName: string, key: React.Key) => {
    // const { value } = e.target;
    const updatedData = tableData.map((item:any) => {
      if (item.key === key) {
        let i={ ...item, [fieldName]: value }
        i.total_amount=calcAmount(i)

        return i;
      }
      return item;
    });
    setTableData(updatedData);
  };
  const calcAmount=(row:any)=>{
    return row.vat_15? row.price*1.15*row.quantity : row.price*row.quantity;
  }

  const columns: TableColumnsType<DataType> = [


    {
      title: locale.description[direction],
      dataIndex: 'item_description',
      key: 'item_description',
      render: (_: any, record: DataType) => (
        <Input
          disabled={data.docstatus >= 1}
          // style={{width:'10rem'}}
          value={record.item_description}
          onChange={(e) => handleInputChange(e.target.value, 'item_description', record.key)}
          required

        />
      ),

    },
    {
      title: locale.price[direction],
      dataIndex: 'price',
      key: 'price',
      render: (_: any, record: DataType) => (
        <Input
          value={record.price}
          suffix="SAR"
          onChange={(e) => /^\d*\.?\d*$/.test(e.target.value) ? handleInputChange(e.target.value, 'price', record.key) : ''}
          disabled={data.docstatus >= 1}
          

        />
      ),

    },
    {
      title: locale.quantity[direction],
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_: any, record: DataType) => (
        <Input
          value={record.quantity}
          onChange={(e) => /^\d*\.?\d*$/.test(e.target.value) ? handleInputChange(e.target.value, 'quantity', record.key) : ''}
          disabled={data.docstatus >= 1}

        />
      ),

    },
    
    {
      title: locale.vat[direction],
      dataIndex: 'vat_15',
      key: 'vat_15',
      render: (_: any, record: DataType) => (
        <Checkbox 
        checked={record.vat_15}
        disabled={data.docstatus >= 1} onChange={(e)=>{
          handleInputChange(e.target.checked, 'vat_15', record.key)
        }}/>

      ),

    },
    {
      title: locale.amount[direction],
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (_: any, record: DataType) => (
        <Input
          value={record.total_amount}
          suffix="SAR"
          disabled={true}

        />
      ),

    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_: any, record: DataType) => <Button icon={<DeleteOutlined />} onClick={() => deleteRow(record)} type='primary' danger></Button>,
    },

  ];

  const get_totalamount = () => {
    let result = 0
    for (let i = 0; i < tableData.length; i++) {
      result += Number(tableData[i].total_amount)


    }
    return result
  }
  function get_totalquantity() {
    let result = 0
    for (let i = 0; i < tableData.length; i++) {
      result += Number(tableData[i].quantity)


    }
    return result  }
  const updateRow = async (row: any) => {
    await MyAxios.put(RESOURCE_URL + 'Item KABi/' + row.name, { item_description: row.item_description, price: row.price,quantity:row.quantity,vat_15:row.vat_15?1:0,total_amount:row.total_amount })
  }
  const addRowafter = async (row: any,docname:string) => {
    await MyAxios.post(RESOURCE_URL + 'Item KABi', { parenttype: Convert_to_base(doctype!), parent: docname, parentfield: 'item', item_description: row.item_description, price: row.price,quantity:row.quantity,vat_15:row.vat_15?1:0,total_amount:row.total_amount  })
  }
  const deleteRow = async (row: any) => {
    if (row.name == '') {
      const updatedData = tableData.filter(item => item.key !== row.key);
      setTableData(updatedData);
    } else {
      await MyAxios.delete(RESOURCE_URL + 'Item KABi/' + row.name).then((e) => {
        const updatedData = tableData.filter(item => item.key !== row.key);
        setTableData(updatedData);
      })


    }

  };
  useEffect(() => {
    form.setFieldValue('grand_total', get_totalamount())
    form.setFieldValue('total_quantity', get_totalquantity())
    // if (name)
    //   form.setFieldsValue({ supplier_name: data.supplier_name, supplier_phone: data.supplier_phone_number, supplier_email: data.supplier_email, description: data.description, grand_total: data.grand_total, total_quantity: data.total_quantity })
  
  }, [tableData])

  // if (name)
  //   form.setFieldsValue({ supplier_name: data.supplier_name, supplier_phone_number: data.supplier_phone_number, supplier_email: data.supplier_email, description: data.description, grand_total: data.grand_total, total_quantity: data.total_quantity })


  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={hundlesubmit}
    >
      <Title level={4}>{locale.supplier[direction]}</Title>
      <Flex wrap='wrap' gap={'small'}>
        <Form.Item
          name="supplier_name"
          label={locale['supplier_name'][direction]}
        // rules={[{ required: true },]}
        >
          <Input
            disabled={data.docstatus >= 1}

          />
        </Form.Item>
        <Form.Item
          name="supplier_phone_number"
          label={locale['supplier_phone_number'][direction]}
          className='d-none'

        // rules={[{ required: true },]}
        >
          <Input
            disabled={data.docstatus >= 1}

          />      </Form.Item>
        <Form.Item
          name="supplier_email"
          label={locale['supplier_email'][direction]}
        // rules={[{ required: true },]}
        >
          <Input
            disabled={data.docstatus >= 1}

          />     
          
           </Form.Item>

      </Flex>
      <Divider />
      <Title level={4}>{locale.items[direction]}</Title>
      <Form.Item
        name="description"
        label={locale['description'][direction]}
      rules={[{ required: true },]}
      >
        <TextArea disabled={data.docstatus >= 1} rows={4} />
      </Form.Item>
      <Form.Item name="item" required>
        <Table pagination={false} columns={columns} dataSource={tableData} rowKey="key" />

        <Button disabled={data.docstatus >= 1} className='mt-1' icon={<PlusOutlined />} type='primary' onClick={addRow}>{locale.add_item[direction]}</Button>

      </Form.Item>
      <Flex wrap='wrap' gap={'small'}>
        <Form.Item
          name="grand_total"
          label={locale['grand_total'][direction]}
        // rules={[{ required: true },]}
        >
          <InputNumber
            style={{ width: '15rem' }}
            value={get_totalamount().toString()}
            formatter={(value) => `${value} SAR`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            disabled
          />     </Form.Item>
        <Form.Item
          name="total_quantity"
          label={locale['total_quantity'][direction]}
        // rules={[{ required: true },]}
        >
          <InputNumber
            style={{ width: '15rem' }}
            value={get_totalquantity().toString()}
            disabled
          />     </Form.Item>

          <div>
            <Title level={5}>{locale.paid_around[direction]}</Title>
              <Progress type="circle" percent={data.grand_total==0?0:(data.remaining_paid_amount/data.grand_total)*100} size={40} />
            </div>

      </Flex>

      <MyUpload docstatus={data.docstatus >= 1} attachment_field='attachment' />

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={data.docstatus >= 1} >
          {locale.save[direction]}
        </Button>
      </Form.Item>
    </Form>)
}


