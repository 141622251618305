import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Button, Flex, Modal,Form, Input } from 'antd'
import { CustomContext } from '../../../App'
import Icon from '@ant-design/icons/lib/components/Icon'
import { ContainerOutlined, UserOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import { MyAxios } from '../../../globals/MyAxios'
import CustomLoading from '../../../globals/CustomLoading'
import { useForm } from 'antd/es/form/Form'

export default function Document_page() {
  const { locale, direction } = useContext(CustomContext)
  const [url, setUrl] = useState('')
  const [docs,setDocs]=useState<any>({salary_id:'none',emp_id:'none'})
  const [genLoading,setGenLoading]=useState(false)
  const [docname,setDocname]=useState('')
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const feachDoc = async () => {
      await MyAxios.get('api/method/kabi.api.document.get_all_docs').then((res:any) => {
        setDocs(res.data.message)
        setLoading(false)
      })
    }
    feachDoc()
  }, [])
  const showModal = () => {
    form.setFieldsValue({to_ar:"إلى من يهمه الامر",to_en:'To whom it may concern'})

    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
 const generate_doc=async(event:any)=>{
  setUrl('')
  setGenLoading(true)
        await MyAxios.get(`api/method/kabi.api.document.${docname=='salary_id'?'get_salary_id':'get_emp_id'}`,{params:event}).then(res => {
        setUrl(res.data.message)
        setGenLoading(false)
      })
 }

  const printDocument = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow!.document.write(`
    <body>
    <style>
    @font-face {
      font-family: 'KABi';
      src: url('font/kabiFontBold.ttf'); 
    }
    body, html {
      font-family: 'KABi', sans-serif !important;
    }
    
    </style>`);
    printWindow!.document.write(url);
    printWindow!.document.write('</body>');
    printWindow!.document.close();
    setTimeout(() => {  
        printWindow!.print();


    }, 1000); 
    
    setOpen(false)
  };


  return (
    <MainLayout title='Document' loading={loading}>
      <Flex gap={'small'} wrap='wrap' align='baseline'>
          <Button type="primary" size='large'
        disabled={docs.salary_id==='none'}
        // href={url!=='none'?url:''}
        onClick={()=>{showModal();setUrl(docs.salary_id);setDocname('salary_id')}}
        target='_blank' icon={<ContainerOutlined />} >
        {locale['salary_identification'][direction]}
       
      </Button>
      <Button type="primary" size='large'
        disabled={docs.emp_id==='none'}
        // href={url!=='none'?url:''}
        onClick={()=>{showModal();setUrl(docs.emp_id);setDocname('emp_id')}}
        target='_blank' icon={<UserOutlined />} >
        {locale['emp_identification'][direction]}
       
      </Button>
      </Flex>
    
 <Modal title={locale.document[direction]} open={open}  onOk={handleOk}  onCancel={handleCancel} footer={[
          <Button key="back" onClick={handleCancel}>
            {locale.cancel[direction]}
          </Button>,
          <Button key="print" type="primary" onClick={printDocument}>
            {locale.print[direction]}
          </Button>
        ]}>
          {/* <Form><Flex gap={'small'} wrap='wrap'><Form.Item></Form.Item></Flex></Form> */}
          <Form
            form={form}
            layout="vertical"
    onFinish={generate_doc}
  >
    <Flex gap={'small'} wrap='wrap' align='end'> <Form.Item
      name="to_en"
      label={locale['to_en'][direction]}
      style={{width:'10rem'}}
    >
      <Input/>
          </Form.Item>
          <Form.Item
      name="to_ar"
      label={locale['to_ar'][direction]}
      style={{width:'10rem'}}

      
    >
      <Input/>
     </Form.Item>
   
   
    <Form.Item>
        <Button icon={<VerticalAlignTopOutlined />} type="primary" htmlType="submit"  >
          {locale.generate[direction]}
        </Button>
    </Form.Item></Flex>
   
  </Form>
          {genLoading?<CustomLoading/>:<div dangerouslySetInnerHTML={{ __html: url }} style={{maxHeight:'25rem' ,minWidth:'20rem',overflow:'scroll'}} />
}
        </Modal>
    </MainLayout>
  )
}
