import { Flex } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { CustomContext } from '../../App';

function FlushPage() {
    const {mode}=useContext(CustomContext)

const nav=useNavigate()
    useEffect(() => {
        setTimeout(() => {
            nav('/');
            window.location.reload()
            window.location.reload()

        }, 3000); // Redirects after 2 seconds
    }, []);

    return (
        <Flex style={{alignItems:"center",justifyContent:"center",height:'100vh', width:'100vw',backgroundColor:mode==='dark'?'black':'white'}}><img src={mode==='dark'?window.location.origin +'/image/logo2.png':window.location.origin +"/image/KABi.png" } height={mode==='dark'?30:50}   /></Flex>
    );
}

export default FlushPage