import {  Layout } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomContext } from '../../App';
const { Header, Footer,  Content } = Layout;

export default function AuthLayout(props: any) {
  const nav=useNavigate()
  const location = useLocation();
  const { pathname } = location;
  useEffect(()=>{

    // if(localStorage.getItem('token')===''){
      
      if(pathname==='/')
      nav('login')
    // }
  },[])
  const {locale,direction}=useContext(CustomContext)

    const headerStyle: React.CSSProperties = {
        
        display:'flex',
        justifyContent:'start',
        backgroundColor:localStorage.getItem('mode')==='dark'?'transparent ':'white',
        marginTop:20,
        marginLeft:"1.5rem",
        marginRight:"1.5rem",
      };
      
      const contentStyle: React.CSSProperties = {
        display:'flex',
        minHeight: 120,
        justifyContent:'space-around',
        alignItems:'center',
        backgroundColor:localStorage.getItem('mode')==='dark'?'transparent ':'white'
      };
      

      
      const footerStyle: React.CSSProperties = {
        display:'flex', 
        justifyContent:'space-between',
        backgroundColor:localStorage.getItem('mode')==='dark'?'transparent ':'white' ,
        alignItems:'baseline'      
      };
      
      const layoutStyle = {
        // borderRadius: 8,
        overflow: 'hidden',
        
        // marginLeft:"1.5rem",
        // marginRight:"1.5rem",
        height:'100vh',
        // backgroundColor:'white'
        
      };
  return (
    <>

    <Layout  style={layoutStyle}>
        <Header style={headerStyle}>
        <img src={localStorage.getItem('mode')==='dark'?'./image/logo2.png':"./image/KABi.png" } height={localStorage.getItem('mode')==='dark'?30:50} alt="logo"/>
        </Header>
        <Content style={contentStyle}>
        {props.children}
        </Content>

        <Footer style={footerStyle}>
        <small>
            
            &copy; {new Date().getFullYear()} KABi. {locale['all_rights_reserved'][direction]}
        </small> 
        <div style={{display:'flex',}}>
            <p ><small>
            {locale['terms_&_conditions'][direction]}
            </small></p>
            <pre>  </pre>
            <p > <small>{locale['privacy_policy'][direction]}</small></p>
        </div>
        </Footer>
    </Layout>
    
    </>


  )
}
