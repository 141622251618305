import { asyncThunkCreator, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { MyAxios } from "../../globals/MyAxios";
import { stat } from "fs";

const HomeSlice=createSlice({
    name:'home',
    initialState:{
data:{
        remote_work:[],
        who_out:[],
        rank:0,
        energy_point:0,
        events:[
            
        ],
        leave_balance:0,
        posts:[{
            title:'',
            content:'',
            image:''
        }
           
        ]
    },
    loading:true
    }
    ,
    reducers:{
        // async  getData(){
        // const res= await MyAxios.get('api/method/kabi.api.home.home')
        // return res.data.message

        // }
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchHomeData.fulfilled,(state, action) => {
            state.loading=false
            state.data=action.payload
          })
          builder.addCase(fetchHomeData.pending,(state,action)=>{

            state.loading=true
          })
          builder.addCase(fetchHomeData.rejected,(state,action)=>{

            state.loading=false
          })
       

    }
})
export const fetchHomeData = createAsyncThunk(
    'home/all',
    async () => {
     const res= await MyAxios.get('api/method/kabi.api.home.home')
     return res.data.message
    },

  )

export default HomeSlice.reducer