import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Divider, Flex, Input, List, Spin, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import TeamItem from './TeamItem'
import { CustomContext } from '../../../../App'
const {Title,Text}=Typography

export default function TeamList(props:any) {
  const {direction,locale}=useContext(CustomContext)

  // const data=[
 
  //   {
  //     title: 'Title 4',
  //   },
  //   {
  //     title: 'Title 5',
  //   },
  //   {
  //     title: 'Title 6',
  //   },
  //   {
  //     title: 'Title 1',
  //   },
  //   {
  //     title: 'Title 2',
  //   },
  //   {
  //     title: 'Title 3',
  //   },
  //   {
  //     title: 'Title 4',
  //   },
  //   {
  //     title: 'Title 5',
  //   },
  //   {
  //     title: 'Title 6',
  //   },
  //   {
  //     title: 'Title 1',
  //   },

  // ]
  const[team,setTeam]=useState(props.data)
  const SearchByname=(text:string)=>{
    if(text!==""){
      const regex = new RegExp(text, 'i'); // 'i' flag for case-insensitive search
      return props.data.filter((item:any) => regex.test(item.employee_name)) ;
    }else{
      return props.data
    }
  }
  return (
    <>
    <Input placeholder={locale['search_by_name'][direction]}variant="borderless" onChange={(e)=>{setTeam(SearchByname(e.target.value))}} prefix={<SearchOutlined />}/>
    <Divider/>
    <Text  style={{fontSize:'large',fontWeight:'bolder' }} >{locale['people'][direction]}  <span style={{color:'gray'}} >({team.length})</span></Text>
    <br />
    
    <List
    pagination={{pageSize:18,align:'end'}}
    bordered={false}
    grid={{
      xs: 1,
      sm: 2,
      md: 4,
      lg: 6,
      xl: 6,
      xxl: 6,
    }}
    dataSource={team}
    // loading={{ indicator:<LoadingOutlined style={{ fontSize: 24 }}  />,spinning:true }}    
    renderItem={(item) => (
      <List.Item >
        <TeamItem emp={item}  />
      </List.Item>
    )}
  />

    </>


  )
}
