import { EXPENSE_CLAIM_Color, FINANCE_Color, HR_Color, HR_RequestColor, TIME_Request_Color } from "../globals/GlobalColors"

export const get_doc_type_color=(doctype:string)=>{
    if(doctype==="General Request"){
        return HR_RequestColor

    }else if(doctype==="Leave Application"||doctype==="Business Trip"||doctype==="Remote Work"){
        return TIME_Request_Color

    }else if(doctype==="Expense Claim KABi"){
        return EXPENSE_CLAIM_Color
    }else if(doctype==="Purchase Order KABi"||doctype==="Payment Request KABi"){
        return FINANCE_Color
    }
    else if(doctype=="Evaulation Form"){

        return HR_Color
    }
    
    else{
        return 'transparent'
    }


}

export const Convert_from_base_doctype_to=(doctype:string)=>{
    return doctype.replace(' KABi',"").replaceAll(' ','-').toLowerCase()

}

export const Convert_to_base=(doctype:string)=>{
    if(doctype==="purchase-order"||doctype==="payment-request"||doctype==="expense-claim"){
        return convertToTitleCase(doctype)+ " KABi"

    }else {
        return convertToTitleCase(doctype)
    }

}
function convertToTitleCase(input: string): string {
    // Split the input string by dashes
    const words = input.split('-');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words with spaces and return
    return capitalizedWords.join(' ');
}