import { Avatar } from 'antd'
import React from 'react'
import MyCustomAvatar from '../../../../globals/MyCustomAvatar'

export default function MyAvatarGroup(props:any) {
    if(props.data.length>0){
return <Avatar.Group
    maxCount={7}
    maxPopoverTrigger="click"
    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
  >
    {props.data.map((e:any)=>{
      return <MyCustomAvatar size='large' name={e.employee_name} image={e.image}/>
    })}
  
  </Avatar.Group>
    }else{
      return <div style={{color:'gray' , textAlign:'center'}}>No one is out</div>
    }
}
